import React from "react";
import PropTypes from "prop-types";

function CrewDisplay({ role, name, status }) {
  return (
    <span>
      <img
        src={require(`static/img/icons/${role.toLowerCase()}.svg`)} // eslint-disable-line global-require, import/no-dynamic-require
        alt={role}
        title={role}
        height="24"
      />{" "}
      <img
        src={require(`static/img/icons/${status.toLowerCase()}.svg`)} // eslint-disable-line global-require, import/no-dynamic-require
        alt={status}
        title={status}
        height="24"
      />{" "}
      {name}
    </span>
  );
}

CrewDisplay.propTypes = {
  role: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default CrewDisplay;
