import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import NumberSelector from "views/components/NumberSelector";
import { activeUserIsFacilitator } from "store/ducks/user";
import { requestUpdateResource } from "store/ducks/ship";

function capitalizeFirstLetter(string) {
  if (!string) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function Resource({ shipId, resource, value, img }) {
  const dispatch = useDispatch();

  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);

  const [showSelector, setShowSelector] = useState(false);
  const [newValue, setNewValue] = useState(value);

  useEffect(() => {
    setNewValue(value);
  }, [setNewValue, value]);

  if (showSelector) {
    return (
      <Form inline>
        <Form.Label>
          <img
            src={img}
            alt={capitalizeFirstLetter(resource)}
            title={capitalizeFirstLetter(resource)}
            height="24"
          />{" "}
        </Form.Label>
        <InputGroup className="pl-1">
          <NumberSelector
            value={newValue}
            onChange={setNewValue}
            start={0}
            end={99}
            size="sm"
          />
          <InputGroup.Append>
            <Button
              size="sm"
              variant="success"
              onClick={() => {
                dispatch(requestUpdateResource(shipId, resource, newValue));
                setShowSelector(false);
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                setShowSelector(false);
                setNewValue(value);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    );
  }
  return (
    <span
      className="mx-1 text-nowrap"
      onClick={isFacilitator ? () => setShowSelector(true) : () => {}}
      onKeyUp={isFacilitator ? () => setShowSelector(true) : () => {}}
      role={isFacilitator ? "button" : ""}
      tabIndex={isFacilitator ? 0 : null}
    >
      <img
        src={img}
        alt={capitalizeFirstLetter(resource)}
        title={capitalizeFirstLetter(resource)}
        height="24"
      />{" "}
      {value}
    </span>
  );
}

Resource.propTypes = {
  shipId: PropTypes.number.isRequired,
  resource: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
};

export default Resource;
