import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CrewDisplay from "views/components/CrewDisplay";
import { getTransactionEffect } from "store/ducks/transactionEffect";
import { getTiredSynthsAtStation } from "store/ducks/crew";

function AddResearchPoints({ effectId, updateEffectsRequirementsMet }) {
  const effect = useSelector(
    (state) => getTransactionEffect(state, effectId),
    shallowEqual
  );
  const tiredSynths = useSelector(
    (state) => getTiredSynthsAtStation(state, effect.station_id),
    shallowEqual
  );

  useEffect(() => {
    if (tiredSynths.length === 0) {
      updateEffectsRequirementsMet(effectId, false);
    }
    if (tiredSynths.length > 0) {
      updateEffectsRequirementsMet(effectId, true);
    }
  }, [tiredSynths, updateEffectsRequirementsMet, effectId]);

  if (tiredSynths.length === 0) {
    return (
      <Row>
        <Col>
          <h4>Charge Synths at Station</h4>
          <p>No discharged, functioning synths found at this station.</p>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col>
        <h4>Charge Synths at Station</h4>
        <p>Functioning synths at this station will be charged.</p>
        <ul>
          {tiredSynths.map((crew) => {
            return (
              <li key={crew.id}>
                <CrewDisplay
                  role={crew.role}
                  name={crew.name}
                  status={crew.status}
                />
              </li>
            );
          })}
        </ul>
      </Col>
    </Row>
  );
}

AddResearchPoints.propTypes = {
  effectId: PropTypes.number.isRequired,
  updateEffectsRequirementsMet: PropTypes.func.isRequired,
};

export default AddResearchPoints;
