import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faChevronCircleDown,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import security from "static/img/icons/security.svg";
import {
  getCrewForStation,
  getUsableCrewForTransaction,
  getShipSecurityForRiotSuppression,
} from "store/ducks/crew";
import { getStation, requestRepair } from "store/ducks/station";
import {
  getShipJumpDriveEngaged,
  getShipJumpCrewUsed,
  getActiveShip,
} from "store/ducks/ship";
import { getTurnPhase, getInWolfAttack } from "store/ducks/gameTurn";
import { activeUserIsFacilitator } from "store/ducks/user";
import FormattedText from "views/components/FormattedText";
import JumpCoordinates from "./JumpCoordinates";
import JumpControls from "./JumpControls";
import CommitCrewToJumpDrive from "./CommitCrewToJumpDrive";
import Transaction from "./Transaction";
import SuppressRioters from "./SuppressRioters";
import CommitCrewToWeaponBatteries from "./CommitCrewToWeaponBatteries";
import CommitCrewToHangarBay from "./CommitCrewToHangarBay";
import FacilitatorEditStation from "./FacilitatorEditStation";
import CrewListEntry from "./CrewListEntry";

function getDisplayVariantByStatus(status) {
  switch (status) {
    case "damaged":
      return "danger";
    case "rioting":
      return "warning";
    case "used":
    case "disabled":
      return "dark";
    default:
      return "light";
  }
}

const CrewList = ({ stationId, status }) => {
  const crew = useSelector(
    (state) => getCrewForStation(state, stationId),
    shallowEqual
  );
  const inWolfAttack = useSelector(getInWolfAttack, shallowEqual);

  return Object.values(crew).map((crewMember, index) => (
    <Draggable
      draggableId={`crewMember-${crewMember.id}`}
      // Account for the invisible draggable in the container
      index={index + 1}
      key={crewMember.id.toString()}
      isDragDisabled={
        (crewMember.role !== "Synth" &&
          ["Tired", "Wounded-Tired"].includes(crewMember.status)) ||
        inWolfAttack
      }
    >
      {(provided) => (
        <ListGroup.Item
          variant={getDisplayVariantByStatus(status)}
          ref={provided.innerRef}
          {...provided.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
          {...provided.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
        >
          <CrewListEntry crew={crewMember} />
        </ListGroup.Item>
      )}
    </Draggable>
  ));
};

const Station = forwardRef(function Station({ stationId }, ref) {
  const dispatch = useDispatch();

  const [jumpCoordinateModalShow, setJumpCoordinateModalShow] = useState(false);
  const [jumpControlsModalShow, setJumpControlsModalShow] = useState(false);
  const [commitCrewToJumpDriveModalShow, setCommitCrewToJumpDriveModalShow] =
    useState(false);
  const [
    commitCrewToWeaponBatteriesModalShow,
    setCommitCrewToWeaponBatteriesModalShow,
  ] = useState(false);
  const [commitCrewToHangarBayModalShow, setCommitCrewToHangarBayModalShow] =
    useState(false);
  const [transactionModalShow, setTransactionModalShow] = useState(false);
  const [suppressRiotersModalShow, setSuppressRiotersModalShow] =
    useState(false);
  const [showCardBody, setShowCardBody] = useState(false);
  const [lockControls, setLockControls] = useState(false);
  const [lockReason, setLockReason] = useState("");
  const [showFacilitatorEditModal, setShowFacilitatorEditModal] =
    useState(false);

  const station = useSelector(
    (state) => getStation(state, stationId),
    shallowEqual
  );
  const shipJumpDriveEngaged = useSelector(
    getShipJumpDriveEngaged,
    shallowEqual
  );
  const shipJumpCrewUsed = useSelector(getShipJumpCrewUsed, shallowEqual);
  const turnPhase = useSelector(getTurnPhase, shallowEqual);
  const repairEngineers = useSelector(
    (state) =>
      getUsableCrewForTransaction(state, stationId, "Engineer", "Normal"),
    shallowEqual
  );
  const repairSynths = useSelector(
    (state) => getUsableCrewForTransaction(state, stationId, "Synth", "Normal"),
    shallowEqual
  );
  const ship = useSelector(getActiveShip, shallowEqual);
  const securityForRiotSuppression = useSelector(
    getShipSecurityForRiotSuppression,
    shallowEqual
  );
  const wolfAttack = useSelector(getInWolfAttack, shallowEqual);
  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);

  useEffect(() => {
    if (turnPhase !== "action") {
      setLockControls(true);
      setLockReason("Stations may only be used during the Action Phase");
    } else if (wolfAttack) {
      setLockControls(true);
      setLockReason("Stations may not be operated during a Wolf attack");
    } else {
      setLockControls(false);
      setLockReason("");
    }
  }, [turnPhase, wolfAttack]);

  const cardFieldProps = {
    border: "dark",
    className: "mb-4",
    bg: getDisplayVariantByStatus(station.status),
    text:
      getDisplayVariantByStatus(station.status) === "light" ? "dark" : "white",
  };

  const toggleBodyDisplay = (e) => {
    e.preventDefault();
    setShowCardBody(!showCardBody);
  };

  useImperativeHandle(ref, () => ({
    expand: () => {
      setShowCardBody(true);
    },
    collapse: () => {
      setShowCardBody(false);
    },
  }));

  return (
    <Col xs={12} sm={6} md={4} xl={3}>
      <Droppable droppableId={`station-${stationId.toString()}`}>
        {(droppableProvided) => (
          <Card
            border={cardFieldProps.border}
            className={cardFieldProps.className}
            bg={cardFieldProps.bg}
            text={cardFieldProps.text}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
          >
            <Card.Header
              onClick={(e) => toggleBodyDisplay(e)}
              className="collapse-control-header"
            >
              <div className="station-header">
                <span>
                  {isFacilitator && (
                    <Button
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowFacilitatorEditModal(true);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </span>
                <span className="h4 font-weight-normal my-0">
                  {station.name}
                  {station.status === "damaged" ? " - DAMAGED" : ""}
                  {station.status === "rioting" ? " - RIOTING" : ""}
                  {station.status === "used" ? " - Used" : ""}
                  {station.status === "disabled" ? " - DISABLED" : ""}
                </span>
                {!showCardBody && (
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    className="align-self-center"
                  />
                )}
                {showCardBody && (
                  <FontAwesomeIcon
                    icon={faChevronCircleDown}
                    className="align-self-center"
                  />
                )}
              </div>
            </Card.Header>
            <FacilitatorEditStation
              show={showFacilitatorEditModal}
              onHide={() => setShowFacilitatorEditModal(false)}
              stationId={stationId}
            />
            <Collapse in={showCardBody}>
              <div>
                <Card.Body>
                  <FormattedText text={station.text} />
                </Card.Body>
                {station.status === "damaged" && (
                  <>
                    <Card.Header>
                      <h4 className="font-weight-normal my-0">! DAMAGED !</h4>
                    </Card.Header>
                    <Card.Body>
                      <FormattedText text={station.damage_text} />
                    </Card.Body>
                  </>
                )}
                {station.status === "rioting" && (
                  <>
                    <Card.Header>
                      <h4 className="font-weight-normal my-0">! Rioting !</h4>
                    </Card.Header>
                    <Card.Body>
                      <p>
                        A riot has overtaken the station, rendering it unusable!
                        You must use a{" "}
                        <img
                          src={security}
                          alt="Security"
                          title="Security"
                          height="24"
                        />{" "}
                        Security crew to attempt to disperse it. The result is
                        determined by a d6 roll as follows:
                      </p>
                      <p>
                        1: Fleet crew damaged. Riot suppressed.
                        <br />
                        2-3: Riot suppressed.
                        <br />
                        4-6: Riot dispersed, station usable next turn.
                      </p>
                      <p>
                        A riot that is not suppressed in a turn has a 50% chance
                        of spreading to another undamaged station at the end of
                        the action phase.
                      </p>
                      <p>
                        {station.rioter_status === "active"
                          ? "RIOT NOT SUPPRESSED! Riot may spread at end of turn!"
                          : "Riot suppressed, will not spread."}
                      </p>
                    </Card.Body>
                  </>
                )}
              </div>
            </Collapse>
            {station.special_transaction_name === "special-jump" &&
              station.status !== "rioting" && (
                <>
                  <Card.Footer>
                    {!lockControls && (
                      <Button
                        variant={!shipJumpCrewUsed ? "secondary" : "success"}
                        onClick={() => setCommitCrewToJumpDriveModalShow(true)}
                        disabled={shipJumpCrewUsed}
                      >
                        Commit Crew
                      </Button>
                    )}
                    {lockControls && (
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>{lockReason}</Tooltip>}
                      >
                        <span className="d-inline-block">
                          <Button
                            variant={
                              !shipJumpCrewUsed ? "secondary" : "success"
                            }
                            disabled
                            style={{ pointerEvents: "none" }}
                          >
                            Commit Crew{" "}
                            <sup>
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </sup>
                          </Button>
                        </span>
                      </OverlayTrigger>
                    )}
                  </Card.Footer>
                  <Card.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setJumpCoordinateModalShow(true)}
                    >
                      Enter Coordinates
                    </Button>
                  </Card.Footer>
                  <Card.Footer>
                    {shipJumpCrewUsed && turnPhase === "action" && (
                      <Button
                        variant={
                          !shipJumpDriveEngaged ? "secondary" : "success"
                        }
                        onClick={() => setJumpControlsModalShow(true)}
                      >
                        Jump Drive
                      </Button>
                    )}
                    {!shipJumpCrewUsed && turnPhase === "action" && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip>
                            Crew must be committed to the Jump Drive at the
                            start of the turn. Please use the above button to
                            commit crew.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline-block">
                          <Button
                            variant={
                              !shipJumpDriveEngaged ? "secondary" : "success"
                            }
                            disabled
                            style={{ pointerEvents: "none" }}
                          >
                            Jump Drive{" "}
                            <sup>
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </sup>
                          </Button>
                        </span>
                      </OverlayTrigger>
                    )}
                    {turnPhase !== "action" && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip>
                            Stations may only be used during the Action Phase
                          </Tooltip>
                        }
                      >
                        <span className="d-inline-block">
                          <Button
                            variant={
                              !shipJumpDriveEngaged ? "secondary" : "success"
                            }
                            disabled
                            style={{ pointerEvents: "none" }}
                          >
                            Jump Drive{" "}
                            <sup>
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </sup>
                          </Button>
                        </span>
                      </OverlayTrigger>
                    )}
                  </Card.Footer>
                  <JumpCoordinates
                    show={jumpCoordinateModalShow}
                    onHide={() => setJumpCoordinateModalShow(false)}
                  />
                  <JumpControls
                    show={jumpControlsModalShow}
                    onHide={() => setJumpControlsModalShow(false)}
                  />
                  <CommitCrewToJumpDrive
                    show={commitCrewToJumpDriveModalShow}
                    onHide={() => setCommitCrewToJumpDriveModalShow(false)}
                    stationId={stationId}
                  />
                </>
              )}
            {(station.status === "active" || station.status === "used") && (
              <>
                {station.special_transaction_name ===
                  "special-weaponbatteries" && (
                  <>
                    <Card.Footer>
                      {!lockControls && (
                        <Button
                          variant="secondary"
                          disabled={station.status === "used"}
                          onClick={() =>
                            setCommitCrewToWeaponBatteriesModalShow(true)
                          }
                        >
                          Commit Crew
                        </Button>
                      )}
                      {lockControls && (
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip>{lockReason}</Tooltip>}
                        >
                          <span className="d-inline-block">
                            <Button
                              variant="secondary"
                              disabled
                              style={{ pointerEvents: "none" }}
                            >
                              Commit Crew{" "}
                              <sup>
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </sup>
                            </Button>
                          </span>
                        </OverlayTrigger>
                      )}
                    </Card.Footer>
                    <CommitCrewToWeaponBatteries
                      show={commitCrewToWeaponBatteriesModalShow}
                      onHide={() =>
                        setCommitCrewToWeaponBatteriesModalShow(false)
                      }
                      stationId={stationId}
                    />
                  </>
                )}
                {station.special_transaction_name === "special-hangarbay" && (
                  <>
                    <Card.Footer>
                      {!lockControls && (
                        <Button
                          variant="secondary"
                          disabled={station.status === "used"}
                          onClick={() =>
                            setCommitCrewToHangarBayModalShow(true)
                          }
                        >
                          Commit Crew
                        </Button>
                      )}
                      {lockControls && (
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip>{lockReason}</Tooltip>}
                        >
                          <span className="d-inline-block">
                            <Button
                              variant="secondary"
                              disabled
                              style={{ pointerEvents: "none" }}
                            >
                              Commit Crew{" "}
                              <sup>
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </sup>
                            </Button>
                          </span>
                        </OverlayTrigger>
                      )}
                    </Card.Footer>
                    <CommitCrewToHangarBay
                      show={commitCrewToHangarBayModalShow}
                      onHide={() => setCommitCrewToHangarBayModalShow(false)}
                      stationId={stationId}
                    />
                  </>
                )}
                {station.transaction_id !== null && (
                  <>
                    <Card.Footer>
                      {!lockControls && (
                        <Button
                          variant="secondary"
                          disabled={station.status === "used"}
                          onClick={() => setTransactionModalShow(true)}
                        >
                          Use Station
                        </Button>
                      )}
                      {lockControls && (
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip>{lockReason}</Tooltip>}
                        >
                          <span className="d-inline-block">
                            <Button
                              variant="secondary"
                              disabled
                              style={{ pointerEvents: "none" }}
                            >
                              Use Station{" "}
                              <sup>
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </sup>
                            </Button>
                          </span>
                        </OverlayTrigger>
                      )}
                    </Card.Footer>
                    <Transaction
                      show={transactionModalShow}
                      onHide={() => setTransactionModalShow(false)}
                      stationId={stationId}
                    />
                  </>
                )}
              </>
            )}
            {station.status === "damaged" && (
              <Card.Footer>
                {!lockControls && (
                  <Button
                    variant="secondary"
                    disabled={
                      (repairSynths.length === 0 &&
                        repairEngineers.length <
                          station.repair_engineer_cost) ||
                      ship.material < station.repair_material_cost
                    }
                    onClick={() => dispatch(requestRepair(stationId))}
                  >
                    Repair
                  </Button>
                )}
                {lockControls && (
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{lockReason}</Tooltip>}
                  >
                    <span className="d-inline-block">
                      <Button
                        variant="secondary"
                        disabled
                        style={{ pointerEvents: "none" }}
                      >
                        Repair{" "}
                        <sup>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </sup>
                      </Button>
                    </span>
                  </OverlayTrigger>
                )}
              </Card.Footer>
            )}
            {station.status === "rioting" && (
              <Card.Footer>
                {!lockControls && (
                  <Button
                    variant="secondary"
                    disabled={securityForRiotSuppression.length === 0}
                    onClick={() => setSuppressRiotersModalShow(true)}
                  >
                    Suppress Rioters
                  </Button>
                )}
                {lockControls && (
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{lockReason}</Tooltip>}
                  >
                    <span className="d-inline-block">
                      <Button
                        variant="secondary"
                        disabled
                        style={{ pointerEvents: "none" }}
                      >
                        Suppress Rioters{" "}
                        <sup>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </sup>
                      </Button>
                    </span>
                  </OverlayTrigger>
                )}
              </Card.Footer>
            )}
            <SuppressRioters
              show={suppressRiotersModalShow}
              onHide={() => setSuppressRiotersModalShow(false)}
              stationId={stationId}
            />
            <ListGroup variant="flush">
              {
                // This placeholder draggable is to make sure that the first crew member dragged to a station is placed properly
              }
              <Draggable
                draggableId={`station-placeholder-${stationId}`}
                index={0}
              >
                {(draggableProvided) => (
                  <div
                    style={{ display: "none" }}
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
                    {...draggableProvided.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
                  />
                )}
              </Draggable>
              <CrewList stationId={station.id} status={station.status} />
              {droppableProvided.placeholder}
            </ListGroup>
          </Card>
        )}
      </Droppable>
    </Col>
  );
});

Station.propTypes = {
  stationId: PropTypes.number.isRequired,
};

export default Station;
