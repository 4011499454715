import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import NumberSelector from "views/components/NumberSelector";
import research from "static/img/icons/research.svg";
import { activeUserIsFacilitator } from "store/ducks/user";
import { requestUpdateResearchPoints } from "store/ducks/researchPoints";

function ResearchPoints({ points }) {
  const dispatch = useDispatch();

  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);

  const [showSelector, setShowSelector] = useState(false);
  const [newPoints, setNewPoints] = useState(points);

  useEffect(() => {
    setNewPoints(points);
  }, [setNewPoints, points]);

  if (showSelector) {
    return (
      <Form inline>
        <Form.Label>
          <img
            src={research}
            alt="Research Points"
            title="Research Points"
            height="24"
          />{" "}
        </Form.Label>
        <InputGroup className="pl-1">
          <NumberSelector
            value={newPoints}
            onChange={setNewPoints}
            start={0}
            end={50}
            size="sm"
          />
          <InputGroup.Append>
            <Button
              size="sm"
              variant="success"
              onClick={() => {
                dispatch(requestUpdateResearchPoints(newPoints));
                setShowSelector(false);
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                setShowSelector(false);
                setNewPoints(points);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    );
  }
  return (
    <span
      className="mx-1 text-nowrap"
      onClick={isFacilitator ? () => setShowSelector(true) : () => {}}
      onKeyUp={isFacilitator ? () => setShowSelector(true) : () => {}}
      role={isFacilitator ? "button" : ""}
      tabIndex={isFacilitator ? 0 : null}
    >
      <img
        src={research}
        alt="Research Points"
        title="Research Points"
        height="24"
      />{" "}
      {points}
    </span>
  );
}

ResearchPoints.propTypes = {
  points: PropTypes.number.isRequired,
};

export default ResearchPoints;
