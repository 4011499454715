import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { createSelector } from "reselect";
import { getDiscordName } from "./display";

export const getUsers = (state) => Object.values(state.users);

export const getUserById = (state, userId) =>
  state.users ? state.users[userId] : {};

export const getActiveUser = createSelector(
  [getDiscordName, getUsers],
  (discordName, users) => {
    return users.find((user) => user.discord_name === discordName);
  }
);

export const activeUserIsFacilitator = createSelector(
  [getActiveUser],
  (user) => {
    return user ? user.type === "facilitator" : false;
  }
);

export const activeUserIsNews = createSelector([getActiveUser], (user) => {
  return user ? user.type === "news" : false;
});

const FETCH_REQUESTED = "maas-loader/user/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/user/FETCH_SUCCEEDED";
const UPDATE_RECEIVED = "maas-loader/user/UPDATE_RECEIVED";
const CREATE_USER_REQUESTED = "maas-loader/user/CREATE_USER_REQUESTED";
const UPDATE_USER_REQUESTED = "maas-loader/user/UPDATE_USER_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { users } = action;
      return users;
    }
    case UPDATE_RECEIVED: {
      const { user } = action;
      return { ...state, [user.id]: user };
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(users) {
  return { type: FETCH_SUCCEEDED, users };
}

export function updateReceived(user) {
  return { type: UPDATE_RECEIVED, user };
}

export function requestCreateUser(user) {
  return { type: CREATE_USER_REQUESTED, user };
}

export function requestUpdateUser(userId, user) {
  return { type: UPDATE_USER_REQUESTED, userId, user };
}

function* fetchUsers() {
  try {
    const userResponse = yield call(() => axios.get("/api/v1/user/"));
    const users = {};
    Object.values(userResponse.data).forEach((user) => {
      users[user.id] = user;
    });
    yield put(fetchSucceeded(users));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchUsers() {
  yield takeLatest(FETCH_REQUESTED, fetchUsers);
}

function* createUser(action) {
  const { user } = action;
  try {
    const newUser = {
      discord_name: user.discord_name,
      type: user.type,
    };
    const userRespone = yield call(() => axios.post("/api/v1/user/", newUser));
    if (user.type === "player") {
      const userId = userRespone.data.id;
      const newPlayer = {
        name: user.name,
        role: user.role,
        ship_id: user.ship_id,
        has_battlemap_access: user.has_battlemap_access,
      };
      const playerResponse = yield call(() =>
        axios.post("/api/v1/player/", newPlayer)
      );
      const playerId = playerResponse.data.id;
      yield put(requestUpdateUser(userId, { player_id: playerId }));
    }
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchCreateUser() {
  yield takeEvery(CREATE_USER_REQUESTED, createUser);
}

function* updateUser(action) {
  const { userId, user } = action;
  try {
    yield call(() => axios.put(`/api/v1/user/${userId}`, user));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER_REQUESTED, updateUser);
}
