import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import NumberSelector from "views/components/NumberSelector";
import { getActiveShip, requestUpdateJumpCoordinates } from "store/ducks/ship";
import {
  getUpdateSucceeded,
  getErrorMessage,
  clearUpdateStatus,
} from "store/ducks/display";
import { activePlayerIsCaptain } from "store/ducks/player";
import { activeUserIsFacilitator } from "store/ducks/user";

const replaceAt = (str, index, replacement) => {
  return (
    str.substr(0, index) + replacement + str.substr(index + replacement.length)
  );
};

function JumpCoordinates({ show, onHide }) {
  const dispatch = useDispatch();

  const ship = useSelector(getActiveShip, shallowEqual);
  const updateSucceeded = useSelector(getUpdateSucceeded, shallowEqual);
  const errorMessage = useSelector(getErrorMessage, shallowEqual);
  const isUserFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);
  const isPlayerCaptain = useSelector(activePlayerIsCaptain, shallowEqual);

  const [jumpCoordinates, setJumpCoordinates] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (ship && jumpCoordinates === "") {
      setJumpCoordinates(ship.jump_coordinates);
    }
  }, [ship, jumpCoordinates]);

  useEffect(() => {
    if (updateSucceeded === undefined) {
      setShowSuccessAlert(false);
      setShowErrorAlert(false);
      setErrorAlertMessage("");
    }
    if (updateSucceeded !== undefined) {
      setLoading(false);
      if (updateSucceeded !== showSuccessAlert) {
        setShowSuccessAlert(updateSucceeded);
      }
      if (updateSucceeded === showErrorAlert) {
        setShowErrorAlert(!updateSucceeded);
      }
    }
    if (errorMessage !== undefined && errorMessage !== errorAlertMessage) {
      setErrorAlertMessage(errorMessage);
    }
  }, [
    updateSucceeded,
    showSuccessAlert,
    showErrorAlert,
    errorMessage,
    errorAlertMessage,
  ]);

  // If/when this component loads before data is available
  if (!ship) {
    return <div />;
  }

  const coordinateNumberUpdated = (value, index) => {
    setJumpCoordinates(replaceAt(jumpCoordinates, index, value));
  };

  const clearAlert = () => {
    if (updateSucceeded !== undefined) {
      dispatch(clearUpdateStatus());
    }
  };

  if (!isUserFacilitator && !isPlayerCaptain) {
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="jumpCoordinatesModalTitle"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="jumpCoordinatesModalTitle">
            Jump Coordinates
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert
            variant="danger"
            className="display-3 font-weight-bold text-center"
          >
            Access Denied: This attempt has been logged
          </Alert>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal
      show={show}
      onShow={() => setJumpCoordinates(ship.jump_coordinates)}
      onHide={onHide}
      onExited={() => clearAlert()}
      size="lg"
      aria-labelledby="jumpCoordinatesModalTitle"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="jumpCoordinatesModalTitle">
          Jump Coordinates
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert
          show={showSuccessAlert}
          variant="success"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Coordinates Locked</Alert.Heading>
          Jump coordinates have been committed to the navigational computer.
        </Alert>
        <Alert
          show={showErrorAlert}
          variant="danger"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Error</Alert.Heading>
          {errorAlertMessage}
        </Alert>
        <Container className="d-flex align-items-center flex-column">
          <Row>
            {jumpCoordinates
              .padStart(5, "0")
              .split("")
              .map((value, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Col key={index}>
                    <NumberSelector
                      size="lg"
                      index={index}
                      defaultValue={value}
                      onChange={coordinateNumberUpdated}
                    />
                  </Col>
                );
              })}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isLoading}
          onClick={() => {
            clearAlert();
            dispatch(requestUpdateJumpCoordinates(ship.id, jumpCoordinates));
            setLoading(true);
          }}
        >
          Set Jump Coordinates
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

JumpCoordinates.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default JumpCoordinates;
