import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  getTransactionRequirement,
  requestUpdateTransactionRequirementValues,
  requestDeleteTransactionRequirement,
  requestCreateTransactionRequirement,
} from "store/ducks/transactionRequirement";

function TransactionRequirementEditor({
  transactionRequirementId,
  shipId,
  stationId,
  transactionId,
}) {
  const dispatch = useDispatch();

  const transactionRequirement = useSelector(
    (state) => getTransactionRequirement(state, transactionRequirementId) || {},
    shallowEqual
  );

  const [showEditor, setShowEditor] = useState(false);

  const [type, setType] = useState(
    transactionRequirement.type || "useCrewAtStation"
  );

  useEffect(() => {
    if (transactionRequirement.type) {
      setType(transactionRequirement.type);
    }
  }, [transactionRequirement.type]);

  const [role, setRole] = useState(transactionRequirement.role || "");

  useEffect(() => {
    if (transactionRequirement.role) {
      setRole(transactionRequirement.role);
    }
  }, [transactionRequirement.role]);

  const [status, setStatus] = useState(transactionRequirement.status || "");

  useEffect(() => {
    if (transactionRequirement.status) {
      setStatus(transactionRequirement.status);
    }
  }, [transactionRequirement.status]);

  const [quantity, setQuantity] = useState(
    transactionRequirement.quantity || 0
  );

  useEffect(() => {
    if (transactionRequirement.quantity) {
      setQuantity(transactionRequirement.quantity);
    }
  }, [transactionRequirement.quantity]);

  const [lowQuantity, setLowQuantity] = useState(
    transactionRequirement.low_quantity || 0
  );

  useEffect(() => {
    if (transactionRequirement.low_quantity) {
      setLowQuantity(transactionRequirement.low_quantity);
    }
  }, [transactionRequirement.low_quantity]);

  const [lowResult, setLowResult] = useState(
    transactionRequirement.low_result || 0
  );

  useEffect(() => {
    if (transactionRequirement.low_result) {
      setLowResult(transactionRequirement.low_result);
    }
  }, [transactionRequirement.low_result]);

  const [medQuantity, setMedQuantity] = useState(
    transactionRequirement.med_quantity || 0
  );

  useEffect(() => {
    if (transactionRequirement.med_quantity) {
      setMedQuantity(transactionRequirement.med_quantity);
    }
  }, [transactionRequirement.med_quantity]);

  const [medResult, setMedResult] = useState(
    transactionRequirement.med_result || 0
  );

  useEffect(() => {
    if (transactionRequirement.med_result) {
      setMedResult(transactionRequirement.med_result);
    }
  }, [transactionRequirement.med_result]);

  const [medPenalty, setMedPenalty] = useState(
    transactionRequirement.med_penalty || 0
  );

  useEffect(() => {
    if (transactionRequirement.med_penalty) {
      setMedPenalty(transactionRequirement.med_penalty);
    }
  }, [transactionRequirement.med_penalty]);

  const [highQuantity, setHighQuantity] = useState(
    transactionRequirement.high_quantity || 0
  );

  useEffect(() => {
    if (transactionRequirement.high_quantity) {
      setHighQuantity(transactionRequirement.high_quantity);
    }
  }, [transactionRequirement.high_quantity]);

  const [highResult, setHighResult] = useState(
    transactionRequirement.high_result || 0
  );

  useEffect(() => {
    if (transactionRequirement.high_result) {
      setHighResult(transactionRequirement.high_result);
    }
  }, [transactionRequirement.high_result]);

  const [highPenalty, setHighPenalty] = useState(
    transactionRequirement.high_penalty || 0
  );

  useEffect(() => {
    if (transactionRequirement.high_penalty) {
      setHighPenalty(transactionRequirement.high_penalty);
    }
  }, [transactionRequirement.high_penalty]);

  return (
    <>
      <Row>
        {transactionRequirementId && (
          <>
            <Col>{transactionRequirement.type}</Col>
            <Col>
              <ButtonGroup>
                <Button onClick={() => setShowEditor(!showEditor)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button
                  onClick={() =>
                    dispatch(
                      requestDeleteTransactionRequirement(
                        transactionRequirementId
                      )
                    )
                  }
                  variant="danger"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </ButtonGroup>
            </Col>
          </>
        )}
        {!transactionRequirementId && (
          <Col>
            <Button onClick={() => setShowEditor(!showEditor)}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
        )}
      </Row>
      <Row className={showEditor ? "" : "d-none"}>
        <Col>
          {!transactionRequirementId && (
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="useCrewAtStation">useCrewAtStation</option>
                <option value="useTwoCrewAtStation">useTwoCrewAtStation</option>
                <option value="spendWater">spendWater</option>
                <option value="spendFood">spendFood</option>
                <option value="spendMaterial">spendMaterial</option>
                <option value="refineOreVariable">refineOreVariable</option>
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="">None</option>
              <option value="Untrained">Untrained</option>
              <option value="Doctor">Doctor</option>
              <option value="Pilot">Pilot</option>
              <option value="Engineer">Engineer</option>
              <option value="Scientist">Scientist</option>
              <option value="Convict">Convict</option>
              <option value="Synth">Synth</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">None</option>
              <option value="Normal">Normal</option>
              <option value="Tired">Tired</option>
              <option value="Wounded">Wounded</option>
              <option value="Wounded-Tired">Wounded-Tired</option>
              <option value="Dead">Dead</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Low Quantity</Form.Label>
            <Form.Control
              value={lowQuantity}
              onChange={(e) => setLowQuantity(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Low Result</Form.Label>
            <Form.Control
              value={lowResult}
              onChange={(e) => setLowResult(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Med Quantity</Form.Label>
            <Form.Control
              value={medQuantity}
              onChange={(e) => setMedQuantity(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Med Result</Form.Label>
            <Form.Control
              value={medResult}
              onChange={(e) => setMedResult(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Med Penalty</Form.Label>
            <Form.Control
              value={medPenalty}
              onChange={(e) => setMedPenalty(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>High Quantity</Form.Label>
            <Form.Control
              value={highQuantity}
              onChange={(e) => setHighQuantity(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>High Result</Form.Label>
            <Form.Control
              value={highResult}
              onChange={(e) => setHighResult(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>High Penalty</Form.Label>
            <Form.Control
              value={highPenalty}
              onChange={(e) => setHighPenalty(e.target.value)}
            />
          </Form.Group>
          <Button
            onClick={() => {
              if (transactionRequirementId) {
                dispatch(
                  requestUpdateTransactionRequirementValues(
                    transactionRequirementId,
                    {
                      role: role !== "" ? role : null,
                      status: status !== "" ? status : null,
                      quantity,
                      low_quantity: lowQuantity,
                      low_result: lowResult,
                      med_quantity: medQuantity,
                      med_result: medResult,
                      med_penalty: medPenalty,
                      high_quantity: highQuantity,
                      high_result: highResult,
                      high_penalty: highPenalty,
                    }
                  )
                );
              } else {
                dispatch(
                  requestCreateTransactionRequirement({
                    transaction_id: transactionId,
                    ship_id: shipId,
                    station_id: stationId,
                    type,
                    role: role !== "" ? role : null,
                    status: status !== "" ? status : null,
                    quantity,
                    low_quantity: lowQuantity,
                    low_result: lowResult,
                    med_quantity: medQuantity,
                    med_result: medResult,
                    med_penalty: medPenalty,
                    high_quantity: highQuantity,
                    high_result: highResult,
                    high_penalty: highPenalty,
                  })
                );
              }
              setShowEditor(false);
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
}

TransactionRequirementEditor.propTypes = {
  transactionRequirementId: PropTypes.number,
  shipId: PropTypes.number.isRequired,
  stationId: PropTypes.number.isRequired,
  transactionId: PropTypes.number.isRequired,
};

TransactionRequirementEditor.defaultProps = {
  transactionRequirementId: null,
};

export default TransactionRequirementEditor;
