import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import CrewDisplay from "views/components/CrewDisplay";
import {
  getUsableCrewForTransaction,
  requestCommitToHangarBay,
} from "store/ducks/crew";
import {
  getUpdateSucceeded,
  getErrorMessage,
  clearUpdateStatus,
} from "store/ducks/display";
import { getStation } from "store/ducks/station";

function CommitCrewToHangarBay({ show, onHide, stationId }) {
  const dispatch = useDispatch();

  const usableCrew = useSelector(
    (state) => getUsableCrewForTransaction(state, stationId, "Pilot", "Normal"),
    shallowEqual
  );
  const station = useSelector(
    (state) => getStation(state, stationId),
    shallowEqual
  );
  const updateSucceeded = useSelector(getUpdateSucceeded, shallowEqual);
  const errorMessage = useSelector(getErrorMessage, shallowEqual);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedCrew, setSelectedCrew] = useState({});

  useEffect(() => {
    if (updateSucceeded === undefined) {
      setShowSuccessAlert(false);
      setShowErrorAlert(false);
      setErrorAlertMessage("");
    }
    if (updateSucceeded !== undefined) {
      setLoading(false);
      if (updateSucceeded !== showSuccessAlert) {
        setShowSuccessAlert(updateSucceeded);
      }
      if (updateSucceeded === showErrorAlert) {
        setShowErrorAlert(!updateSucceeded);
      }
    }
    if (errorMessage !== undefined && errorMessage !== errorAlertMessage) {
      setErrorAlertMessage(errorMessage);
    }
  }, [
    updateSucceeded,
    showSuccessAlert,
    showErrorAlert,
    errorMessage,
    errorAlertMessage,
  ]);

  useEffect(() => {
    const selectableCrew = {};
    const usableCrewIds = usableCrew.map((crew) => crew.id);
    let modified = false;
    usableCrewIds.forEach((crewId) => {
      if (!Object.keys(selectedCrew).includes(crewId.toString())) {
        selectableCrew[crewId] = true;
        modified = true;
      } else {
        selectableCrew[crewId] = selectedCrew[crewId];
      }
    });
    if (
      Object.keys(selectableCrew).length === 1 &&
      !selectableCrew[Object.keys(selectableCrew)[0]]
    ) {
      selectableCrew[Object.keys(selectableCrew)[0]] = true;
      modified = true;
    }
    if (modified || Object.keys(selectedCrew).length !== usableCrewIds.length) {
      setSelectedCrew(selectableCrew);
    }
  }, [selectedCrew, usableCrew]);

  const clearAlert = () => {
    if (updateSucceeded !== undefined) {
      dispatch(clearUpdateStatus());
    }
  };

  const getCrewDisplayForCrewMember = (crewMember) => {
    return (
      <CrewDisplay
        role={crewMember.role}
        name={crewMember.name}
        status={crewMember.status}
      />
    );
  };

  const handleCrewSelectionUpdated = (event) => {
    if (event.target.checked) {
      setSelectedCrew({ ...selectedCrew, [event.target.value]: true });
    } else {
      setSelectedCrew({ ...selectedCrew, [event.target.value]: false });
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={() => clearAlert()}
      size="lg"
      aria-labelledby="commitCrewModalTitle"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="commitCrewModalTitle">
          Commit Crew to Hangar Bay
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert
          show={showSuccessAlert}
          variant="success"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Crew Committed</Alert.Heading>
          Crew has been committed to the Hangar Bay for this turn.
        </Alert>
        <Alert
          show={showErrorAlert}
          variant="danger"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Error</Alert.Heading>
          {errorAlertMessage}
        </Alert>
        {station.status === "used" && (
          <span>
            Crew has already been committed to the Hangar Bay this turn.
          </span>
        )}
        {station.status !== "used" && usableCrew.length === 0 && (
          <span>
            At least one uninjured, unused pilot must be posted at this station
            in order to commit them to the Hangar Bay.
          </span>
        )}
        {station.status !== "used" && usableCrew.length === 1 && (
          <span>
            Are you sure you want to commit crew member{" "}
            {getCrewDisplayForCrewMember(usableCrew[0])} to the Hangar Bay for
            this turn?
          </span>
        )}
        {station.status !== "used" && usableCrew.length > 1 && (
          <Form>
            <Form.Group controlId="formGroupCrewSelect">
              <Form.Label>
                Please select the crew members to commit to the Hangar Bay for
                this turn.
              </Form.Label>
              {usableCrew.map((crew) => {
                return (
                  <Form.Check
                    type="checkbox"
                    label={crew.name}
                    name="selectedCrew"
                    id={crew.id}
                    value={crew.id}
                    key={crew.id}
                    onChange={handleCrewSelectionUpdated}
                    defaultChecked={selectedCrew[crew.id]}
                  />
                );
              })}
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={
            isLoading || usableCrew.length === 0 || station.status === "used"
          }
          onClick={() => {
            clearAlert();
            dispatch(
              requestCommitToHangarBay(
                Object.keys(selectedCrew).filter(
                  (crewId) => selectedCrew[crewId]
                ),
                stationId
              )
            );
            setLoading(true);
          }}
        >
          Commit Crew
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CommitCrewToHangarBay.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  stationId: PropTypes.number.isRequired,
};

export default CommitCrewToHangarBay;
