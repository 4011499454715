import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { createSelector } from "reselect";
import { getShipId, getShuttleId } from "./display";
import { getActivePlayer, getPlayers } from "./player";

export const getShuttles = (state) => Object.values(state.shuttles);

export const getShuttle = (state, shuttleId) =>
  state.shuttles ? state.shuttles[shuttleId] : {};

export const getShuttlesDockedToActiveShip = createSelector(
  [getShipId, getShuttles],
  (shipId, shuttles) => {
    return shuttles.filter((shuttle) => shuttle.ship_id === shipId);
  }
);

export const getActivePlayerShuttle = createSelector(
  [getActivePlayer, getShuttles],
  (player, shuttles) => {
    if (!player) return null;
    return shuttles.find((shuttle) => shuttle.pilot_id === player.id);
  }
);

export const getPlayersWithoutShuttles = createSelector(
  [getPlayers, getShuttles],
  (players, shuttles) => {
    const shuttlePilots = shuttles.map((shuttle) => shuttle.pilot_id);
    return players.filter((player) => !shuttlePilots.includes(player.id));
  }
);

export const getActiveShuttle = createSelector(
  [getShuttleId, getShuttles],
  (shuttleId, shuttles) => {
    return shuttles.find((shuttle) => shuttle.id === shuttleId);
  }
);

const FETCH_REQUESTED = "maas-loader/shuttle/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/shuttle/FETCH_SUCCEEDED";
const UPDATE_RECEIVED = "maas-loader/shuttle/UPDATE_RECEIVED";
const MOVE_SHUTTLE_REQUESTED = "maas-loader/shuttle/MOVE_SHUTTLE_REQUESTED";
const TRANSFER_SHUTTLE_OWNERSHIP_REQUESTED =
  "maas-loader/shuttle/TRANSFER_SHUTTLE_OWNERSHIP_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { shuttles } = action;
      return shuttles;
    }
    case UPDATE_RECEIVED: {
      const { shuttle } = action;
      return { ...state, [shuttle.id]: shuttle };
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(shuttles) {
  return { type: FETCH_SUCCEEDED, shuttles };
}

export function updateReceived(shuttle) {
  return { type: UPDATE_RECEIVED, shuttle };
}

export function requestMoveShuttle(shuttleId, shipId) {
  return { type: MOVE_SHUTTLE_REQUESTED, shuttleId, shipId };
}

export function requestTransferShuttleOwnership(shuttleId, playerId) {
  return { type: TRANSFER_SHUTTLE_OWNERSHIP_REQUESTED, shuttleId, playerId };
}

function* fetchShuttles() {
  try {
    const shuttleResponse = yield call(() => axios.get("/api/v1/shuttle/"));
    const shuttles = {};
    Object.values(shuttleResponse.data).forEach((shuttle) => {
      shuttles[shuttle.id] = shuttle;
    });
    yield put(fetchSucceeded(shuttles));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchShuttles() {
  yield takeLatest(FETCH_REQUESTED, fetchShuttles);
}

function* moveShuttle(action) {
  const { shuttleId, shipId } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/shuttle/${shuttleId}`, {
        ship_id: shipId !== "null" ? shipId : null,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchMoveShuttle() {
  yield takeEvery(MOVE_SHUTTLE_REQUESTED, moveShuttle);
}

function* transferShuttle(action) {
  const { shuttleId, playerId } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/shuttle/${shuttleId}`, {
        pilot_id: playerId,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchTransferShuttle() {
  yield takeEvery(TRANSFER_SHUTTLE_OWNERSHIP_REQUESTED, transferShuttle);
}
