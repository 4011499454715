import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Draggable } from "react-beautiful-dnd";
import { activeUserIsFacilitator } from "store/ducks/user";
import { getActivePlayer } from "store/ducks/player";
import { getPercentUndamagedStationsForShip } from "store/ducks/station";
import { setSelectedItem, getSelectedItem } from "store/ducks/display";

function ShipToken({ ship }) {
  const dispatch = useDispatch();

  const isUserFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);
  const player = useSelector(getActivePlayer, shallowEqual);
  const percentUndamaged = useSelector(
    (state) => (ship ? getPercentUndamagedStationsForShip(state, ship.id) : 0),
    shallowEqual
  );
  const selectedItem = useSelector(getSelectedItem, shallowEqual);

  if (!ship) {
    return (
      <div className="ship-token bg-light rounded text-truncate w-100 border border-dark">
        <div style={{ height: "4px" }} />
        ---
      </div>
    );
  }

  const isInteractive = () => {
    return isUserFacilitator || (player && player.ship_id === ship.id);
  };

  return (
    <Draggable
      draggableId={`ship-${ship.id}`}
      index={ship.id}
      isDragDisabled={!isInteractive()}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
          {...provided.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
          className={`ship-token ${
            ship.boardingUnits === 0 ? "bg-light" : "bg-warning"
          } rounded text-truncate border ${
            selectedItem &&
            selectedItem.itemType === "ship" &&
            selectedItem.itemId === ship.id
              ? "border-warning"
              : "border-dark"
          }`}
          title={ship.name}
          onClick={
            isInteractive()
              ? () => dispatch(setSelectedItem("ship", ship.id))
              : () => {}
          }
          onKeyUp={
            isInteractive()
              ? () => dispatch(setSelectedItem("ship", ship.id))
              : () => {}
          }
          role={isInteractive() ? "button" : ""}
          tabIndex={isInteractive() ? 1 : null}
        >
          <ProgressBar
            now={percentUndamaged}
            variant="success"
            style={{ height: "4px" }}
          />
          {ship.name}
        </div>
      )}
    </Draggable>
  );
}

ShipToken.propTypes = {
  ship: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    size: PropTypes.oneOf([1, 2]),
    boardingUnits: PropTypes.number,
  }),
};

ShipToken.defaultProps = {
  ship: null,
};

export default ShipToken;
