import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Droppable } from "react-beautiful-dnd";
import { getFightersForQuadrant } from "store/ducks/fighter";
import { getWolfUnitsForQuadrant } from "store/ducks/wolfUnit";
import Fighter from "./Fighter";
import WolfUnit from "./WolfUnit";

function SmallCraftPanel({ quadrant }) {
  const fighters = useSelector(
    (state) => getFightersForQuadrant(state, quadrant),
    shallowEqual
  );
  const wolfUnits = useSelector(
    (state) => getWolfUnitsForQuadrant(state, quadrant),
    shallowEqual
  );

  return (
    <Droppable droppableId={quadrant} type="SMALLCRAFT" direction="horizontal">
      {(provided) => (
        <Row
          className={`d-flex w-100 h-100 m-0 ${
            quadrant.startsWith("N") ? "pt-1" : "pb-1"
          }`}
          ref={provided.innerRef}
          {...provided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
        >
          <Col className="align-self-center">
            <Row className="justify-content-center m-0">
              {fighters.map((fighter, index) => {
                return (
                  <Fighter
                    fighterId={fighter.id}
                    name={fighter.name}
                    index={index}
                    key={fighter.id}
                    shipId={fighter.ship_id}
                    status={fighter.status}
                    used={fighter.used}
                  />
                );
              })}
              {wolfUnits.map((wolfUnit, index) => {
                return (
                  <WolfUnit
                    wolfUnitId={wolfUnit.id}
                    name={wolfUnit.name}
                    index={fighters.length + index}
                    key={wolfUnit.id}
                    type={wolfUnit.type}
                    currentHealth={wolfUnit.current_health}
                    maxHealth={wolfUnit.max_health}
                    used={wolfUnit.used}
                    attacks={wolfUnit.number_of_attacks}
                  />
                );
              })}
              {provided.placeholder}
            </Row>
          </Col>
        </Row>
      )}
    </Droppable>
  );
}

SmallCraftPanel.propTypes = {
  quadrant: PropTypes.oneOf(["NE", "NW", "SE", "SW"]).isRequired,
};

export default SmallCraftPanel;
