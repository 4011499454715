import React from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ShipToken from "./ShipToken";

function ShipSlot({ quadrant, slotId, size, ships }) {
  if (size === 1) {
    return (
      <Droppable
        droppableId={`${quadrant}-${slotId}`}
        isDropDisabled={ships[slotId] !== undefined}
        type="SHIP"
      >
        {(provided) => (
          <Col
            xs={2}
            className="text-center"
            ref={provided.innerRef}
            {...provided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
          >
            <ShipToken ship={ships[slotId]} />
            <div className="d-none">{provided.placeholder}</div>
          </Col>
        )}
      </Droppable>
    );
  }
  return (
    <Col xs={4} className="text-center">
      <div className="bg-light rounded">
        {ships[slotId] && ships[slotId].size === 2 && (
          <Row>
            <Droppable
              droppableId={`${quadrant}-${slotId}`}
              isDropDisabled
              type="SHIP"
            >
              {(provided) => (
                <Col
                  ref={provided.innerRef}
                  {...provided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
                >
                  <ShipToken ship={ships[slotId]} />
                  <div className="d-none">{provided.placeholder}</div>
                </Col>
              )}
            </Droppable>
          </Row>
        )}
        {((ships[slotId] && ships[slotId].size === 1) || !ships[slotId]) && (
          <Row
            className={`d-flex ${
              quadrant.endsWith("W") ? "flex-row" : "flex-row-reverse"
            }`}
          >
            <Droppable
              droppableId={`${quadrant}-${slotId}`}
              isDropDisabled={ships[slotId] !== undefined}
              type="SHIP"
            >
              {(provided) => (
                <Col
                  xs={6}
                  ref={provided.innerRef}
                  {...provided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
                >
                  <ShipToken ship={ships[slotId]} />
                  <div className="d-none">{provided.placeholder}</div>
                </Col>
              )}
            </Droppable>
            <Droppable
              droppableId={`${quadrant}-${slotId + 1}`}
              isDropDisabled={ships[slotId + 1] !== undefined}
              type="SHIP"
            >
              {(provided) => (
                <Col
                  xs={6}
                  ref={provided.innerRef}
                  {...provided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
                >
                  <ShipToken ship={ships[slotId + 1]} />
                  <div className="d-none">{provided.placeholder}</div>
                </Col>
              )}
            </Droppable>
          </Row>
        )}
      </div>
    </Col>
  );
}

ShipSlot.propTypes = {
  quadrant: PropTypes.oneOf(["NE", "NW", "SE", "SW"]).isRequired,
  slotId: PropTypes.oneOf([1, 3, 5]).isRequired,
  size: PropTypes.oneOf([1, 2]).isRequired,
  ships: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
      size: PropTypes.oneOf([1, 2]),
      boardingUnits: PropTypes.number,
    })
  ).isRequired,
};

export default ShipSlot;
