import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

function NumberSelector({
  defaultValue,
  value,
  onChange,
  index,
  size,
  start,
  end,
  showPlus,
}) {
  return (
    <Form.Control
      as="select"
      size={size}
      defaultValue={defaultValue}
      value={value}
      onChange={(e) => onChange(e.target.value, index)}
      custom
    >
      {[...Array(end - start + 1).keys()].map((num) => {
        return (
          <option key={num + start} value={num + start}>
            {showPlus && num + start >= 0 ? "+" : ""}
            {num + start}
          </option>
        );
      })}
    </Form.Control>
  );
}

NumberSelector.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  index: PropTypes.number,
  size: PropTypes.oneOf(["sm", "lg"]),
  start: PropTypes.number,
  end: PropTypes.number,
  showPlus: PropTypes.bool,
};

NumberSelector.defaultProps = {
  defaultValue: undefined,
  value: undefined,
  onChange: () => {},
  index: 1,
  size: null,
  start: 0,
  end: 9,
  showPlus: false,
};

export default NumberSelector;
