import React from "react";
import PropTypes from "prop-types";

function QuadrantLabel({ quadrant }) {
  return (
    <div className="quadrant-label-container">
      <div className="display-1 font-weight-bold text-white">{quadrant}</div>
    </div>
  );
}

QuadrantLabel.propTypes = {
  quadrant: PropTypes.oneOf(["NE", "NW", "SE", "SW"]).isRequired,
};

export default QuadrantLabel;
