import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Redirect,
  useHistory,
} from "react-router-dom";
import RouterNavLink from "views/components/RouterNavLink";
import { setShipId } from "store/ducks/display";
import { getActivePlayerShip, getShipById } from "store/ducks/ship";
import { getActivePlayerShuttle } from "store/ducks/shuttle";
import { activeUserIsFacilitator } from "store/ducks/user";
import stations from "static/img/icons/stations.svg";
import shuttle from "static/img/icons/shuttle.svg";
import cargo from "static/img/icons/cargo.svg";
import rations from "static/img/icons/rations.svg";
import actioncards from "static/img/icons/actioncards.svg";
import battlemap from "static/img/icons/battlemap.svg";
import Battlemap from "./tabs/player/ship/Battlemap";
import Shuttle from "./tabs/player/ship/Shuttle";
import ActionCards from "./tabs/player/ship/ActionCards";
import Rations from "./tabs/player/ship/Rations";
import Cargo from "./tabs/player/ship/Cargo";
import Stations from "./tabs/player/ship/Stations";

function PlayerShip() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { shipId } = useParams();
  const { path, url } = useRouteMatch();

  const activePlayerShuttle = useSelector(getActivePlayerShuttle, shallowEqual);
  const isActiveUserFacilitator = useSelector(
    activeUserIsFacilitator,
    shallowEqual
  );
  const ship = useSelector((state) => {
    if (isActiveUserFacilitator) {
      return getShipById(state, shipId);
    }
    return getActivePlayerShip(state);
  }, shallowEqual);

  useEffect(() => {
    if (ship) {
      dispatch(setShipId(ship.id));
      if (parseInt(shipId, 10) !== ship.id) {
        history.push(`/ship/${ship.id}/stations`);
      }
    }

    return () => {
      dispatch(setShipId(null));
    };
  }, [dispatch, ship, history, shipId]);

  return (
    <Container
      fluid
      className="h-100 w-100 d-flex flex-column position-relative p-0"
    >
      <Nav variant="tabs" defaultActiveKey={`${url}/stations`}>
        <Nav.Item>
          <RouterNavLink to={`${url}/stations`}>
            <img src={stations} title="Stations" alt="Stations" height="24" />{" "}
            Stations
          </RouterNavLink>
        </Nav.Item>
        <Nav.Item>
          <RouterNavLink to={`${url}/cargo`}>
            <img
              src={cargo}
              title="Cargo &amp; Crew Transfer"
              alt="Cargo &amp; Crew Transfer"
              height="24"
            />{" "}
            Cargo &amp; Crew Transfer
          </RouterNavLink>
        </Nav.Item>
        <Nav.Item>
          <RouterNavLink to={`${url}/rations`}>
            <img
              src={rations}
              title="Rations &amp; Morale"
              alt="Rations &amp; Morale"
              height="24"
            />{" "}
            Rations &amp; Morale
          </RouterNavLink>
        </Nav.Item>
        <Nav.Item>
          <RouterNavLink to={`${url}/actioncards`}>
            <img
              src={actioncards}
              title="Action Cards"
              alt="Action Cards"
              height="24"
            />{" "}
            Action Cards
          </RouterNavLink>
        </Nav.Item>
        {activePlayerShuttle && (
          <Nav.Item>
            <RouterNavLink to={`${url}/shuttle/${activePlayerShuttle.id}`}>
              <img
                src={shuttle}
                title="Shuttle Control"
                alt="Shuttle Control"
                height="24"
              />{" "}
              Shuttle &quot;{activePlayerShuttle.name}&quot; Control
            </RouterNavLink>
          </Nav.Item>
        )}
        <Nav.Item>
          <RouterNavLink to={`${url}/battlemap`}>
            <img
              src={battlemap}
              title="Battlemap"
              alt="Battlemap"
              height="24"
            />{" "}
            Battlemap
          </RouterNavLink>
        </Nav.Item>
      </Nav>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${url}/stations`} />
        </Route>
        <Route path={`${path}/stations`}>
          <Stations />
        </Route>
        <Route path={`${path}/cargo`}>
          <Cargo />
        </Route>
        <Route path={`${path}/rations`}>
          <Rations />
        </Route>
        <Route path={`${path}/actioncards`}>
          <ActionCards />
        </Route>
        <Route path={`${path}/shuttle/:shuttleId`}>
          <Shuttle />
        </Route>
        <Route path={`${path}/battlemap`}>
          <Battlemap />
        </Route>
      </Switch>
    </Container>
  );
}

export default PlayerShip;
