import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getTransactionRequirement } from "store/ducks/transactionRequirement";
import { getShipById } from "store/ducks/ship";

function SpendResource({ requirementId, resource, updateRequirementsMet }) {
  const requirement = useSelector(
    (state) => getTransactionRequirement(state, requirementId),
    shallowEqual
  );
  const ship = useSelector(
    (state) => getShipById(state, requirement.ship_id),
    shallowEqual
  );

  const getResourceAmount = useCallback(() => {
    switch (resource) {
      case "Food":
        return ship.food;
      case "Water":
        return ship.water;
      case "Material":
        return ship.material;
      default:
        return undefined;
    }
  }, [resource, ship]);

  useEffect(() => {
    if (getResourceAmount() < requirement.quantity) {
      updateRequirementsMet(requirementId, false);
    } else {
      updateRequirementsMet(requirementId, true);
    }
  }, [getResourceAmount, requirement, requirementId, updateRequirementsMet]);

  return (
    <Row>
      <Col>
        <h4>Spend {resource}</h4>
        {getResourceAmount() < requirement.quantity && (
          <p>
            Insufficient {resource.toLowerCase()} for action. Need at least{" "}
            {requirement.quantity}, have {getResourceAmount()}.
          </p>
        )}
        {getResourceAmount() >= requirement.quantity && (
          <>
            <p>
              Will spend {requirement.quantity} {resource.toLowerCase()}.
            </p>
            <p>
              Current {resource.toLowerCase()}: {getResourceAmount()} <br />
              Resultant {resource.toLowerCase()}:{" "}
              {getResourceAmount() - requirement.quantity}
            </p>
          </>
        )}
      </Col>
    </Row>
  );
}

SpendResource.propTypes = {
  requirementId: PropTypes.number.isRequired,
  resource: PropTypes.oneOf(["Food", "Water", "Material"]).isRequired,
  updateRequirementsMet: PropTypes.func.isRequired,
};

export default SpendResource;
