import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { getActiveShip, getShips } from "store/ducks/ship";
import { getInWolfAttack } from "store/ducks/gameTurn";
import { activeUserIsFacilitator } from "store/ducks/user";
import { getShuttles, getActiveShuttle } from "store/ducks/shuttle";
import ShipResources from "./navBar/ShipResources";
import Timer from "./navBar/Timer";

function NavBar() {
  const activeShip = useSelector(getActiveShip, shallowEqual);
  const activeShuttle = useSelector(getActiveShuttle, shallowEqual);
  const ships = useSelector(getShips, shallowEqual);
  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);
  const shuttles = useSelector(getShuttles, shallowEqual);
  const inWolfAttack = useSelector(getInWolfAttack, shallowEqual);

  // I can't figure out how to get centered elements using Bootstrap's Navbar, so using a Container instead and copying the formatting
  // I don't think Dropdown.Items have an "active" property, which is why they're not using the LinkContainer wrappers from react-router-bootstrap (also because they didn't work)
  return (
    <Container fluid className="fixed-top navbar-dark bg-dark py-2 px-3">
      <Row>
        <Col className="align-self-center d-flex flex-row align-items-center">
          {isFacilitator && (
            <Dropdown className="mr-4">
              <Dropdown.Toggle>Facilitator</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Header>Facilitator</Dropdown.Header>
                <Dropdown.Item as={Link} to="/jump">
                  Jump Status
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/resources">
                  Ship &amp; Shuttle Resources
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/users">
                  User Management
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to="/battlemap">
                  Battlemap
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Header>Ships</Dropdown.Header>
                {ships.map((ship) => (
                  <Dropdown.Item
                    key={ship.id}
                    as={Link}
                    to={`/ship/${ship.id}`}
                  >
                    {ship.name}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Header>Shuttles</Dropdown.Header>
                {shuttles.map((shuttle) => (
                  <Dropdown.Item
                    key={shuttle.id}
                    as={Link}
                    to={`/shuttle/${shuttle.id}`}
                  >
                    {shuttle.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {activeShip && (
            <span className="navbar-text text-light header-ship-text">
              {activeShip.name}
            </span>
          )}
          {!activeShip && activeShuttle && (
            <span className="navbar-text text-light header-ship-text">
              {activeShuttle.name}
            </span>
          )}
        </Col>
        {inWolfAttack && (
          <Col className="text-center">
            <span className="navbar-text header-ship-text mx-1 text-nowrap font-weight-bold text-uppercase text-danger">
              WOLF ATTACK
            </span>
          </Col>
        )}
        {activeShip && (
          <Col className="text-center">
            <ShipResources shipId={activeShip.id} />
          </Col>
        )}
        {inWolfAttack && (
          <Col className="text-center">
            <span className="navbar-text header-ship-text mx-1 text-nowrap font-weight-bold text-uppercase text-danger">
              WOLF ATTACK
            </span>
          </Col>
        )}
        <Col className="d-flex flex-row align-items-center justify-content-end">
          <Timer />
        </Col>
      </Row>
    </Container>
  );
}

export default NavBar;
