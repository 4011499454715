import React, { useRef, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import FormattedText from "views/components/FormattedText";
import { getCombatLogMessages } from "store/ducks/combatLogMessage";

function CombatLog() {
  const combatLogMessages = useSelector(getCombatLogMessages, shallowEqual);

  const messagesEnd = useRef(null);

  const scrollToBottomOfMessageList = () => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView();
    }
  };

  useEffect(() => {
    scrollToBottomOfMessageList();
  });

  return (
    <div
      className="bg-light d-flex flex-column h-100 m-2"
      style={{ overflow: "auto" }}
    >
      <div
        className="d-flex flex-column w-100 h-100"
        style={{ minHeight: "min-content" }}
      >
        {combatLogMessages.map((message) => {
          return (
            <div key={message.id} className="m-1">
              <div className="small text-muted">
                {new Date(message.date_created).toLocaleTimeString()}
              </div>
              <FormattedText text={message.message} />
            </div>
          );
        })}
        <div style={{ float: "left", clear: "both" }} ref={messagesEnd} />
      </div>
    </div>
  );
}

export default CombatLog;
