import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import CrewDisplay from "views/components/CrewDisplay";
import { getTransactionRequirement } from "store/ducks/transactionRequirement";
import { getUsableCrewForTransaction } from "store/ducks/crew";

// TODO: Display validation error when duplicate crew selected

function UseCrewAtStation({
  requirementId,
  crewCount,
  updateRequirementsMet,
  updateRequirementsData,
}) {
  const requirement = useSelector(
    (state) => getTransactionRequirement(state, requirementId),
    shallowEqual
  );
  const usableCrew = useSelector(
    (state) =>
      getUsableCrewForTransaction(
        state,
        requirement.station_id,
        requirement.role,
        requirement.status
      ),
    shallowEqual
  );

  const [selectedCrew, setSelectedCrew] = useState(
    usableCrew.slice(0, crewCount).map((crew) => crew.id)
  );

  const updateRequirementsMetBasedOnSelectedCrew = useCallback(() => {
    const crewSet = new Set(selectedCrew);
    if (crewSet.size !== selectedCrew.length) {
      updateRequirementsMet(requirementId, false);
    } else {
      updateRequirementsMet(requirementId, true);
    }
  }, [requirementId, selectedCrew, updateRequirementsMet]);

  useEffect(() => {
    if (usableCrew.length < crewCount) {
      updateRequirementsMet(requirementId, false);
    }
    if (usableCrew.length >= crewCount) {
      updateRequirementsData(requirementId, selectedCrew);
      updateRequirementsMetBasedOnSelectedCrew();
    }
  }, [
    updateRequirementsMet,
    usableCrew,
    updateRequirementsData,
    requirementId,
    selectedCrew,
    crewCount,
    updateRequirementsMetBasedOnSelectedCrew,
  ]);

  const handleCrewSelectionUpdated = (crewNum, crewId) => {
    setSelectedCrew(
      selectedCrew.map((orig, index) => {
        if (crewNum === index) {
          return crewId;
        }
        return orig;
      })
    );
    updateRequirementsMetBasedOnSelectedCrew();
  };

  if (usableCrew.length < crewCount) {
    return (
      <Row>
        <Col>
          <h4>Use Crew at Station</h4>
          <p>
            Not enough crew found at the station that fit the requirements. Need{" "}
            {crewCount} that match the following:
          </p>
          <p>
            Role: {requirement.role === "Untrained" ? "Any" : requirement.role}
            <br />
            Status: {requirement.status}
          </p>
        </Col>
      </Row>
    );
  }

  if (usableCrew.length === crewCount) {
    return (
      <Row>
        <Col>
          <h4>Use Crew at Station</h4>
          <p>The following crew members will be used:</p>

          <ul>
            {usableCrew.map((crew) => {
              return (
                <li key={crew.id}>
                  <CrewDisplay
                    role={crew.role}
                    name={crew.name}
                    status={crew.status}
                  />
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    );
  }

  return (
    <Form>
      <Form.Group controlId="formUseCrewAtStationCrewSelect">
        <Form.Label>
          Please select the crew member(s) to use this station.
        </Form.Label>
        {[...Array(crewCount).keys()].map((i) => {
          return (
            <Form.Control
              as="select"
              custom
              onChange={(e) =>
                handleCrewSelectionUpdated(i, parseInt(e.target.value, 10))
              }
              key={i}
              defaultValue={selectedCrew[i]}
            >
              {usableCrew.map((crew) => {
                return (
                  <option value={crew.id} key={crew.id}>
                    {crew.role} - {crew.name}
                  </option>
                );
              })}
            </Form.Control>
          );
        })}
      </Form.Group>
    </Form>
  );
}

UseCrewAtStation.propTypes = {
  requirementId: PropTypes.number.isRequired,
  crewCount: PropTypes.number.isRequired,
  updateRequirementsMet: PropTypes.func.isRequired,
  updateRequirementsData: PropTypes.func.isRequired,
};

export default UseCrewAtStation;
