import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";

const getWolfUnits = (state) => Object.values(state.wolfUnits);

export const getWolfUnitsForQuadrant = (state, quadrant) => {
  return getWolfUnits(state).filter(
    (wolfUnit) => wolfUnit.quadrant === quadrant
  );
};

export const getWolfUnitById = (state, wolfUnitId) => {
  return state.wolfUnits ? state.wolfUnits[wolfUnitId] : {};
};

const FETCH_REQUESTED = "maas-loader/wolfUnit/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/wolfUnit/FETCH_SUCCEEDED";
const UPDATE_RECEIVED = "maas-loader/wolfUnit/UPDATE_RECEIVED";
const SPAWN_WOLF_UNIT_REQUESTED =
  "maas-loader/wolfUnit/SPAWN_WOLF_UNIT_REQUESTED";
const UPDATE_WOLF_UNIT_CURRENT_HEALTH =
  "maas-loader/wolfUnit/UPDATE_WOLF_UNIT_CURRENT_HEALTH";
const DELETE_RECEIVED = "maas-loader/wolfUnit/DELETE_RECEIVED";
const DELETE_WOLF_UNIT_REQUESTED =
  "maas-loader/wolfUnit/DELETE_WOLF_UNIT_REQUESTED";
const MOVE_WOLF_UNIT_REQUESTED =
  "maas-loader/wolfUnit/MOVE_WOLF_UNIT_REQUESTED";
const BOARD_REQUESTED = "maas-loader/wolfUnit/BOARD_REQUESTED";
const ATTACK_REQUESTED = "maas-loader/wolfUnit/ATTACK_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { wolfUnits } = action;
      return wolfUnits;
    }
    case UPDATE_RECEIVED: {
      const { wolfUnit } = action;
      return { ...state, [wolfUnit.id]: wolfUnit };
    }
    case DELETE_RECEIVED: {
      const { wolfUnit } = action;
      const { [wolfUnit.id]: _, ...rest } = state;
      return rest;
    }
    case MOVE_WOLF_UNIT_REQUESTED: {
      const { wolfUnitId, quadrant } = action;
      return {
        ...state,
        [wolfUnitId]: {
          ...state[wolfUnitId],
          quadrant,
        },
      };
    }
    case UPDATE_WOLF_UNIT_CURRENT_HEALTH: {
      const { wolfUnitId, currentHealth } = action;
      return {
        ...state,
        [wolfUnitId]: {
          ...state[wolfUnitId],
          current_health: currentHealth,
        },
      };
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(wolfUnits) {
  return { type: FETCH_SUCCEEDED, wolfUnits };
}

export function updateReceived(wolfUnit) {
  return { type: UPDATE_RECEIVED, wolfUnit };
}

export function requestSpawnWolfUnit(wolfUnitType, quadrant) {
  return { type: SPAWN_WOLF_UNIT_REQUESTED, wolfUnitType, quadrant };
}

export function requestUpdateWolfUnitCurrentHealth(wolfUnitId, currentHealth) {
  return { type: UPDATE_WOLF_UNIT_CURRENT_HEALTH, wolfUnitId, currentHealth };
}

export function deleteReceived(wolfUnit) {
  return { type: DELETE_RECEIVED, wolfUnit };
}

export function requestDelete(wolfUnitId) {
  return { type: DELETE_WOLF_UNIT_REQUESTED, wolfUnitId };
}

export function requestMoveWolfUnit(wolfUnitId, quadrant) {
  return { type: MOVE_WOLF_UNIT_REQUESTED, wolfUnitId, quadrant };
}

export function requestBoard(wolfUnitId) {
  return { type: BOARD_REQUESTED, wolfUnitId };
}

export function requestAttack(wolfUnitId) {
  return { type: ATTACK_REQUESTED, wolfUnitId };
}

function* fetchWolfUnits() {
  try {
    const wolfUnitResponse = yield call(() => axios.get("/api/v1/wolf_unit/"));
    const wolfUnits = {};
    Object.values(wolfUnitResponse.data).forEach((wolfUnit) => {
      wolfUnits[wolfUnit.id] = wolfUnit;
    });
    yield put(fetchSucceeded(wolfUnits));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchWolfUnits() {
  yield takeLatest(FETCH_REQUESTED, fetchWolfUnits);
}

function* spawnWolfUnit(action) {
  const { wolfUnitType, quadrant } = action;
  try {
    yield call(() =>
      axios.put(
        `/api/v1/wolf_unit/spawn_wolf_unit/${wolfUnitType}/quadrant/${quadrant}`,
        {}
      )
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchSpawnWolfUnit() {
  yield takeEvery(SPAWN_WOLF_UNIT_REQUESTED, spawnWolfUnit);
}

function* updateWolfUnitCurrentHealth(action) {
  const { wolfUnitId, currentHealth } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/wolf_unit/${wolfUnitId}`, {
        current_health: currentHealth,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateWolfUnitCurrentHealth() {
  yield takeEvery(UPDATE_WOLF_UNIT_CURRENT_HEALTH, updateWolfUnitCurrentHealth);
}

function* deleteWolfUnit(action) {
  const { wolfUnitId } = action;
  try {
    yield call(() => axios.delete(`/api/v1/wolf_unit/${wolfUnitId}`));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchDeleteWolfUnit() {
  yield takeEvery(DELETE_WOLF_UNIT_REQUESTED, deleteWolfUnit);
}

function* moveWolfUnit(action) {
  const { wolfUnitId, quadrant } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/wolf_unit/${wolfUnitId}`, {
        quadrant,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchMoveWolfUnit() {
  yield takeEvery(MOVE_WOLF_UNIT_REQUESTED, moveWolfUnit);
}

function* board(action) {
  const { wolfUnitId } = action;
  try {
    yield call(() => axios.put(`/api/v1/wolf_unit/${wolfUnitId}/board`, {}));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchBoard() {
  yield takeEvery(BOARD_REQUESTED, board);
}

function* attack(action) {
  const { wolfUnitId } = action;
  try {
    yield call(() => axios.put(`/api/v1/wolf_unit/${wolfUnitId}/attack`, {}));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchAttack() {
  yield takeEvery(ATTACK_REQUESTED, attack);
}
