import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { createSelector } from "reselect";
import { getActiveUser, requestUpdateUser } from "./user";

export const getPlayers = (state) => Object.values(state.players);

export const getActivePlayer = (state) => {
  const user = getActiveUser(state);
  if (!user || user.type === "facilitator" || user.type === "news") {
    return null;
  }
  return state.players ? state.players[user.player_id] : {};
};

export const getPlayerById = (state, playerId) =>
  state.players ? state.players[playerId] : {};

export const activePlayerIsCaptain = createSelector(
  [getActivePlayer],
  (activePlayer) => {
    return (
      activePlayer &&
      ["Captain", "Admiral", "President"].includes(activePlayer.role)
    );
  }
);

const FETCH_REQUESTED = "maas-loader/player/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/player/FETCH_SUCCEEDED";
const UPDATE_RECEIVED = "maas-loader/player/UPDATE_RECEIVED";
const CREATE_PLAYER_REQUESTED = "maas-loader/player/CREATE_PLAYER_REQUESTED";
const UPDATE_PLAYER_REQUESTED = "maas-loader/player/UPDATE_PLAYER_REQUESTED";
const DELETE_RECEIVED = "maas-loader/player/DELETE_RECEIVED";
const DELETE_PLAYER_REQUESTED = "maas-loader/player/DELETE_PLAYER_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { players } = action;
      return players;
    }
    case UPDATE_RECEIVED: {
      const { player } = action;
      return { ...state, [player.id]: player };
    }
    case DELETE_RECEIVED: {
      const { player } = action;
      const { [player.id]: _, ...rest } = state;
      return rest;
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(players) {
  return { type: FETCH_SUCCEEDED, players };
}

export function updateReceived(player) {
  return { type: UPDATE_RECEIVED, player };
}

export function requestCreatePlayer(player, userId) {
  return { type: CREATE_PLAYER_REQUESTED, player, userId };
}

export function requestUpdatePlayer(playerId, player) {
  return { type: UPDATE_PLAYER_REQUESTED, playerId, player };
}

export function deleteReceived(player) {
  return { type: DELETE_RECEIVED, player };
}

export function requestDeletePlayer(playerId) {
  return { type: DELETE_PLAYER_REQUESTED, playerId };
}

function* fetchPlayers() {
  try {
    const playerResponse = yield call(() => axios.get("/api/v1/player/"));
    const players = {};
    Object.values(playerResponse.data).forEach((player) => {
      players[player.id] = player;
    });
    yield put(fetchSucceeded(players));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchPlayers() {
  yield takeLatest(FETCH_REQUESTED, fetchPlayers);
}

function* createPlayer(action) {
  const { player, userId } = action;
  try {
    const playerResponse = yield call(() =>
      axios.post("/api/v1/player/", player)
    );
    const playerId = playerResponse.data.id;
    yield put(requestUpdateUser(userId, { player_id: playerId }));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchCreatePlayer() {
  yield takeEvery(CREATE_PLAYER_REQUESTED, createPlayer);
}

function* updatePlayer(action) {
  const { playerId, player } = action;
  try {
    yield call(() => axios.put(`/api/v1/player/${playerId}`, player));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdatePlayer() {
  yield takeEvery(UPDATE_PLAYER_REQUESTED, updatePlayer);
}

function* deletePlayer(action) {
  const { playerId } = action;
  try {
    yield call(() => axios.delete(`/api/v1/player/${playerId}`));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchDeletePlayer() {
  yield takeEvery(DELETE_PLAYER_REQUESTED, deletePlayer);
}
