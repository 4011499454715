import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { setDiscordName } from "store/ducks/display";

function Login() {
  const dispatch = useDispatch();

  const [cookies] = useCookies(["jwt"]);

  useEffect(() => {
    if (cookies.jwt) {
      const decoded = jwtDecode(cookies.jwt);
      dispatch(setDiscordName(decoded.sub));
    } else {
      window.location.replace("/api/v1/discord/login");
    }
  });

  return <Redirect to="/" />;
}

export default Login;
