import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  getUsers,
  getUserById,
  requestCreateUser,
  requestUpdateUser,
} from "store/ducks/user";
import {
  getPlayerById,
  requestCreatePlayer,
  requestUpdatePlayer,
  requestDeletePlayer,
} from "store/ducks/player";
import { getShips } from "store/ducks/ship";

function NewUserRow() {
  const dispatch = useDispatch();

  const ships = useSelector(getShips, shallowEqual);

  const [discordName, setDiscordName] = useState("");

  const [type, setType] = useState("player");

  const [name, setName] = useState("");

  const [role, setRole] = useState("");

  const [shipId, setShipId] = useState(1);

  const [hasBattlemapAccess, setHasBattlemapAccess] = useState(false);

  return (
    <tr>
      <td>
        <Form.Control
          onChange={(e) => setDiscordName(e.target.value)}
          value={discordName}
        />
      </td>
      <td>
        <Form.Control
          as="select"
          onChange={(e) => setType(e.target.value)}
          value={type}
        >
          <option value="facilitator">Facilitator</option>
          <option value="player">Player</option>
          <option value="news">News</option>
        </Form.Control>
      </td>
      <td>
        <Form.Control
          onChange={(e) => setName(e.target.value)}
          value={name}
          disabled={type !== "player"}
        />
      </td>
      <td>
        <Form.Control
          onChange={(e) => setRole(e.target.value)}
          value={role}
          disabled={type !== "player"}
        />
      </td>
      <td>
        <Form.Control
          as="select"
          onChange={(e) => setShipId(e.target.value)}
          value={shipId}
          disabled={type !== "player"}
        >
          {type === "player" &&
            ships.map((ship) => (
              <option key={ship.id} value={ship.id}>
                {ship.name}
              </option>
            ))}
        </Form.Control>
      </td>
      <td>
        <Form.Check
          type="checkbox"
          checked={hasBattlemapAccess}
          onChange={(e) => setHasBattlemapAccess(e.target.checked)}
          disabled={type !== "player"}
        />
      </td>
      <td>
        <Button
          onClick={() => {
            dispatch(
              requestCreateUser({
                discord_name: discordName,
                type,
                name,
                role,
                ship_id: shipId,
                has_battlemap_access: hasBattlemapAccess,
              })
            );
            setDiscordName("");
            setType("player");
            setName("");
            setRole("");
            setShipId(1);
            setHasBattlemapAccess(false);
          }}
        >
          Create
        </Button>
      </td>
    </tr>
  );
}

function UserRow({ userId }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => getUserById(state, userId));
  const player = useSelector(
    (state) => (user.player_id ? getPlayerById(state, user.player_id) : null),
    shallowEqual
  );
  const ships = useSelector(getShips, shallowEqual);

  const [discordName, setDiscordName] = useState(user.discord_name);

  useEffect(() => {
    setDiscordName(user.discord_name);
  }, [user.discord_name]);

  const [type, setType] = useState(user.type);

  useEffect(() => {
    setType(user.type);
  }, [user.type]);

  const [name, setName] = useState(player ? player.name : "");

  useEffect(() => {
    setName(player ? player.name : "");
  }, [player]);

  const [role, setRole] = useState(player ? player.role : "");

  useEffect(() => {
    setRole(player ? player.role : "");
  }, [player]);

  const [shipId, setShipId] = useState(player ? player.ship_id : 1);

  useEffect(() => {
    setShipId(player ? player.ship_id : 1);
  }, [player]);

  const [hasBattlemapAccess, setHasBattlemapAccess] = useState(
    player ? player.has_battlemap_access : false
  );

  useEffect(() => {
    setHasBattlemapAccess(player ? player.has_battlemap_access : false);
  }, [player]);

  return (
    <tr>
      <td>
        <Form.Control
          onChange={(e) => setDiscordName(e.target.value)}
          value={discordName}
        />
      </td>
      <td>
        <Form.Control
          as="select"
          onChange={(e) => {
            setType(e.target.value);
            if (e.target.value === "player") {
              setName("");
              setRole("");
              setShipId(1);
              setHasBattlemapAccess(false);
            }
          }}
          value={type}
        >
          <option value="facilitator">Facilitator</option>
          <option value="player">Player</option>
          <option value="news">News</option>
        </Form.Control>
      </td>
      <td>
        <Form.Control
          onChange={(e) => setName(e.target.value)}
          value={name}
          disabled={type !== "player"}
        />
      </td>
      <td>
        <Form.Control
          onChange={(e) => setRole(e.target.value)}
          value={role}
          disabled={type !== "player"}
        />
      </td>
      <td>
        <Form.Control
          as="select"
          onChange={(e) => setShipId(e.target.value)}
          value={shipId}
          disabled={type !== "player"}
        >
          {type === "player" &&
            ships.map((ship) => (
              <option key={ship.id} value={ship.id}>
                {ship.name}
              </option>
            ))}
        </Form.Control>
      </td>
      <td>
        <Form.Check
          type="checkbox"
          checked={hasBattlemapAccess}
          onChange={(e) => setHasBattlemapAccess(e.target.checked)}
          disabled={type !== "player"}
        />
      </td>
      <td>
        <Button
          onClick={() => {
            if (type !== "player") {
              dispatch(
                requestUpdateUser(userId, {
                  discord_name: discordName,
                  type,
                  player_id: null,
                })
              );
              if (user.type === "player" && user.player_id) {
                dispatch(requestDeletePlayer(user.player_id));
                setName("");
                setRole("");
                setShipId(1);
                setHasBattlemapAccess(false);
              }
            } else {
              dispatch(
                requestUpdateUser(userId, {
                  discord_name: discordName,
                  type,
                })
              );
              if (user.type === "player" && user.player_id) {
                dispatch(
                  requestUpdatePlayer(user.player_id, {
                    name,
                    role,
                    ship_id: shipId,
                    has_battlemap_access: hasBattlemapAccess,
                  })
                );
              } else {
                dispatch(
                  requestCreatePlayer(
                    {
                      name,
                      role,
                      ship_id: shipId,
                      has_battlemap_access: hasBattlemapAccess,
                    },
                    userId
                  )
                );
              }
            }
          }}
        >
          Save
        </Button>
      </td>
    </tr>
  );
}

UserRow.propTypes = {
  userId: PropTypes.number.isRequired,
};

function Users() {
  const users = useSelector(getUsers, shallowEqual);

  return (
    <Container
      fluid
      className="h-100 w-100 d-flex flex-column position-relative p-0"
    >
      <section className="mb-0 py-4 mx-2">
        <h2 className="text-center text-uppercase">User Management</h2>
        <Container fluid>
          <Table responsive>
            <thead>
              <tr>
                <th>Discord Name</th>
                <th>Type</th>
                <th>Name</th>
                <th>Role</th>
                <th>Ship</th>
                <th>Has Battlemap Access?</th>
                <th>Save</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <UserRow key={user.id} userId={user.id} />
              ))}
              <NewUserRow />
            </tbody>
          </Table>
        </Container>
      </section>
    </Container>
  );
}

export default Users;
