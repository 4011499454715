import React from "react";
import PropTypes from "prop-types";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";

// Necessary to set active 'false' so back/forward clears active tab properly
// https://github.com/react-bootstrap/react-router-bootstrap/issues/242#issuecomment-480330910
function RouterNavLink({ children, to }) {
  return (
    <LinkContainer to={to}>
      <Nav.Link active={false}>{children}</Nav.Link>
    </LinkContainer>
  );
}

RouterNavLink.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  ).isRequired,
  to: PropTypes.string.isRequired,
};

export default RouterNavLink;
