import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import CrewDisplay from "views/components/CrewDisplay";
import FormattedText from "views/components/FormattedText";
import { getStation, requestSuppressRioters } from "store/ducks/station";
import { getShipSecurityForRiotSuppression } from "store/ducks/crew";
import {
  getUpdateSucceeded,
  getSuccessMessage,
  getErrorMessage,
  clearUpdateStatus,
} from "store/ducks/display";

function SuppressRioters({ show, onHide, stationId }) {
  const dispatch = useDispatch();

  const station = useSelector(
    (state) => getStation(state, stationId),
    shallowEqual
  );
  const securityCrew = useSelector(
    getShipSecurityForRiotSuppression,
    shallowEqual
  );
  const updateSucceeded = useSelector(getUpdateSucceeded, shallowEqual);
  const successMessage = useSelector(getSuccessMessage, shallowEqual);
  const errorMessage = useSelector(getErrorMessage, shallowEqual);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [successAlertMessage, setSuccessAlertMessage] = useState("");
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedCrew, setSelectedCrew] = useState(
    securityCrew.length > 0 ? securityCrew[0].id : 0
  );

  useEffect(() => {
    if (updateSucceeded === undefined) {
      setShowSuccessAlert(false);
      setShowErrorAlert(false);
      setSuccessAlertMessage("");
      setErrorAlertMessage("");
    }
    if (updateSucceeded !== undefined) {
      setLoading(false);
      if (updateSucceeded !== showSuccessAlert) {
        setShowSuccessAlert(updateSucceeded);
      }
      if (updateSucceeded === showErrorAlert) {
        setShowErrorAlert(!updateSucceeded);
      }
    }
    if (
      successMessage !== undefined &&
      successMessage !== successAlertMessage
    ) {
      setSuccessAlertMessage(successMessage);
    }
    if (errorMessage !== undefined && errorMessage !== errorAlertMessage) {
      setErrorAlertMessage(errorMessage);
    }
    if (
      !securityCrew.map((crew) => crew.id).includes(parseInt(selectedCrew, 10))
    ) {
      setSelectedCrew(securityCrew.length > 0 ? securityCrew[0].id : 0);
    }
  }, [
    updateSucceeded,
    showSuccessAlert,
    showErrorAlert,
    errorMessage,
    errorAlertMessage,
    successMessage,
    successAlertMessage,
    securityCrew,
    selectedCrew,
  ]);

  const clearAlert = () => {
    if (updateSucceeded !== undefined) {
      dispatch(clearUpdateStatus());
    }
  };

  const getCrewDisplayForCrewMember = (crewMember) => {
    return (
      <CrewDisplay
        role={crewMember.role}
        name={crewMember.name}
        status={crewMember.status}
      />
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={() => clearAlert()}
      size="lg"
      aria-labelledby="suppressRiotersModalTitle"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="suppressRiotersModalTitle">
          Suppress Rioters
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert
          show={showSuccessAlert}
          variant="success"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Suppression Result</Alert.Heading>
          <FormattedText text={successAlertMessage} />
        </Alert>
        <Alert
          show={showErrorAlert}
          variant="danger"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Error</Alert.Heading>
          {errorAlertMessage}
        </Alert>
        {station.status === "rioting" && (
          <>
            {station.rioter_status === "active" && (
              <p>
                Station is actively rioting, and may spread at the end of the
                turn. This action will either suppress it for this turn, or
                disperse it completely.
              </p>
            )}
            {station.rioter_status !== "active" && (
              <p>
                The riot at this station is suppressed and will not spread this
                turn, but will become active next turn and will still prevent
                the use of this station.
              </p>
            )}
            {securityCrew.length === 0 && (
              <p>
                At least one unused Security unit must be posted on this ship in
                order to suppress the riot.
              </p>
            )}
            {securityCrew.length === 1 && (
              <p>
                Are you sure you want to use crew member{" "}
                {getCrewDisplayForCrewMember(securityCrew[0])} to attempt to
                suppress the riot?
                {securityCrew[0].status === "Wounded" && (
                  <span> If they take damage again, they will die.</span>
                )}
              </p>
            )}
            {securityCrew.length > 1 && (
              <Form>
                <Form.Group controlId="formGroupCrewSelect">
                  <Form.Label>
                    Please choose a crew member to attempt to disperse the riot.
                    You may use wounded crew, but if they take damage again,
                    they will die.
                  </Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    onChange={(e) => setSelectedCrew(e.target.value)}
                  >
                    {securityCrew.map((crew) => {
                      return (
                        <option value={crew.id} key={crew.id}>
                          {crew.status === "Normal" ? "Healthy" : "Wounded"} -{" "}
                          {crew.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Form>
            )}
            {securityCrew.length > 0 && (
              <>
                <p>
                  The results of this attempt will be determined by a d6 roll,
                  as follows:
                </p>
                <p>
                  1: Fleet crew wounded. Riot suppressed this turn.
                  <br />
                  2-3: Riot suppressed this turn.
                  <br />
                  4-6: Riot dispersed, station available for use next turn.
                </p>
              </>
            )}
          </>
        )}
        {station.status !== "rioting" && (
          <p>The riot at this station has been dispersed.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={
            isLoading ||
            securityCrew.length === 0 ||
            station.status !== "rioting"
          }
          onClick={() => {
            clearAlert();
            dispatch(requestSuppressRioters(stationId, selectedCrew));
            setLoading(true);
          }}
        >
          Commit Crew
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SuppressRioters.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  stationId: PropTypes.number.isRequired,
};

export default SuppressRioters;
