import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Morale from "views/components/navBar/resources/Morale";
import Resource from "views/components/navBar/resources/Resource";
import ResearchPoints from "views/components/navBar/resources/ResearchPoints";
import food from "static/img/icons/food.svg";
import water from "static/img/icons/water.svg";
import ore from "static/img/icons/ore.svg";
import fuel from "static/img/icons/fuel.svg";
import material from "static/img/icons/material.svg";
import { getShips, getShipById, getShipCargoTotals } from "store/ducks/ship";
import { getResearchPoints } from "store/ducks/researchPoints";
import {
  getLoadingDockForShip,
  getLoadingDockTotals,
} from "store/ducks/loadingDock";
import { getShuttles, getShuttle } from "store/ducks/shuttle";
import {
  getShuttleCargoForShuttle,
  getShuttleCargoTotals,
} from "store/ducks/shuttleCargo";
import { getCrewForShipId, getCrewForShuttle } from "store/ducks/crew";
import { getStationsForShip } from "store/ducks/station";

function TotalResourcesRow() {
  const shipCargoTotals = useSelector(getShipCargoTotals, shallowEqual);
  const loadingDockTotals = useSelector(getLoadingDockTotals, shallowEqual);
  const shuttleCargoTotals = useSelector(getShuttleCargoTotals, shallowEqual);

  return (
    <tr>
      <th>Total</th>
      <td />
      <td>
        {shipCargoTotals[1] + loadingDockTotals[1] + shuttleCargoTotals[1]}
      </td>
      <td>
        {shipCargoTotals[2] + loadingDockTotals[2] + shuttleCargoTotals[2]}
      </td>
      <td>
        {shipCargoTotals[3] + loadingDockTotals[3] + shuttleCargoTotals[3]}
      </td>
      <td>
        {shipCargoTotals[4] + loadingDockTotals[4] + shuttleCargoTotals[4]}
      </td>
      <td>
        {shipCargoTotals[5] + loadingDockTotals[5] + shuttleCargoTotals[5]}
      </td>
      <td />
      <td />
      <td />
    </tr>
  );
}

function ShuttleResourcesRow({ shuttleId }) {
  const shuttle = useSelector(
    (state) => getShuttle(state, shuttleId),
    shallowEqual
  );
  const shuttleCargo = useSelector(
    (state) => getShuttleCargoForShuttle(state, shuttleId),
    shallowEqual
  );
  const crew = useSelector((state) => getCrewForShuttle(state, shuttleId));

  return (
    <tr>
      <td>
        <Link to={`/shuttle/${shuttle.id}`}>{shuttle.name}</Link>
      </td>
      <td />
      <td>{shuttleCargo[1]}</td>
      <td>{shuttleCargo[2]}</td>
      <td>{shuttleCargo[3]}</td>
      <td>{shuttleCargo[4]}</td>
      <td>{shuttleCargo[5]}</td>
      <td />
      <td>{crew.length}</td>
      <td />
    </tr>
  );
}

ShuttleResourcesRow.propTypes = {
  shuttleId: PropTypes.number.isRequired,
};

function ShipResourceRow({ shipId }) {
  const ship = useSelector((state) => getShipById(state, shipId), shallowEqual);
  const researchPoints = useSelector(getResearchPoints, shallowEqual);
  const loadingDock = useSelector(
    (state) => getLoadingDockForShip(state, shipId),
    shallowEqual
  );
  const crew = useSelector(
    (state) => getCrewForShipId(state, shipId),
    shallowEqual
  );
  const stations = useSelector(
    (state) => getStationsForShip(state, shipId),
    shallowEqual
  );

  return (
    <>
      <tr>
        <td>
          <Link to={`/ship/${ship.id}`}>{ship.name}</Link>
        </td>
        <td>
          <Morale shipId={ship.id} morale={ship.morale} />
        </td>
        <td>
          <Resource
            shipId={ship.id}
            resource="food"
            value={ship.food}
            img={food}
          />
        </td>
        <td>
          <Resource
            shipId={ship.id}
            resource="water"
            value={ship.water}
            img={water}
          />
        </td>
        <td>
          <Resource
            shipId={ship.id}
            resource="ore"
            value={ship.ore}
            img={ore}
          />
        </td>
        <td>
          <Resource
            shipId={ship.id}
            resource="fuel"
            value={ship.fuel}
            img={fuel}
          />
        </td>
        <td>
          <Resource
            shipId={ship.id}
            resource="material"
            value={ship.material}
            img={material}
          />
        </td>
        <td>{ship.id === 9 && <ResearchPoints points={researchPoints} />}</td>
        <td>
          {
            crew.filter(
              (crewMember) =>
                !["Wounded", "Wounded-Tired", "Dead"].includes(
                  crewMember.status
                )
            ).length
          }
          /
          {
            crew.filter((crewMember) =>
              ["Wounded", "Wounded-Tired"].includes(crewMember.status)
            ).length
          }
          /{crew.filter((crewMember) => crewMember.status === "Dead").length}
        </td>
        <td>
          {
            stations.filter(
              (station) => !["damaged", "rioting"].includes(station.status)
            ).length
          }
          /{stations.filter((station) => station.status === "damaged").length}/
          {stations.filter((station) => station.status === "rioting").length}
        </td>
      </tr>
      <tr>
        <td>
          <Link to={`/ship/${ship.id}/cargo`}>{ship.name} Loading Dock</Link>
        </td>
        <td />
        <td>{loadingDock[1]}</td>
        <td>{loadingDock[2]}</td>
        <td>{loadingDock[3]}</td>
        <td>{loadingDock[4]}</td>
        <td>{loadingDock[5]}</td>
        <td />
        <td />
        <td />
      </tr>
    </>
  );
}

ShipResourceRow.propTypes = {
  shipId: PropTypes.number.isRequired,
};

function Resources() {
  const ships = useSelector(getShips, shallowEqual);
  const shuttles = useSelector(getShuttles, shallowEqual);

  return (
    <Container
      fluid
      className="h-100 w-100 d-flex flex-column position-relative p-0"
    >
      <section className="mb-0 py-4 mx-2">
        <h2 className="text-center text-uppercase">Resources</h2>
        <Container fluid>
          <Table responsive>
            <thead>
              <tr>
                <th>Ship Name</th>
                <th>Morale</th>
                <th>Food</th>
                <th>Water</th>
                <th>Ore</th>
                <th>Fuel</th>
                <th>Material</th>
                <th>Research Points</th>
                <th>Crew (Normal/Injured/Dead)</th>
                <th>Stations (Normal/Damaged/Rioting)</th>
              </tr>
            </thead>
            <thead>
              <TotalResourcesRow />
            </thead>
            <tbody>
              {ships.map((ship) => (
                <ShipResourceRow key={ship.id} shipId={ship.id} />
              ))}
            </tbody>
            <thead>
              <tr>
                <th>Shuttle Name</th>
                <th />
                <th>Food</th>
                <th>Water</th>
                <th>Ore</th>
                <th>Fuel</th>
                <th>Material</th>
                <th />
                <th>Crew (Total)</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {shuttles.map((shuttle) => (
                <ShuttleResourcesRow key={shuttle.id} shuttleId={shuttle.id} />
              ))}
            </tbody>
          </Table>
        </Container>
      </section>
    </Container>
  );
}

export default Resources;
