import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getTransactionEffect } from "store/ducks/transactionEffect";
import { getResearchPoints } from "store/ducks/researchPoints";

function AddResearchPoints({ effectId }) {
  const effect = useSelector(
    (state) => getTransactionEffect(state, effectId),
    shallowEqual
  );
  const researchPoints = useSelector(getResearchPoints, shallowEqual);

  return (
    <Row>
      <Col>
        <h4>Add Research Points</h4>
        <p>
          Research points will go from {researchPoints} to{" "}
          {researchPoints + effect.quantity}.
        </p>
      </Col>
    </Row>
  );
}

AddResearchPoints.propTypes = {
  effectId: PropTypes.number.isRequired,
};

export default AddResearchPoints;
