import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";

const getFighters = (state) => Object.values(state.fighters);

export const getFightersForShip = (state, shipId) => {
  return getFighters(state).filter((fighter) => fighter.ship_id === shipId);
};

export const getFightersForQuadrant = (state, quadrant) => {
  return getFighters(state).filter((fighter) => fighter.quadrant === quadrant);
};

export const getFighterById = (state, fighterId) => {
  return state.fighters ? state.fighters[fighterId] : {};
};

const FETCH_REQUESTED = "maas-loader/fighter/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/fighter/FETCH_SUCCEEDED";
const UPDATE_RECEIVED = "maas-loader/fighter/UPDATE_RECEIVED";
const MOVE_FIGHTER_REQUESTED = "maas-loader/fighter/MOVE_FIGHTER_REQUESTED";
const UPDATE_FIGHTER_STATUS_REQUESTED =
  "maas-loader/fighter/UPDATE_FIGHTER_STATUS_REQUESTED";
const UPDATE_FIGHTER_USED_REQUESTED =
  "maas-loader/fighter/UPDATE_FIGHTER_USED_REQUESTED";
const ATTACK_REQUESTED = "maas-loader/fighter/ATTACK_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { fighters } = action;
      return fighters;
    }
    case UPDATE_RECEIVED: {
      const { fighter } = action;
      return { ...state, [fighter.id]: fighter };
    }
    case MOVE_FIGHTER_REQUESTED: {
      const { fighterId, quadrant, inWolfAttack } = action;
      return {
        ...state,
        [fighterId]: {
          ...state[fighterId],
          quadrant,
          used: inWolfAttack,
        },
      };
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(fighters) {
  return { type: FETCH_SUCCEEDED, fighters };
}

export function updateReceived(fighter) {
  return { type: UPDATE_RECEIVED, fighter };
}

export function requestMoveFighter(fighterId, quadrant, inWolfAttack) {
  return { type: MOVE_FIGHTER_REQUESTED, fighterId, quadrant, inWolfAttack };
}

export function requestUpdateFighterStatus(fighterId, status) {
  return { type: UPDATE_FIGHTER_STATUS_REQUESTED, fighterId, status };
}

export function requestUpdateFighterUsed(fighterId, used) {
  return { type: UPDATE_FIGHTER_USED_REQUESTED, fighterId, used };
}

export function requestAttack(fighterId, modifier) {
  return { type: ATTACK_REQUESTED, fighterId, modifier };
}

function* fetchFighters() {
  try {
    const fightersResponse = yield call(() => axios.get("/api/v1/fighter/"));
    const fighters = {};
    Object.values(fightersResponse.data).forEach((fighter) => {
      fighters[fighter.id] = fighter;
    });
    yield put(fetchSucceeded(fighters));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchFighters() {
  yield takeLatest(FETCH_REQUESTED, fetchFighters);
}

function* moveFighter(action) {
  const { fighterId, quadrant, inWolfAttack } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/fighter/${fighterId}`, {
        quadrant,
        used: inWolfAttack,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchMoveFighter() {
  yield takeEvery(MOVE_FIGHTER_REQUESTED, moveFighter);
}

function* updateFighterStatus(action) {
  const { fighterId, status } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/fighter/${fighterId}`, {
        status,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateFighterStatus() {
  yield takeEvery(UPDATE_FIGHTER_STATUS_REQUESTED, updateFighterStatus);
}

function* updateFighterUsed(action) {
  const { fighterId, used } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/fighter/${fighterId}`, {
        used,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateFighterUsed() {
  yield takeEvery(UPDATE_FIGHTER_USED_REQUESTED, updateFighterUsed);
}

function* attack(action) {
  const { fighterId, modifier } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/fighter/${fighterId}/attack?modifier=${modifier}`, {})
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchAttack() {
  yield takeEvery(ATTACK_REQUESTED, attack);
}
