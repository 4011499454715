import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import ShipSlot from "./ShipSlot";

function ShipRow({ ships, quadrant }) {
  return (
    <Row
      className={`d-flex w-100 m-0 ${
        quadrant.endsWith("W") ? "flex-row" : "flex-row-reverse ml-0"
      }`}
    >
      <ShipSlot quadrant={quadrant} slotId={1} size={2} ships={ships} />
      <ShipSlot quadrant={quadrant} slotId={3} size={2} ships={ships} />
      <ShipSlot quadrant={quadrant} slotId={5} size={1} ships={ships} />
    </Row>
  );
}

ShipRow.propTypes = {
  ships: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
      size: PropTypes.number,
      boardingUnits: PropTypes.number,
    })
  ).isRequired,
  quadrant: PropTypes.oneOf(["NE", "NW", "SE", "SW"]).isRequired,
};

export default ShipRow;
