import React from "react";
import PropTypes from "prop-types";
import replace from "string-replace-to-array";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function FormattedText({ text }) {
  // Convert the array from string-replace-to-array to a Fragment to avoid key errors
  return React.createElement(
    React.Fragment,
    {},
    ...replace(
      text,
      // Note that this doesn't support nesting of tags for ones with closing tags (i.e. bold)
      /\[([a-zA-Z0-9-]+)\](?:((?:(?!\[).)*)\[\/[a-zA-Z0-9-]+\])?/gi,
      (_, tag, textToModify) => {
        if (textToModify) {
          switch (tag.toLowerCase()) {
            case "b":
              return <span className="font-weight-bold">{textToModify}</span>;
            default:
              return textToModify;
          }
        }
        switch (tag.toLowerCase()) {
          case "br":
            return <br />;
          default:
            try {
              return (
                <img
                  src={require(`static/img/icons/${tag.toLowerCase()}.svg`)} // eslint-disable-line global-require, import/no-dynamic-require
                  alt={capitalizeFirstLetter(tag)}
                  title={capitalizeFirstLetter(tag)}
                  height="24"
                />
              );
            } catch (err) {
              return `[${tag}]`;
            }
        }
      }
    )
  );
}

FormattedText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FormattedText;
