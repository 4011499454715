import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { createSelector } from "reselect";
import { getShipId } from "./display";

const getLoadingDocks = (state) => Object.values(state.loadingDock);

export const getLoadingDockTotals = (state) => {
  const loadingDocks = getLoadingDocks(state);
  const cargo = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
  loadingDocks.forEach((entry) => {
    cargo[entry.cargo_id] += entry.qty;
  });
  return cargo;
};

export const getLoadingDockForShip = (state, shipId) => {
  const loadingDocks = getLoadingDocks(state);
  const cargo = {};
  loadingDocks
    .filter((entry) => entry.ship_id === shipId)
    .forEach((entry) => {
      cargo[entry.cargo_id] = entry.qty;
    });
  return cargo;
};

export const getLoadingDockForActiveShip = createSelector(
  [getShipId, getLoadingDocks],
  (shipId, loadingDocks) => {
    const cargo = {};
    loadingDocks
      .filter((entry) => entry.ship_id === shipId)
      .forEach((entry) => {
        cargo[entry.cargo_id] = entry.qty;
      });
    return cargo;
  }
);

const FETCH_REQUESTED = "maas-loader/loadingDock/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/loadingDock/FETCH_SUCCEEDED";
const UPDATE_RECEIVED = "maas-loader/loadingDock/UPDATE_RECEIVED";
const TRANSFER_TO_LOADING_DOCK_REQUESTED =
  "maas-loader/loadingDock/TRANSFER_TO_LOADING_DOCK_REQUESTED";
const TRANSFER_FROM_LOADING_DOCK_REQUESTED =
  "maas-loader/loadingDock/TRANSFER_FROM_LOADING_DOCK_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { loadingDocks } = action;
      return loadingDocks;
    }
    case UPDATE_RECEIVED: {
      const { loadingDock } = action;
      return { ...state, [loadingDock.id]: loadingDock };
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(loadingDocks) {
  return { type: FETCH_SUCCEEDED, loadingDocks };
}

export function updateReceived(loadingDock) {
  return { type: UPDATE_RECEIVED, loadingDock };
}

export function requestTransferToLoadingDock(shipId, cargoId, qty) {
  return { type: TRANSFER_TO_LOADING_DOCK_REQUESTED, shipId, cargoId, qty };
}

export function requestTransferFromLoadingDock(shipId, cargoId, qty) {
  return { type: TRANSFER_FROM_LOADING_DOCK_REQUESTED, shipId, cargoId, qty };
}

function* fetchLoadingDock() {
  try {
    const loadingDockResponse = yield call(() =>
      axios.get("/api/v1/loading_dock/")
    );
    const loadingDocks = {};
    Object.values(loadingDockResponse.data).forEach((loadingDock) => {
      loadingDocks[loadingDock.id] = loadingDock;
    });
    yield put(fetchSucceeded(loadingDocks));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchLoadingDock() {
  yield takeLatest(FETCH_REQUESTED, fetchLoadingDock);
}

function* transferToLoadingDock(action) {
  const { shipId, cargoId, qty } = action;
  try {
    yield call(
      () =>
        axios.put(
          `/api/v1/loading_dock/transfer_to_loading_dock/ship/${shipId}/cargo/${cargoId}/qty/${qty}`
        ),
      {}
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchTransferToLoadingDock() {
  yield takeEvery(TRANSFER_TO_LOADING_DOCK_REQUESTED, transferToLoadingDock);
}

function* transferFromLoadingDock(action) {
  const { shipId, cargoId, qty } = action;
  try {
    yield call(
      () =>
        axios.put(
          `/api/v1/loading_dock/transfer_from_loading_dock/ship/${shipId}/cargo/${cargoId}/qty/${qty}`
        ),
      {}
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchTransferFromLoadingDock() {
  yield takeEvery(
    TRANSFER_FROM_LOADING_DOCK_REQUESTED,
    transferFromLoadingDock
  );
}
