import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";

export const getCombatLogMessages = (state) =>
  Object.values(state.combatLogMessages);

const FETCH_REQUESTED = "maas-loader/combatLogMessage/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/combatLogMessage/FETCH_SUCCEEDED";
const UPDATE_RECEIVED = "maas-loader/combatLogMessage/UPDATE_RECEIVED";
const CREATE_MESSAGE_REQUESTED =
  "maas-loader/combatLogMessage/CREATE_MESSAGE_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { combatLogMessages } = action;
      return combatLogMessages;
    }
    case UPDATE_RECEIVED: {
      const { combatLogMessage } = action;
      return { ...state, [combatLogMessage.id]: combatLogMessage };
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(combatLogMessages) {
  return { type: FETCH_SUCCEEDED, combatLogMessages };
}

export function updateReceived(combatLogMessage) {
  return { type: UPDATE_RECEIVED, combatLogMessage };
}

export function requestCreateMessage(message) {
  return { type: CREATE_MESSAGE_REQUESTED, message };
}

function* fetchCombatLogMessages() {
  try {
    const combatLogMessagesResponse = yield call(() =>
      axios.get("/api/v1/combat_log_message/?limit=100")
    );
    const combatLogMessages = {};
    Object.values(combatLogMessagesResponse.data).forEach(
      (combatLogMessage) => {
        combatLogMessages[combatLogMessage.id] = combatLogMessage;
      }
    );
    yield put(fetchSucceeded(combatLogMessages));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchCombatLogMessages() {
  yield takeLatest(FETCH_REQUESTED, fetchCombatLogMessages);
}

function* createMessage(action) {
  const { message } = action;
  try {
    yield call(() => axios.post("/api/v1/combat_log_message/", { message }));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchCreateMessage() {
  yield takeEvery(CREATE_MESSAGE_REQUESTED, createMessage);
}
