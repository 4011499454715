import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
  getInWolfAttack,
  requestToggleInWolfAttack,
  getWolfAttackPhase,
  requestAdvanceWolfAttackPhase,
} from "store/ducks/gameTurn";
import { requestSpawnWolfUnit } from "store/ducks/wolfUnit";

function FacilitatorButtons() {
  const dispatch = useDispatch();

  const inWolfAttack = useSelector(getInWolfAttack, shallowEqual);
  const wolfAttackPhase = useSelector(getWolfAttackPhase, shallowEqual);

  const [wolfUnitSpawnQuadrant, setWolfUnitSpawnQuadrant] = useState("NW");

  return (
    <ButtonGroup vertical className="pt-2">
      <Button onClick={() => dispatch(requestToggleInWolfAttack())}>
        {inWolfAttack ? "End" : "Begin"} Wolf Attack
      </Button>
      <Button
        disabled={!inWolfAttack}
        onClick={() => dispatch(requestAdvanceWolfAttackPhase())}
      >
        Advance to {wolfAttackPhase === "Fleet" ? "Wolf" : "Fleet"} Phase
      </Button>
      <Dropdown as={ButtonGroup} drop="left">
        <Dropdown.Toggle style={{ whiteSpace: "normal" }}>
          Wolf Unit Spawn Quadrant: {wolfUnitSpawnQuadrant}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setWolfUnitSpawnQuadrant("NW");
            }}
            active={wolfUnitSpawnQuadrant === "NW"}
          >
            NW
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setWolfUnitSpawnQuadrant("NE");
            }}
            active={wolfUnitSpawnQuadrant === "NE"}
          >
            NE
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setWolfUnitSpawnQuadrant("SW");
            }}
            active={wolfUnitSpawnQuadrant === "SW"}
          >
            SW
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setWolfUnitSpawnQuadrant("SE");
            }}
            active={wolfUnitSpawnQuadrant === "SE"}
          >
            SE
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={ButtonGroup} drop="left">
        <Dropdown.Toggle style={{ whiteSpace: "normal" }}>
          Spawn Wolf Unit
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() =>
              dispatch(requestSpawnWolfUnit("fighter", wolfUnitSpawnQuadrant))
            }
          >
            Fighter Squadron
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              dispatch(requestSpawnWolfUnit("boarding", wolfUnitSpawnQuadrant))
            }
          >
            Boarding Squadron
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              dispatch(requestSpawnWolfUnit("destroyer", wolfUnitSpawnQuadrant))
            }
          >
            Destroyer
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              dispatch(requestSpawnWolfUnit("cruiser", wolfUnitSpawnQuadrant))
            }
          >
            Cruiser
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              dispatch(
                requestSpawnWolfUnit("battleship", wolfUnitSpawnQuadrant)
              )
            }
          >
            Battleship
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </ButtonGroup>
  );
}

export default FacilitatorButtons;
