import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Button from "react-bootstrap/Button";
import CrewDisplay from "views/components/CrewDisplay";
import { activeUserIsFacilitator } from "store/ducks/user";
import FacilitatorEditCrew from "./FacilitatorEditCrew";

function CrewListEntry({ crew }) {
  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);

  const [showFacilitatorEditModal, setShowFacilitatorEditModal] =
    useState(false);

  return (
    <>
      <CrewDisplay name={crew.name} role={crew.role} status={crew.status} />
      {isFacilitator && (
        <>
          <Button
            size="sm"
            className="ml-1"
            onClick={() => setShowFacilitatorEditModal(true)}
          >
            Edit
          </Button>
          <FacilitatorEditCrew
            crewId={crew.id}
            show={showFacilitatorEditModal}
            onHide={() => setShowFacilitatorEditModal(false)}
          />
        </>
      )}
    </>
  );
}

CrewListEntry.propTypes = {
  crew: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.oneOf([
      "Untrained",
      "Doctor",
      "Pilot",
      "Engineer",
      "Security",
      "Marine",
      "Scientist",
      "Convict",
      "Synth",
    ]).isRequired,
    status: PropTypes.oneOf([
      "Normal",
      "Tired",
      "Wounded",
      "Wounded-Tired",
      "Dead",
    ]).isRequired,
  }).isRequired,
};

export default CrewListEntry;
