import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import NumberSelector from "views/components/NumberSelector";
import {
  getPursuitTrackLevel,
  requestUpdatePursuitTrackLevel,
} from "store/ducks/gameTurn";
import { activeUserIsFacilitator } from "store/ducks/user";

function PursuitTrack() {
  const dispatch = useDispatch();

  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);
  const pursuitTrackLevel = useSelector(
    (state) => getPursuitTrackLevel(state) || 1,
    shallowEqual
  );

  const [selectedLevel, setSelectedLevel] = useState(pursuitTrackLevel);

  return (
    <Row className="justify-content-center">
      <Col className="d-flex flex-row justify-content-center align-items-center">
        <img
          src={require(`static/img/pursuitTrack/${pursuitTrackLevel}.png`)} // eslint-disable-line global-require, import/no-dynamic-require
          alt={`Pursuit Track Level ${pursuitTrackLevel}`}
          title={`Pursuit Track Level ${pursuitTrackLevel}`}
          style={{ maxHeight: "8vh" }}
        />
        {isFacilitator && (
          <Form inline>
            <InputGroup className="ml-2">
              <NumberSelector
                defaultValue={selectedLevel}
                start={1}
                end={12}
                onChange={setSelectedLevel}
              />
              <InputGroup.Append>
                <Button
                  onClick={() =>
                    dispatch(requestUpdatePursuitTrackLevel(selectedLevel))
                  }
                >
                  Save
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        )}
      </Col>
    </Row>
  );
}

export default PursuitTrack;
