import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { getSecurityCrewForShip } from "store/ducks/crew";
import CrewListEntry from "./CrewListEntry";

const CrewList = () => {
  const crew = useSelector(getSecurityCrewForShip, shallowEqual);

  return Object.values(crew).map((crewMember) => (
    <ListGroup.Item variant="secondary" key={crewMember.id.toString()}>
      <CrewListEntry crew={crewMember} />
    </ListGroup.Item>
  ));
};

function SecurityStation() {
  return (
    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
      <Card className="mb-4" text="white" bg="secondary" border="dark">
        <Card.Header>
          <h4 className="font-weight-normal my-0">Security Station</h4>
        </Card.Header>
        <Card.Body>For Security crew only.</Card.Body>
        <ListGroup variant="flush">
          <CrewList />
        </ListGroup>
      </Card>
    </div>
  );
}

export default SecurityStation;
