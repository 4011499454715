import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import InputGroup from "react-bootstrap/InputGroup";
import NumberSelector from "views/components/NumberSelector";
import {
  getShipById,
  requestAddBoarder,
  requestDamageShip,
  requestRemoveBoarder,
  requestRepelBoardingUnit,
  requestRollBoarderDamage,
} from "store/ducks/ship";
import { getWeaponBatteryStationCountForShip } from "store/ducks/station";
import { getSecurityCrewForShipById } from "store/ducks/crew";
import { getFightersForShip, requestMoveFighter } from "store/ducks/fighter";
import { activeUserIsFacilitator } from "store/ducks/user";
import { requestCreateMessage } from "store/ducks/combatLogMessage";

function ShipControl({ shipId }) {
  const dispatch = useDispatch();

  const ship = useSelector((state) => getShipById(state, shipId), shallowEqual);
  const fighters = useSelector(
    (state) => getFightersForShip(state, ship.id),
    shallowEqual
  );
  const weaponBatteryStationCount = useSelector(
    (state) => getWeaponBatteryStationCountForShip(state, shipId),
    shallowEqual
  );
  const securityCrew = useSelector(
    (state) => getSecurityCrewForShipById(state, shipId),
    shallowEqual
  );
  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);

  const [fighterLaunchQuadrant, setFighterLaunchQuadrant] = useState("NW");
  const [fireWeaponsRollModifier, setFireWeaponsRollModifier] = useState(0);
  const [repelBoardersRollModifier, setRepelBoardersRollModifier] = useState(0);

  const rolld6 = () => {
    return Math.floor(Math.random() * 6) + 1;
  };

  const fireWeaponBatteries = () => {
    const d6Roll = rolld6();
    const modifiedRoll = d6Roll + parseInt(fireWeaponsRollModifier, 10);
    let message = `${ship.battle_map_quadrant}: The ${ship.name} fires its Weapon Batteries and rolls a ${d6Roll} + ${fireWeaponsRollModifier} = ${modifiedRoll}. `;

    if (modifiedRoll <= 1) {
      message += "[b]It misses.[/b]";
    } else if (modifiedRoll <= 5) {
      message += "[b]It destroys one squadron unit.[/b]";
    } else {
      message += "[b]It destroys two squadron units.[/b]";
    }

    dispatch(requestCreateMessage(message));
  };

  return (
    <Row className="text-light">
      <Col>
        <div className="d-flex flex-row align-items-center">
          <span className="mr-4">{ship.name} Actions:</span>
          <ButtonToolbar>
            {fighters.length > 0 && (
              <ButtonGroup className="mr-2">
                <Dropdown as={ButtonGroup} drop="up">
                  <Dropdown.Toggle
                    disabled={
                      fighters.filter((fighter) => fighter.quadrant === null)
                        .length === 0
                    }
                  >
                    Launch Fighter - {ship.turn_active_fighters} Active Allowed
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {fighters
                      .filter((fighter) => fighter.quadrant === null)
                      .map((fighter) => {
                        return (
                          <Dropdown.Item
                            key={fighter.id}
                            onClick={() => {
                              dispatch(
                                requestMoveFighter(
                                  fighter.id,
                                  ship.battle_map_quadrant !== "Center"
                                    ? ship.battle_map_quadrant
                                    : fighterLaunchQuadrant
                                )
                              );
                            }}
                          >
                            {fighter.name} - {fighter.status}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
                {ship.battle_map_quadrant === "Center" && (
                  <Dropdown as={ButtonGroup} drop="up">
                    <Dropdown.Toggle>
                      Fighter Launch Quadrant: {fighterLaunchQuadrant}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        active={fighterLaunchQuadrant === "NW"}
                        onClick={() => setFighterLaunchQuadrant("NW")}
                      >
                        NW
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={fighterLaunchQuadrant === "NE"}
                        onClick={() => setFighterLaunchQuadrant("NE")}
                      >
                        NE
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={fighterLaunchQuadrant === "SW"}
                        onClick={() => setFighterLaunchQuadrant("SW")}
                      >
                        SW
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={fighterLaunchQuadrant === "SE"}
                        onClick={() => setFighterLaunchQuadrant("SE")}
                      >
                        SE
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </ButtonGroup>
            )}
            {weaponBatteryStationCount > 0 && (
              <InputGroup className="mr-2">
                <InputGroup.Prepend>
                  <Button onClick={() => fireWeaponBatteries()}>
                    Fire Weapons - {ship.turn_active_weapon_batteries} Shots
                    Allowed
                  </Button>
                </InputGroup.Prepend>
                <NumberSelector
                  defaultValue={0}
                  onChange={setFireWeaponsRollModifier}
                  start={-3}
                  end={3}
                  showPlus
                />
              </InputGroup>
            )}
            <InputGroup className="mr-2">
              <InputGroup.Prepend>
                <Dropdown as={ButtonGroup} drop="up">
                  <Dropdown.Toggle disabled={ship.wolf_boarding_units === 0}>
                    Repel Boarding Units - {ship.wolf_boarding_units} Current
                    Boarding Units
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {securityCrew.map((crew) => {
                      return (
                        <Dropdown.Item
                          key={crew.id}
                          onClick={() =>
                            dispatch(
                              requestRepelBoardingUnit(
                                shipId,
                                crew.id,
                                repelBoardersRollModifier
                              )
                            )
                          }
                        >
                          {crew.role === "Marine" ? "Marine (+2) " : ""}
                          {crew.name} - {crew.status}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup.Prepend>
              <NumberSelector
                defaultValue={0}
                onChange={setRepelBoardersRollModifier}
                start={-3}
                end={3}
                showPlus
              />
            </InputGroup>
            {isFacilitator && (
              <ButtonGroup className="mr-2">
                <Button onClick={() => dispatch(requestDamageShip(shipId))}>
                  Damage Ship
                </Button>
                <Button onClick={() => dispatch(requestAddBoarder(shipId))}>
                  Add Boarder
                </Button>
                <Dropdown as={ButtonGroup} drop="up">
                  <Dropdown.Toggle disabled={ship.wolf_boarding_units === 0}>
                    Remove Boarder
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        dispatch(requestRemoveBoarder(shipId, true))
                      }
                    >
                      And reduce morale roll penalty (i.e. boarder was added by
                      accident)
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        dispatch(requestRemoveBoarder(shipId, false))
                      }
                    >
                      And DO NOT reduce morale roll penalty (i.e. boarder was
                      added correctly)
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  onClick={() => dispatch(requestRollBoarderDamage(shipId))}
                >
                  Roll Boarder Damage
                </Button>
              </ButtonGroup>
            )}
          </ButtonToolbar>
        </div>
      </Col>
    </Row>
  );
}

ShipControl.propTypes = {
  shipId: PropTypes.number.isRequired,
};

export default ShipControl;
