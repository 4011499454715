import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { getEnabledShips } from "store/ducks/ship";

function TransportPrisoner({ effectId, updateEffectsData }) {
  const ships = useSelector((state) => getEnabledShips(state), shallowEqual);

  const [selectedShip, setSelectedShip] = useState(1);

  useEffect(() => {
    updateEffectsData(effectId, selectedShip);
  }, [updateEffectsData, effectId, selectedShip]);

  return (
    <Row>
      <Col>
        <h4>Transport Prisoner</h4>

        <Form>
          <Form.Group controlId="formTransportPrisonerSelect">
            <Form.Label>
              Select which ship to transport a prisoner to for this turn. If the
              prisoner operates a station, there is a 1 in 6 chance that they
              will cause a riot at that station at the end of the turn.
              Regardless of whether or not they operated a station, they will
              return to the Vulcan at the end of the turn.
            </Form.Label>
            <Form.Control
              as="select"
              custom
              onChange={(e) => setSelectedShip(parseInt(e.target.value, 10))}
              value={selectedShip}
            >
              {ships.map((ship) => {
                return (
                  <option value={ship.id} key={ship.id}>
                    {ship.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
}

TransportPrisoner.propTypes = {
  effectId: PropTypes.number.isRequired,
  updateEffectsData: PropTypes.func.isRequired,
};

export default TransportPrisoner;
