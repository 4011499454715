import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";

export const getTurnPhase = (state) =>
  state.gameTurn ? state.gameTurn.phase : null;
export const getTurnNumber = (state) =>
  state.gameTurn ? state.gameTurn.number : null;
export const getTimerPauseTime = (state) =>
  state.gameTurn ? state.gameTurn.timer_pause_time : null;
export const getTurnEndTime = (state) =>
  state.gameTurn ? state.gameTurn.turn_end_time : null;
export const getInWolfAttack = (state) =>
  state.gameTurn ? state.gameTurn.in_wolf_attack : null;
export const getWolfAttackPhase = (state) =>
  state.gameTurn ? state.gameTurn.wolf_attack_phase : null;
export const getPursuitTrackLevel = (state) =>
  state.gameTurn ? state.gameTurn.pursuit_track_level : null;

const FETCH_REQUESTED = "maas-loader/gameTurn/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/gameTurn/FETCH_SUCCEEDED";
const FETCH_FAILED = "maas-loader/gameTurn/FETCH_FAILED";
const UPDATE_RECEIVED = "maas-loader/gameTurn/UPDATE_RECEIVED";
const TIMER_PAUSE_REQUESTED = "maas-loader/gameTurn/TIMER_PAUSE_REQUESTED";
const TIMER_RESUME_REQUESTED = "maas-loader/gameTurn/TIMER_RESUME_REQUESTED";
const TIMER_REWIND_REQUESTED = "maas-loader/gameTurn/TIMER_REWIND_REQUESTED";
const PHASE_ADVANCE_REQUESTED = "maas-loader/gameTurn/PHASE_ADVANCE_REQUESTED";
const TOGGLE_IN_WOLF_ATTACK_REQUESTED =
  "maas-loader/gameTurn/TOGGLE_IN_WOLF_ATTACK_REQUESTED";
const UPDATE_PURSUIT_TRACK_LEVEL_REQUESTED =
  "maas-loader/gameTurn/UPDATE_PURSUIT_TRACK_LEVEL_REQUESTED";
const ADVANCE_WOLF_ATTACK_PHASE_REQUESTED =
  "maas-loader/gameTurn/ADVANCE_WOLF_ATTACK_PHASE_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { gameTurn } = action;
      return gameTurn;
    }
    case UPDATE_RECEIVED: {
      const { gameTurn } = action;
      return gameTurn;
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(gameTurn) {
  return { type: FETCH_SUCCEEDED, gameTurn };
}

export function fetchFailed(message) {
  return { type: FETCH_FAILED, message };
}

export function updateReceived(gameTurn) {
  return { type: UPDATE_RECEIVED, gameTurn };
}

export function requestTimerPause() {
  return { type: TIMER_PAUSE_REQUESTED };
}

export function requestTimerResume() {
  return { type: TIMER_RESUME_REQUESTED };
}

export function requestTimerRewind() {
  return { type: TIMER_REWIND_REQUESTED };
}

export function requestPhaseAdvance() {
  return { type: PHASE_ADVANCE_REQUESTED };
}

export function requestToggleInWolfAttack() {
  return { type: TOGGLE_IN_WOLF_ATTACK_REQUESTED };
}

export function requestUpdatePursuitTrackLevel(level) {
  return { type: UPDATE_PURSUIT_TRACK_LEVEL_REQUESTED, level };
}

export function requestAdvanceWolfAttackPhase() {
  return { type: ADVANCE_WOLF_ATTACK_PHASE_REQUESTED };
}

function* fetchGameTurn() {
  try {
    const gameTurnResponse = yield call(() => axios.get("/api/v1/game_turn/1"));
    yield put(fetchSucceeded(gameTurnResponse.data));
  } catch (e) {
    yield put(fetchFailed(e.message));
  }
}

export function* watchFetchGameTurn() {
  yield takeLatest(FETCH_REQUESTED, fetchGameTurn);
}

function* pauseTimer() {
  try {
    yield call(() => axios.put("/api/v1/game_turn/pause", {}));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchPauseTimer() {
  yield takeEvery(TIMER_PAUSE_REQUESTED, pauseTimer);
}

function* resumeTimer() {
  try {
    yield call(() => axios.put("/api/v1/game_turn/resume", {}));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchResumeTimer() {
  yield takeEvery(TIMER_RESUME_REQUESTED, resumeTimer);
}

function* rewindTimer() {
  try {
    yield call(() => axios.put("/api/v1/game_turn/rewind", {}));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchRewindTimer() {
  yield takeEvery(TIMER_REWIND_REQUESTED, rewindTimer);
}

function* advancePhase() {
  try {
    yield call(() => axios.put("/api/v1/game_turn/advance", {}));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchAdvancePhase() {
  yield takeEvery(PHASE_ADVANCE_REQUESTED, advancePhase);
}

function* toggleInWolfAttack() {
  try {
    yield call(() => axios.put("/api/v1/game_turn/toggle_in_wolf_attack", {}));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchToggleInWolfAttack() {
  yield takeEvery(TOGGLE_IN_WOLF_ATTACK_REQUESTED, toggleInWolfAttack);
}

function* updatePursuitTrackLevel(action) {
  const { level } = action;
  try {
    yield call(() =>
      axios.put("/api/v1/game_turn/1", {
        pursuit_track_level: level,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdatePursuitTrackLevel() {
  yield takeEvery(
    UPDATE_PURSUIT_TRACK_LEVEL_REQUESTED,
    updatePursuitTrackLevel
  );
}

function* advanceWolfAttackPhase() {
  try {
    yield call(() =>
      axios.put("/api/v1/game_turn/advance_wolf_attack_phase", {})
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchAdvanceWolfAttackPhase() {
  yield takeEvery(ADVANCE_WOLF_ATTACK_PHASE_REQUESTED, advanceWolfAttackPhase);
}
