import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { createSelector } from "reselect";
import { getShipId, setUpdateSucceeded, setUpdateFailed } from "./display";

export const getStations = (state) => Object.values(state.stations);

export const getStation = (state, stationId) =>
  state.stations[stationId] ? state.stations[stationId] : {};

export const getStationsForShip = (state, shipId) => {
  const stations = getStations(state);
  return stations.filter((station) => station.ship_id === shipId);
};

export const getStationsForActiveShip = createSelector(
  [getShipId, getStations],
  (shipId, stations) => {
    return stations.filter((station) => {
      return station.ship_id === shipId;
    });
  }
);

export const getJumpStationForActiveShip = createSelector(
  [getShipId, getStations],
  (shipId, stations) => {
    return stations.find((station) => {
      return station.ship_id === shipId && station.is_jump_station_for_ship;
    });
  }
);

export const getJumpStationForShipById = (state, shipId) => {
  const stations = getStations(state);
  return stations.find((station) => {
    return station.ship_id === shipId && station.is_jump_station_for_ship;
  });
};

export const getFuelStationForActiveShip = createSelector(
  [getShipId, getStations],
  (shipId, stations) => {
    return stations.find((station) => {
      return (
        station.ship_id === shipId && station.is_jump_fuel_station_for_ship
      );
    });
  }
);

export const getFuelStationForShipById = (state, shipId) => {
  const stations = getStations(state);
  return stations.find((station) => {
    return station.ship_id === shipId && station.is_jump_fuel_station_for_ship;
  });
};

export const getInitialStationForShip = (state, shipId) => {
  const stations = getStations(state);
  return stations.find((station) => {
    return station.ship_id === shipId && station.is_initial_station_for_ship;
  });
};

export const getPercentUndamagedStationsForShip = (state, shipId) => {
  const stations = getStationsForShip(state, shipId);
  const totalStations = stations.length;
  const undamagedStations = stations.filter(
    (station) => station.status !== "damaged"
  ).length;
  return (undamagedStations / totalStations) * 100;
};

export const getWeaponBatteryStationCountForShip = (state, shipId) => {
  const stations = getStations(state);
  return stations.filter((station) => {
    return (
      station.ship_id === shipId &&
      station.special_transaction_name === "special-weaponbatteries"
    );
  }).length;
};

const FETCH_REQUESTED = "maas-loader/station/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/station/FETCH_SUCCEEDED";
const FETCH_FAILED = "maas-loader/station/FETCH_FAILED";
const UPDATE_RECEIVED = "maas-loader/station/UPDATE_RECEIVED";
const REPAIR_REQUESTED = "maas-loader/station/REPAIR_REQUESTED";
const REPAIR_SUCCEEDED = "maas-loader/station/REPAIR_SUCCEEDED";
const REPAIR_FAILED = "maas-loader/station/REPAIR_FAILED";
const SUPPRESS_RIOTERS_REQUESTED =
  "maas-loader/station/SUPPRESS_RIOTERS_REQUESTED";
const SUPPRESS_RIOTERS_SUCCEEDED =
  "maas-loader/station/SUPPRESS_RIOTERS_SUCCEEDED";
const SUPPRESS_RIOTERS_FAILED = "maas-loader/station/SUPPRESS_RIOTERS_FAILED";
const UPDATE_STATION_VALUES_REQUESTED =
  "maas-loader/station/UPDATE_STATION_VALUES_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { stations } = action;
      return stations;
    }
    case UPDATE_RECEIVED: {
      const { station } = action;
      return { ...state, [station.id]: station };
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(stations) {
  return { type: FETCH_SUCCEEDED, stations };
}

export function fetchFailed(message) {
  return { type: FETCH_FAILED, message };
}

export function updateReceived(station) {
  return { type: UPDATE_RECEIVED, station };
}

export function requestRepair(stationId) {
  return { type: REPAIR_REQUESTED, stationId };
}

export function repairSucceeded(station) {
  return { type: REPAIR_SUCCEEDED, station };
}

export function repairFailed(message) {
  return { type: REPAIR_FAILED, message };
}

export function requestSuppressRioters(stationId, crewId) {
  return { type: SUPPRESS_RIOTERS_REQUESTED, stationId, crewId };
}

export function suppressRiotersSucceeded(message) {
  return { type: SUPPRESS_RIOTERS_SUCCEEDED, message };
}

export function suppressRiotersFailed(message) {
  return { type: SUPPRESS_RIOTERS_FAILED, message };
}

export function requestUpdateStationValues(stationId, values) {
  return { type: UPDATE_STATION_VALUES_REQUESTED, stationId, values };
}

function* fetchStations() {
  try {
    const stationResponse = yield call(() =>
      axios.get("/api/v1/station/?limit=1000")
    );
    const stations = {};
    Object.values(stationResponse.data).forEach((station) => {
      stations[station.id] = station;
    });
    yield put(fetchSucceeded(stations));
  } catch (e) {
    yield put(fetchFailed(e.message));
  }
}

export function* watchFetchStations() {
  yield takeLatest(FETCH_REQUESTED, fetchStations);
}

function* repairStation(action) {
  const { stationId } = action;
  try {
    const repairStationResponse = yield call(() =>
      axios.put(`/api/v1/station/${stationId}/repair`, {})
    );
    yield put(repairSucceeded(repairStationResponse.data));
  } catch (e) {
    if (e.response && e.response.data && e.response.data.detail) {
      // TODO: Store this message and present it to the user in some useful way
      yield put(repairFailed(e.response.data.detail));
    } else {
      yield put(repairFailed(e.message));
    }
  }
}

export function* watchRepairStation() {
  yield takeEvery(REPAIR_REQUESTED, repairStation);
}

function* suppressRioters(action) {
  const { stationId, crewId } = action;
  try {
    const suppressRiotersResponse = yield call(() =>
      axios.put(`/api/v1/station/${stationId}/suppress_riot`, {
        crew_id: crewId,
      })
    );
    yield put(
      suppressRiotersSucceeded(suppressRiotersResponse.data.result_text)
    );
    yield put(setUpdateSucceeded(suppressRiotersResponse.data.result_text));
  } catch (e) {
    yield put(suppressRiotersFailed(e.message));
    yield put(setUpdateFailed(e.message));
  }
}

export function* watchSuppressRioters() {
  yield takeEvery(SUPPRESS_RIOTERS_REQUESTED, suppressRioters);
}

function* updateStationValues(action) {
  const { stationId, values } = action;
  try {
    yield call(() => axios.put(`/api/v1/station/${stationId}`, values));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateStationValues() {
  yield takeEvery(UPDATE_STATION_VALUES_REQUESTED, updateStationValues);
}
