import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";

const getTransactionRequirements = (state) =>
  Object.values(state.transactionRequirements);

export const getTransactionRequirement = (state, transactionRequirementId) =>
  state.transactionRequirements
    ? state.transactionRequirements[transactionRequirementId]
    : {};

export const getTransactionRequirementsForTransaction = (
  state,
  transactionId
) => {
  const allTransactionRequirements = getTransactionRequirements(state);
  return allTransactionRequirements.filter((transactionRequirement) => {
    return transactionRequirement.transaction_id === transactionId;
  });
};

const FETCH_REQUESTED = "maas-loader/transactionRequirement/FETCH_REQUESTED";
export const FETCH_SUCCEEDED =
  "maas-loader/transactionRequirement/FETCH_SUCCEEDED";
const FETCH_FAILED = "maas-loader/transactionRequirement/FETCH_FAILED";
const UPDATE_RECEIVED = "maas-loader/transactionRequirement/UPDATE_RECEIVED";
const DELETE_RECEIVED = "maas-loader/transactionRequirement/DELETE_RECEIVED";
const UPDATE_TRANSACTION_REQUIREMENT_VALUES_REQUESTED =
  "maas-loader/transactionRequirement/UPDATE_TRANSACTION_REQUIREMENT_VALUES_REQUESTED";
const DELETE_TRANSACTION_REQUIREMENT_REQUESTED =
  "maas-loader/transactionRequirement/DELETE_TRANSACTION_REQUIREMENT_REQUESTED";
const CREATE_TRANSACTION_REQUIREMENT_REQUESTED =
  "maas-loader/transactionRequirement/CREATE_TRANSACTION_REQUIREMENT_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { transactionRequirements } = action;
      return transactionRequirements;
    }
    case UPDATE_RECEIVED: {
      const { transactionRequirement } = action;
      return { ...state, [transactionRequirement.id]: transactionRequirement };
    }
    case DELETE_RECEIVED: {
      const { transactionRequirement } = action;
      const { [transactionRequirement.id]: _, ...rest } = state;
      return rest;
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(transactionRequirements) {
  return { type: FETCH_SUCCEEDED, transactionRequirements };
}

export function fetchFailed(message) {
  return { type: FETCH_FAILED, message };
}

export function updateReceived(transactionRequirement) {
  return { type: UPDATE_RECEIVED, transactionRequirement };
}

export function deleteReceived(transactionRequirement) {
  return { type: DELETE_RECEIVED, transactionRequirement };
}

export function requestUpdateTransactionRequirementValues(
  transactionRequirementId,
  values
) {
  return {
    type: UPDATE_TRANSACTION_REQUIREMENT_VALUES_REQUESTED,
    transactionRequirementId,
    values,
  };
}

export function requestDeleteTransactionRequirement(transactionRequirementId) {
  return {
    type: DELETE_TRANSACTION_REQUIREMENT_REQUESTED,
    transactionRequirementId,
  };
}

export function requestCreateTransactionRequirement(values) {
  return {
    type: CREATE_TRANSACTION_REQUIREMENT_REQUESTED,
    values,
  };
}

function* fetchTransactionRequirements() {
  try {
    const transactionRequirementResponse = yield call(() =>
      axios.get("/api/v1/transaction_requirement/")
    );
    const transactionRequirements = {};
    Object.values(transactionRequirementResponse.data).forEach(
      (transactionRequirement) => {
        transactionRequirements[transactionRequirement.id] =
          transactionRequirement;
      }
    );
    yield put(fetchSucceeded(transactionRequirements));
  } catch (e) {
    yield put(fetchFailed(e.message));
  }
}

export function* watchFetchTransactionRequirements() {
  yield takeLatest(FETCH_REQUESTED, fetchTransactionRequirements);
}

function* updateTransactionRequirementValues(action) {
  const { transactionRequirementId, values } = action;
  try {
    yield call(() =>
      axios.put(
        `/api/v1/transaction_requirement/${transactionRequirementId}`,
        values
      )
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateTransactionRequirementValues() {
  yield takeEvery(
    UPDATE_TRANSACTION_REQUIREMENT_VALUES_REQUESTED,
    updateTransactionRequirementValues
  );
}

function* deleteTransactionRequirement(action) {
  const { transactionRequirementId } = action;
  try {
    yield call(() =>
      axios.delete(
        `/api/v1/transaction_requirement/${transactionRequirementId}`
      )
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchDeleteTransactionRequirement() {
  yield takeEvery(
    DELETE_TRANSACTION_REQUIREMENT_REQUESTED,
    deleteTransactionRequirement
  );
}

function* createTransactionRequirement(action) {
  const { values } = action;
  try {
    yield call(() => axios.post(`/api/v1/transaction_requirement/`, values));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchCreateTransactionRequirement() {
  yield takeEvery(
    CREATE_TRANSACTION_REQUIREMENT_REQUESTED,
    createTransactionRequirement
  );
}
