const SET_SHIP_ID = "maas-loader/display/SET_SHIP_ID";
const SET_UPDATE_SUCCEEDED = "maas-loader/display/SET_UPDATE_SUCCEEDED";
const SET_UPDATE_FAILED = "maas-loader/display/SET_UPDATE_FAILED";
const CLEAR_UPDATE_STATUS = "maas-loader/display/CLEAR_UPDATE_STATUS";
const SET_DISCORD_NAME = "maas-loader/display/SET_DISCORD_NAME";
const SET_SHUTTLE_ID = "maas-loader/display/SET_SHUTTLE_ID";
const SET_SELECTED_ITEM = "maas-loader/display/SET_SELECTED_ITEM";
const SET_LOADING_DATA = "maas-loader/display/SET_LOADING_DATA";

export const getShipId = (state) => state.display.shipId;

export const getUpdateSucceeded = (state) => state.display.updateSucceeded;

export const getSuccessMessage = (state) => state.display.successMessage;

export const getErrorMessage = (state) => state.display.errorMessage;

export const getDiscordName = (state) => state.display.discordName;

export const getShuttleId = (state) => state.display.shuttleId;

export const getSelectedItem = (state) => state.display.selectedItem;

export const getLoadingData = (state) => state.display.loading;

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case SET_SHIP_ID: {
      const { shipId } = action;
      return { ...state, shipId };
    }
    case SET_UPDATE_SUCCEEDED: {
      const { successMessage } = action;
      return { ...state, updateSucceeded: true, successMessage };
    }
    case SET_UPDATE_FAILED: {
      const { errorMessage } = action;
      return { ...state, updateSucceeded: false, errorMessage };
    }
    case CLEAR_UPDATE_STATUS: {
      const { updateSucceeded, successMessage, errorMessage, ...restOfState } =
        state;
      return restOfState;
    }
    case SET_DISCORD_NAME: {
      const { discordName } = action;
      return { ...state, discordName };
    }
    case SET_SHUTTLE_ID: {
      const { shuttleId } = action;
      return { ...state, shuttleId };
    }
    case SET_SELECTED_ITEM: {
      const { itemType, itemId } = action;
      return { ...state, selectedItem: { itemType, itemId } };
    }
    case SET_LOADING_DATA: {
      const { loading } = action;
      return { ...state, loading };
    }
    default:
      return state;
  }
}

export function setShipId(shipId) {
  return { type: SET_SHIP_ID, shipId: parseInt(shipId, 10) };
}

export function setUpdateSucceeded(successMessage) {
  return { type: SET_UPDATE_SUCCEEDED, successMessage };
}

export function setUpdateFailed(errorMessage) {
  return { type: SET_UPDATE_FAILED, errorMessage };
}

export function clearUpdateStatus() {
  return { type: CLEAR_UPDATE_STATUS };
}

export function setDiscordName(discordName) {
  return { type: SET_DISCORD_NAME, discordName };
}

export function setShuttleId(shuttleId) {
  return { type: SET_SHUTTLE_ID, shuttleId: parseInt(shuttleId, 10) };
}

export function setSelectedItem(itemType, itemId) {
  return { type: SET_SELECTED_ITEM, itemType, itemId };
}

export function setLoadingData(loading) {
  return { type: SET_LOADING_DATA, loading };
}
