import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  getTransaction,
  requestCreateTransaction,
} from "store/ducks/transaction";
import { getTransactionRequirementsForTransaction } from "store/ducks/transactionRequirement";
import { getTransactionEffectsForTransaction } from "store/ducks/transactionEffect";
import { requestUpdateStationValues } from "store/ducks/station";
import TransactionEffectEditor from "./TransactionEffectEditor";
import TransactionRequirementEditor from "./TransactionRequirementEditor";

function TransactionEditor({ transactionId, shipId, stationId }) {
  const dispatch = useDispatch();

  const transaction = useSelector(
    (state) => getTransaction(state, transactionId),
    shallowEqual
  );

  const transactionRequirements = useSelector(
    (state) => getTransactionRequirementsForTransaction(state, transactionId),
    shallowEqual
  );
  const transactionEffects = useSelector(
    (state) => getTransactionEffectsForTransaction(state, transactionId),
    shallowEqual
  );

  const [showAddForm, setShowAddForm] = useState(false);
  const [name, setName] = useState("");

  if (transactionId) {
    return (
      <>
        <Row>
          <Col>Transaction</Col>
        </Row>
        <Row>
          <Col>{transaction.identifier}</Col>
          <Col>
            <ButtonGroup>
              <Button
                variant="danger"
                onClick={() =>
                  dispatch(
                    requestUpdateStationValues(stationId, {
                      transaction_id: null,
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col>Transaction Requirements</Col>
        </Row>
        {transactionRequirements.map((req) => (
          <TransactionRequirementEditor
            key={req.id}
            transactionRequirementId={req.id}
            shipId={shipId}
            stationId={stationId}
            transactionId={transactionId}
          />
        ))}
        <TransactionRequirementEditor
          shipId={shipId}
          stationId={stationId}
          transactionId={transactionId}
        />
        <Row>
          <Col>Transaction Effects</Col>
        </Row>
        {transactionEffects.map((eff) => (
          <TransactionEffectEditor
            key={eff.id}
            transactionEffectId={eff.id}
            shipId={shipId}
            stationId={stationId}
            transactionId={transactionId}
          />
        ))}
        <TransactionEffectEditor
          shipId={shipId}
          stationId={stationId}
          transactionId={transactionId}
        />
      </>
    );
  }
  return (
    <>
      <Row>
        <Col>Transaction</Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={() => setShowAddForm(!showAddForm)}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <Row className={showAddForm ? "" : "d-none"}>
        <Col>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Button
            onClick={() => {
              dispatch(
                requestCreateTransaction(stationId, { identifier: name })
              );
              setName("");
              setShowAddForm(false);
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
}

TransactionEditor.propTypes = {
  transactionId: PropTypes.number,
  shipId: PropTypes.number.isRequired,
  stationId: PropTypes.number.isRequired,
};

TransactionEditor.defaultProps = {
  transactionId: null,
};

export default TransactionEditor;
