import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getCrewById, requestUpdateCrewValues } from "store/ducks/crew";
import { getShips } from "store/ducks/ship";
import { getStations } from "store/ducks/station";
import { getShuttles } from "store/ducks/shuttle";

function FacilitatorEditCrew({ show, onHide, crewId }) {
  const dispatch = useDispatch();

  const crew = useSelector((state) => getCrewById(state, crewId), shallowEqual);
  const ships = useSelector(getShips, shallowEqual);
  const stations = useSelector(getStations, shallowEqual);
  const shuttles = useSelector(getShuttles, shallowEqual);

  const getInitialStationIdForShip = (shipId) => {
    return stations.find(
      (station) =>
        station.ship_id === shipId && station.is_initial_station_for_ship
    ).id;
  };

  const getStationsForShip = (shipId) => {
    return stations.filter((station) => station.ship_id === shipId);
  };

  const [name, setName] = useState(crew.name);

  useEffect(() => {
    setName(crew.name);
  }, [crew.name]);

  const [role, setRole] = useState(crew.role);

  useEffect(() => {
    setRole(crew.role);
  }, [crew.role]);

  const [status, setStatus] = useState(crew.status);

  useEffect(() => {
    setStatus(crew.status);
  }, [crew.status]);

  const [shipId, setShipId] = useState(crew.ship_id);

  useEffect(() => {
    setShipId(crew.ship_id);
  }, [crew.ship_id]);

  const [stationId, setStationId] = useState(crew.station_id);

  useEffect(() => {
    setStationId(crew.station_id);
  }, [crew.station_id]);

  const [shuttleId, setShuttleId] = useState(crew.shuttle_id);

  useEffect(() => {
    setShuttleId(crew.shuttle_id);
  }, [crew.shuttle_id]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="editCrewModalTitle"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="editCrewModalTitle">Editing {crew.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Button
              onClick={() =>
                dispatch(requestUpdateCrewValues(crewId, { name }))
              }
            >
              Save
            </Button>
          </Form.Group>
          <Form.Group>
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="Untrained">Untrained</option>
              <option value="Doctor">Doctor</option>
              <option value="Pilot">Pilot</option>
              <option value="Engineer">Engineer</option>
              <option value="Security">Security</option>
              <option value="Marine">Marine</option>
              <option value="Scientist">Scientist</option>
              <option value="Convict">Convict</option>
              <option value="Synth">Synth</option>
            </Form.Control>
            <Button
              onClick={() => {
                if (
                  ["Security", "Marine"].includes(role) &&
                  !["Security", "Marine"].includes(crew.role)
                ) {
                  dispatch(
                    requestUpdateCrewValues(crewId, { role, station_id: null })
                  );
                } else if (
                  !["Security", "Marine"].includes(role) &&
                  ["Security", "Marine"].includes(crew.role)
                ) {
                  dispatch(
                    requestUpdateCrewValues(crewId, {
                      role,
                      station_id: getInitialStationIdForShip(crew.ship_id),
                    })
                  );
                } else {
                  dispatch(requestUpdateCrewValues(crewId, { role }));
                }
              }}
            >
              Save
            </Button>
          </Form.Group>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Normal">Normal</option>
              <option value="Tired">Tired</option>
              <option value="Wounded">Wounded</option>
              <option value="Wounded-Tired">Wounded-Tired</option>
              <option value="Dead">Dead</option>
            </Form.Control>
            <Button
              onClick={() => {
                dispatch(requestUpdateCrewValues(crewId, { status }));
              }}
            >
              Save
            </Button>
          </Form.Group>
          <Form.Group>
            <Form.Label>Ship</Form.Label>
            <Form.Control
              as="select"
              value={shipId || "null"}
              onChange={(e) => setShipId(e.target.value)}
            >
              {ships.map((ship) => {
                return (
                  <option key={ship.id} value={ship.id}>
                    {ship.name}
                  </option>
                );
              })}
              <option value="null">None</option>
            </Form.Control>
            <Button
              disabled={!shipId || shipId === "null"}
              onClick={() => {
                if (["Security", "Marine"].includes(crew.role)) {
                  dispatch(
                    requestUpdateCrewValues(crewId, {
                      ship_id: shipId,
                      station_id: null,
                      shuttle_id: null,
                    })
                  );
                } else {
                  dispatch(
                    requestUpdateCrewValues(crewId, {
                      ship_id: shipId,
                      station_id: getInitialStationIdForShip(
                        parseInt(shipId, 10)
                      ),
                      shuttle_id: null,
                    })
                  );
                }
              }}
            >
              Save
            </Button>
          </Form.Group>
          {!["Security", "Marine"].includes(crew.role) && !crew.shuttle_id && (
            <Form.Group>
              <Form.Label>Station</Form.Label>
              <Form.Control
                as="select"
                value={stationId}
                onChange={(e) => setStationId(e.target.value)}
              >
                {getStationsForShip(crew.ship_id).map((station) => {
                  return (
                    <option key={station.id} value={station.id}>
                      {station.name}
                    </option>
                  );
                })}
              </Form.Control>
              <Button
                onClick={() =>
                  dispatch(
                    requestUpdateCrewValues(crewId, {
                      station_id: stationId,
                    })
                  )
                }
              >
                Save
              </Button>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Shuttle</Form.Label>
            <Form.Control
              as="select"
              value={shuttleId || "null"}
              onChange={(e) => setShuttleId(e.target.value)}
            >
              {shuttles.map((shuttle) => {
                return (
                  <option key={shuttle.id} value={shuttle.id}>
                    {shuttle.name}
                  </option>
                );
              })}
              <option value="null">None</option>
            </Form.Control>
            <Button
              disabled={!shuttleId || shuttleId === "null"}
              onClick={() => {
                dispatch(
                  requestUpdateCrewValues(crewId, {
                    ship_id: null,
                    station_id: null,
                    shuttle_id: shuttleId,
                  })
                );
              }}
            >
              Save
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

FacilitatorEditCrew.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  crewId: PropTypes.number.isRequired,
};

export default FacilitatorEditCrew;
