import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { setUpdateSucceeded, setUpdateFailed } from "./display";
import { requestUpdateStationValues } from "./station";

export const getTransaction = (state, transactionId) =>
  state.transactions ? state.transactions[transactionId] : {};

const FETCH_REQUESTED = "maas-loader/transaction/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/transaction/FETCH_SUCCEEDED";
const FETCH_FAILED = "maas-loader/transaction/FETCH_FAILED";
const COMMIT_TRANSACTION_REQUESTED =
  "maas-loader/transaction/COMMIT_TRANSACTION_REQUESTED";
const COMMIT_TRANSACTION_SUCCEEDED =
  "maas-loader/transaction/COMMIT_TRANSACTION_SUCCEEDED";
const COMMIT_TRANSACTION_FAILED =
  "maas-loader/transaction/COMMIT_TRANSACTION_FAILED";
const UPDATE_RECEIVED = "maas-loader/transaction/UPDATE_RECEIVED";
const DELETE_RECEIVED = "maas-loader/transaction/DELETE_RECEIVED";
const CREATE_TRANSACTION_REQUESTED =
  "maas-loader/transaction/CREATE_TRANSACTION_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { transactions } = action;
      return transactions;
    }
    case UPDATE_RECEIVED: {
      const { transaction } = action;
      return { ...state, [transaction.id]: transaction };
    }
    case DELETE_RECEIVED: {
      const { transaction } = action;
      const { [transaction.id]: _, ...rest } = state;
      return rest;
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(transactions) {
  return { type: FETCH_SUCCEEDED, transactions };
}

export function fetchFailed(message) {
  return { type: FETCH_FAILED, message };
}

export function updateReceived(transaction) {
  return { type: UPDATE_RECEIVED, transaction };
}

export function deleteReceived(transaction) {
  return { type: DELETE_RECEIVED, transaction };
}

export function requestCommitTransaction(
  transactionId,
  requestData,
  effectData
) {
  return {
    type: COMMIT_TRANSACTION_REQUESTED,
    transactionId,
    requestData,
    effectData,
  };
}

export function commitTransactionSucceeded() {
  return { type: COMMIT_TRANSACTION_SUCCEEDED };
}

export function commitTransactionFailed(message) {
  return { type: COMMIT_TRANSACTION_FAILED, message };
}

export function requestCreateTransaction(stationId, values) {
  return {
    type: CREATE_TRANSACTION_REQUESTED,
    stationId,
    values,
  };
}

function* fetchTransactions() {
  try {
    const transactionResponse = yield call(() =>
      axios.get("/api/v1/transaction/")
    );
    const transactions = {};
    Object.values(transactionResponse.data).forEach((transaction) => {
      transactions[transaction.id] = transaction;
    });
    yield put(fetchSucceeded(transactions));
  } catch (e) {
    yield put(fetchFailed(e.message));
  }
}

export function* watchFetchTransactions() {
  yield takeLatest(FETCH_REQUESTED, fetchTransactions);
}

function* commitTransaction(action) {
  const { transactionId, requestData, effectData } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/transaction/${transactionId}/commit`, {
        requirement_data: requestData,
        effect_data: effectData,
      })
    );
    yield put(commitTransactionSucceeded());
    yield put(setUpdateSucceeded());
  } catch (e) {
    yield put(commitTransactionFailed(e.message));
    yield put(setUpdateFailed(e.message));
  }
}

export function* watchCommitTransaction() {
  yield takeEvery(COMMIT_TRANSACTION_REQUESTED, commitTransaction);
}

function* createTransaction(action) {
  const { stationId, values } = action;
  try {
    const transactionResponse = yield call(() =>
      axios.post(`/api/v1/transaction/`, values)
    );
    yield put(
      requestUpdateStationValues(stationId, {
        transaction_id: transactionResponse.data.id,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchCreateTransaction() {
  yield takeEvery(CREATE_TRANSACTION_REQUESTED, createTransaction);
}
