import io from "socket.io-client";

const socket = io();

function updateRecord(callback) {
  socket.on("UPDATE", callback);
}

function createRecord(callback) {
  socket.on("CREATE", callback);
}

function deleteRecord(callback) {
  socket.on("DELETE", callback);
}

function resetStore(callback) {
  socket.on("RESET", callback);
}

function deauth(callback) {
  socket.on("DEAUTH", callback);
}

export default { updateRecord, createRecord, deleteRecord, resetStore, deauth };
