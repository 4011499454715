import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import FormattedText from "views/components/FormattedText";
import NumberSelector from "views/components/NumberSelector";
import {
  getActiveShip,
  requestUpdateRationSetting,
  requestUpdateNewsMoraleModifier,
  requestUpdateMiscMoraleModifier,
} from "store/ducks/ship";
import {
  getFoodCostsForShipInOrder,
  getWaterCostsForShipInOrder,
} from "store/ducks/cost";
import { activeUserIsFacilitator } from "store/ducks/user";

function Rations() {
  const dispatch = useDispatch();

  const ship = useSelector(getActiveShip, shallowEqual);
  const foodCosts = useSelector(getFoodCostsForShipInOrder, shallowEqual);
  const waterCosts = useSelector(getWaterCostsForShipInOrder, shallowEqual);
  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);

  const [newsMoraleModifier, setNewsMoraleModifier] = useState(
    ship.turn_news_modifier
  );

  useEffect(() => {
    setNewsMoraleModifier(ship.turn_news_modifier);
  }, [ship.turn_news_modifier]);

  const [miscMoraleModifier, setMiscMoraleModifier] = useState(
    ship.turn_misc_modifier
  );

  useEffect(() => {
    setMiscMoraleModifier(ship.turn_misc_modifier);
  }, [ship.turn_misc_modifier]);

  if (
    !ship ||
    !foodCosts ||
    foodCosts.length === 0 ||
    !waterCosts ||
    waterCosts.length === 0
  ) {
    return <div />;
  }

  const moraleRollImpact = {
    none: "-6",
    critical: "-4",
    minimum: "-2",
    low: "-1",
    normal: "+0",
    increased: "+2",
    luxurious: "+4",
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const totalRollModifier = (() => {
    let rollMod = 0;
    rollMod +=
      parseInt(moraleRollImpact[ship.food_setting], 10) +
      parseInt(moraleRollImpact[ship.water_setting], 10);
    if (ship.id === 1) {
      rollMod -= Math.floor(ship.turn_damage_taken / 3);
    } else {
      rollMod -= ship.turn_damage_taken;
    }
    rollMod -= ship.turn_wolf_boarders;
    rollMod += ship.turn_news_modifier;
    rollMod += ship.turn_misc_modifier;
    return rollMod;
  })();

  return (
    <section className="mb-0 py-4 mx-2">
      <Container fluid>
        <Row>
          <Col className="text-center">
            <h2 className="text-uppercase">Morale Track</h2>
            <Row>
              <Col>Spawn riot on 1,2,3</Col>
              <Col>Spawn riot on 1,2</Col>
              <Col>Spawn riot on 1,2</Col>
              <Col>Spawn riot on 1</Col>
              <Col>Spawn riot on 1</Col>
              <Col>No chance of riot</Col>
              <Col>No chance of riot</Col>
              <Col>No chance of riot</Col>
              <Col>No chance of riot</Col>
              <Col>No chance of riot</Col>
            </Row>
            <Row className="text-white">
              <Col className="bg-danger">
                <h4>1</h4>
              </Col>
              <Col className="bg-warning">
                <h4>2</h4>
              </Col>
              <Col className="bg-warning">
                <h4>3</h4>
              </Col>
              <Col className="bg-secondary">
                <h4>4</h4>
              </Col>
              <Col className="bg-secondary">
                <h4>5</h4>
              </Col>
              <Col className="bg-success">
                <h4>6</h4>
              </Col>
              <Col className="bg-success">
                <h4>7</h4>
              </Col>
              <Col className="bg-success">
                <h4>8</h4>
              </Col>
              <Col className="bg-success">
                <h4>9</h4>
              </Col>
              <Col className="bg-success">
                <h4>10</h4>
              </Col>
            </Row>
            <Row>
              {[...Array(10).keys()].map((value) => {
                if (value + 1 === ship.morale) {
                  return (
                    <Col key={value + 1} className="bg-info text-white">
                      <h4>
                        <FontAwesomeIcon icon={faArrowUp} />
                        <br />
                        Current Morale
                      </h4>
                    </Col>
                  );
                }
                return <Col key={value + 1} />;
              })}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center text-uppercase">Food Rations</h2>
            <Table>
              <tbody>
                <tr>
                  <th>Setting</th>
                  {foodCosts.map((cost) => {
                    return (
                      <td
                        key={cost.id}
                        className={
                          cost.setting === ship.food_setting
                            ? "bg-light"
                            : "bg-white"
                        }
                      >
                        {capitalizeFirstLetter(cost.setting)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Cost</th>
                  {foodCosts.map((cost) => {
                    return (
                      <td
                        key={cost.id}
                        className={
                          cost.setting === ship.food_setting
                            ? "bg-light"
                            : "bg-white"
                        }
                      >
                        {cost.cost}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Morale Roll Bonus</th>
                  {foodCosts.map((cost) => {
                    return (
                      <td
                        key={cost.id}
                        className={
                          cost.setting === ship.food_setting
                            ? "bg-light"
                            : "bg-white"
                        }
                      >
                        {moraleRollImpact[cost.setting]}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Select</th>
                  {foodCosts.map((cost) => {
                    return (
                      <td
                        key={cost.id}
                        className={
                          cost.setting === ship.food_setting
                            ? "bg-light"
                            : "bg-white"
                        }
                      >
                        <Button
                          variant={
                            ship.food_setting === cost.setting
                              ? "success"
                              : "secondary"
                          }
                          onClick={() => {
                            if (ship.food_setting !== cost.setting) {
                              dispatch(
                                requestUpdateRationSetting(
                                  ship.id,
                                  "food",
                                  cost.setting
                                )
                              );
                            }
                          }}
                        >
                          {capitalizeFirstLetter(cost.setting)}
                        </Button>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2 className="text-center text-uppercase">Water Rations</h2>
            <Table>
              <tbody>
                <tr>
                  <th>Setting</th>
                  {waterCosts.map((cost) => {
                    return (
                      <td
                        key={cost.id}
                        className={
                          cost.setting === ship.water_setting
                            ? "bg-light"
                            : "bg-white"
                        }
                      >
                        {capitalizeFirstLetter(cost.setting)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Cost</th>
                  {waterCosts.map((cost) => {
                    return (
                      <td
                        key={cost.id}
                        className={
                          cost.setting === ship.water_setting
                            ? "bg-light"
                            : "bg-white"
                        }
                      >
                        {cost.cost}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Morale Roll Bonus</th>
                  {waterCosts.map((cost) => {
                    return (
                      <td
                        key={cost.id}
                        className={
                          cost.setting === ship.water_setting
                            ? "bg-light"
                            : "bg-white"
                        }
                      >
                        {moraleRollImpact[cost.setting]}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Select</th>
                  {waterCosts.map((cost) => {
                    return (
                      <td
                        key={cost.id}
                        className={
                          cost.setting === ship.water_setting
                            ? "bg-light"
                            : "bg-white"
                        }
                      >
                        <Button
                          variant={
                            ship.water_setting === cost.setting
                              ? "success"
                              : "secondary"
                          }
                          onClick={() => {
                            if (ship.water_setting !== cost.setting) {
                              dispatch(
                                requestUpdateRationSetting(
                                  ship.id,
                                  "water",
                                  cost.setting
                                )
                              );
                            }
                          }}
                        >
                          {capitalizeFirstLetter(cost.setting)}
                        </Button>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-uppercase text-center">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    <p>Results by d6 roll</p>
                    <p>
                      13 or more - Morale rises 2 levels
                      <br />
                      7 to 12 - Morale rises 1 level
                      <br />
                      1 to 6 - Morale unchanged
                      <br />
                      -3 to 0 - Morale drops 1 level
                      <br />
                      -4 or less - Morale drops 2 levels
                    </p>
                  </Tooltip>
                }
              >
                <span>
                  Morale Roll Modifiers
                  <sup>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </sup>
                </span>
              </OverlayTrigger>
            </h2>

            <Table>
              <tbody>
                <tr>
                  <th>Type</th>
                  <th>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          If chosen food ration cost exceeds available food when
                          the morale roll is calculated, ration level will be
                          set to highest affordable value. This is not reflected
                          in the below total.
                        </Tooltip>
                      }
                    >
                      <span>
                        Food Ration
                        <sup>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </sup>
                      </span>
                    </OverlayTrigger>
                  </th>
                  <th>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          If chosen water ration cost exceeds available water
                          when the morale roll is calculated, ration level will
                          be set to highest affordable value. This is not
                          reflected in the below total.
                        </Tooltip>
                      }
                    >
                      <span>
                        Water Ration
                        <sup>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </sup>
                      </span>
                    </OverlayTrigger>
                  </th>
                  <th>Damage Taken</th>
                  <th>Wolf Boarders</th>
                  <th>News Coverage</th>
                  <th>Other</th>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>Modifier from food ration chosen above.</td>
                  <td>Modifier from water ration chosen above.</td>
                  <td>
                    {ship.id === 1
                      ? "-1 penalty for every 3 damage taken in a turn."
                      : "-1 penalty for each damage taken in a turn."}
                  </td>
                  <td>
                    -1 penalty for each wolf boarder on ship at any point this
                    turn.
                  </td>
                  <td>
                    Morale impact from news coverage, as determined by
                    Faciitators.
                  </td>
                  <td>
                    Other morale roll modifiers, as assigned by Faciitators.
                  </td>
                </tr>
                <tr>
                  <th>Roll Modifier</th>
                  <td>{moraleRollImpact[ship.food_setting]}</td>
                  <td>{moraleRollImpact[ship.water_setting]}</td>
                  <td>
                    -
                    {ship.id === 1
                      ? Math.floor(ship.turn_damage_taken / 3)
                      : ship.turn_damage_taken}
                  </td>
                  <td>-{ship.turn_wolf_boarders}</td>
                  <td>
                    {!isFacilitator && (
                      <>
                        {ship.turn_news_modifier > 0 && "+"}
                        {ship.turn_news_modifier}
                      </>
                    )}
                    {isFacilitator && (
                      <Form inline>
                        <InputGroup>
                          <NumberSelector
                            value={newsMoraleModifier}
                            onChange={setNewsMoraleModifier}
                            start={-10}
                            end={10}
                            showPlus
                          />
                          <InputGroup.Append>
                            <Button
                              onClick={() =>
                                dispatch(
                                  requestUpdateNewsMoraleModifier(
                                    ship.id,
                                    newsMoraleModifier
                                  )
                                )
                              }
                            >
                              Save
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form>
                    )}
                  </td>
                  <td>
                    {!isFacilitator && (
                      <>
                        {ship.turn_misc_modifier > 0 && "+"}
                        {ship.turn_misc_modifier}
                      </>
                    )}
                    {isFacilitator && (
                      <Form inline>
                        <InputGroup>
                          <NumberSelector
                            value={miscMoraleModifier}
                            onChange={setMiscMoraleModifier}
                            start={-10}
                            end={10}
                            showPlus
                          />
                          <InputGroup.Append>
                            <Button
                              onClick={() =>
                                dispatch(
                                  requestUpdateMiscMoraleModifier(
                                    ship.id,
                                    miscMoraleModifier
                                  )
                                )
                              }
                            >
                              Save
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={7} className="text-center">
                    <h4>
                      <span className="bg-info text-white p-2 rounded">
                        {`
                      Total Roll Modifier: ${
                        totalRollModifier >= 0 ? "+" : ""
                      }${totalRollModifier}`}
                      </span>
                    </h4>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center text-uppercase">
              Previous Turn Morale Results
            </h2>
            <p className="text-center">
              <FormattedText text={ship.turn_notes} />
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Rations;
