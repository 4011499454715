import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";

const getTransactionEffects = (state) =>
  Object.values(state.transactionEffects);

export const getTransactionEffect = (state, transactionEffectId) =>
  state.transactionEffects ? state.transactionEffects[transactionEffectId] : {};

export const getTransactionEffectsForTransaction = (state, transactionId) => {
  const allTransactionEffects = getTransactionEffects(state);
  return allTransactionEffects.filter((transactionEffect) => {
    return transactionEffect.transaction_id === transactionId;
  });
};

const FETCH_REQUESTED = "maas-loader/transactionEffect/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/transactionEffect/FETCH_SUCCEEDED";
const FETCH_FAILED = "maas-loader/transactionEffect/FETCH_FAILED";
const UPDATE_RECEIVED = "maas-loader/transactionEffect/UPDATE_RECEIVED";
const DELETE_RECEIVED = "maas-loader/transactionEffect/DELETE_RECEIVED";
const UPDATE_TRANSACTION_EFFECT_VALUES_REQUESTED =
  "maas-loader/transactionEffect/UPDATE_TRANSACTION_EFFECT_VALUES_REQUESTED";
const DELETE_TRANSACTION_EFFECT_REQUESTED =
  "maas-loader/transactionEffect/DELETE_TRANSACTION_EFFECT_REQUESTED";
const CREATE_TRANSACTION_EFFECT_REQUESTED =
  "maas-loader/transactionEffect/CREATE_TRANSACTION_EFFECT_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { transactionEffects } = action;
      return transactionEffects;
    }
    case UPDATE_RECEIVED: {
      const { transactionEffect } = action;
      return { ...state, [transactionEffect.id]: transactionEffect };
    }
    case DELETE_RECEIVED: {
      const { transactionEffect } = action;
      const { [transactionEffect.id]: _, ...rest } = state;
      return rest;
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(transactionEffects) {
  return { type: FETCH_SUCCEEDED, transactionEffects };
}

export function fetchFailed(message) {
  return { type: FETCH_FAILED, message };
}

export function updateReceived(transactionEffect) {
  return { type: UPDATE_RECEIVED, transactionEffect };
}

export function deleteReceived(transactionEffect) {
  return { type: DELETE_RECEIVED, transactionEffect };
}

export function requestUpdateTransactionEffectValues(
  transactionEffectId,
  values
) {
  return {
    type: UPDATE_TRANSACTION_EFFECT_VALUES_REQUESTED,
    transactionEffectId,
    values,
  };
}

export function requestDeleteTransactionEffect(transactionEffectId) {
  return {
    type: DELETE_TRANSACTION_EFFECT_REQUESTED,
    transactionEffectId,
  };
}

export function requestCreateTransactionEffect(values) {
  return {
    type: CREATE_TRANSACTION_EFFECT_REQUESTED,
    values,
  };
}

function* fetchTransactionEffects() {
  try {
    const transactionEffectResponse = yield call(() =>
      axios.get("/api/v1/transaction_effect/")
    );
    const transactionEffects = {};
    Object.values(transactionEffectResponse.data).forEach(
      (transactionEffect) => {
        transactionEffects[transactionEffect.id] = transactionEffect;
      }
    );
    yield put(fetchSucceeded(transactionEffects));
  } catch (e) {
    yield put(fetchFailed(e.message));
  }
}

export function* watchFetchTransactionEffects() {
  yield takeLatest(FETCH_REQUESTED, fetchTransactionEffects);
}

function* updateTransactionEffectValues(action) {
  const { transactionEffectId, values } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/transaction_effect/${transactionEffectId}`, values)
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateTransactionEffectValues() {
  yield takeEvery(
    UPDATE_TRANSACTION_EFFECT_VALUES_REQUESTED,
    updateTransactionEffectValues
  );
}

function* deleteTransactionEffect(action) {
  const { transactionEffectId } = action;
  try {
    yield call(() =>
      axios.delete(`/api/v1/transaction_effect/${transactionEffectId}`)
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchDeleteTransactionEffect() {
  yield takeEvery(DELETE_TRANSACTION_EFFECT_REQUESTED, deleteTransactionEffect);
}

function* createTransactionEffect(action) {
  const { values } = action;
  try {
    yield call(() => axios.post(`/api/v1/transaction_effect/`, values));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchCreateTransactionEffect() {
  yield takeEvery(CREATE_TRANSACTION_EFFECT_REQUESTED, createTransactionEffect);
}
