import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiceOne,
  faDiceTwo,
  faDiceThree,
  faDiceFour,
  faDiceFive,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function DiceRow({ east, south }) {
  return (
    <Row
      className={`d-flex w-100 m-0 ${!south ? "pt-1" : "pb-1"} ${
        !east ? "flex-row " : "flex-row-reverse ml-0"
      }`}
    >
      <Col xs={2} className="text-center">
        <div className="bg-light rounded">
          <FontAwesomeIcon icon={faDiceOne} />
        </div>
      </Col>
      <Col xs={2} className="text-center">
        <div className="bg-light rounded">
          <FontAwesomeIcon icon={faDiceTwo} />
        </div>
      </Col>
      <Col xs={2} className="text-center">
        <div className="bg-light rounded">
          <FontAwesomeIcon icon={faDiceThree} />
        </div>
      </Col>
      <Col xs={2} className="text-center">
        <div className="bg-light rounded">
          <FontAwesomeIcon icon={faDiceFour} />
        </div>
      </Col>
      <Col xs={2} className="text-center">
        <div className="bg-light rounded">
          <FontAwesomeIcon icon={faDiceFive} />
        </div>
      </Col>
    </Row>
  );
}

DiceRow.propTypes = {
  east: PropTypes.bool,
  south: PropTypes.bool,
};

DiceRow.defaultProps = {
  east: false,
  south: false,
};

export default DiceRow;
