import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ProgressBar from "react-bootstrap/ProgressBar";
import { activeUserIsFacilitator } from "store/ducks/user";
import { getActivePlayer } from "store/ducks/player";
import { getPercentUndamagedStationsForShip } from "store/ducks/station";
import { getSelectedItem, setSelectedItem } from "store/ducks/display";
import { getShipById } from "store/ducks/ship";

function AegisToken() {
  const dispatch = useDispatch();

  const isUserFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);
  const player = useSelector(getActivePlayer, shallowEqual);
  const percentUndamaged = useSelector(
    (state) => getPercentUndamagedStationsForShip(state, 1),
    shallowEqual
  );
  const ship = useSelector((state) => getShipById(state, 1), shallowEqual);
  const selectedItem = useSelector(getSelectedItem, shallowEqual);

  const isInteractive = () => {
    return isUserFacilitator || (player && player.ship_id === 1);
  };

  return (
    <div
      className={`aegis-token ship-token rounded ${
        !ship || ship.wolf_boarding_units === 0 ? "bg-light" : "bg-warning"
      } border ${
        selectedItem &&
        selectedItem.itemType === "ship" &&
        selectedItem.itemId === ship.id
          ? "border-warning"
          : "border-dark"
      }`}
      title="Aegis"
      onClick={
        isInteractive() ? () => dispatch(setSelectedItem("ship", 1)) : () => {}
      }
      onKeyUp={
        isInteractive() ? () => dispatch(setSelectedItem("ship", 1)) : () => {}
      }
      role={isInteractive() ? "button" : ""}
      tabIndex={isInteractive() ? 1 : null}
    >
      <ProgressBar
        now={percentUndamaged}
        variant="success"
        style={{ height: "4px" }}
      />
      Aegis
    </div>
  );
}

export default AegisToken;
