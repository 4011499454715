import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CardDeck from "react-bootstrap/CardDeck";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { DragDropContext } from "react-beautiful-dnd";
import Station from "views/components/ship/stations/Station";
import SecurityStation from "views/components/ship/stations/SecurityStation";
import { requestTransferStation } from "store/ducks/crew";
import { getStationsForActiveShip } from "store/ducks/station";

// TODO: Display error on failure to transfer crew

function Stations() {
  const dispatch = useDispatch();

  const onDragEnd = async ({ source, destination, reason, draggableId }) => {
    if (
      destination &&
      reason === "DROP" &&
      destination.droppableId !== source.droppableId
    ) {
      dispatch(
        requestTransferStation(
          draggableId.split("-").pop(),
          destination.droppableId.split("-").pop()
        )
      );
    }
  };

  const stations = useSelector(getStationsForActiveShip, shallowEqual);

  const stationsRef = useRef([]);

  useEffect(() => {
    stationsRef.current = stationsRef.current.slice(0, stations.length);
  }, [stations.length]);

  const handleExpandAllClick = (e) => {
    e.preventDefault();
    stationsRef.current.forEach((ref) => ref.expand());
  };

  const handleCollapseAllClick = (e) => {
    e.preventDefault();
    stationsRef.current.forEach((ref) => ref.collapse());
  };

  return (
    <section className="mb-0 py-4 mx-2">
      <div className="text-center mb-3">
        <h2 className="text-uppercase">Stations</h2>{" "}
        <Button
          className="mr-1"
          variant="light"
          size="sm"
          onClick={handleExpandAllClick}
        >
          Expand All
        </Button>
        <Button variant="light" size="sm" onClick={handleCollapseAllClick}>
          Collapse All
        </Button>
      </div>
      <Container fluid>
        <DragDropContext onDragEnd={onDragEnd}>
          <CardDeck className="mb-3 mx-0 px-0 text-center">
            <Row>
              {stations.map((station, i) => {
                return (
                  <Station
                    stationId={station.id}
                    key={station.id}
                    ref={(el) => {
                      stationsRef.current[i] = el;
                    }}
                  />
                );
              })}
              <SecurityStation />
            </Row>
          </CardDeck>
        </DragDropContext>
      </Container>
    </section>
  );
}

export default Stations;
