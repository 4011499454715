import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ActionCard from "views/components/ship/actionCards/ActionCard";
import { getActionCardsForActiveShip } from "store/ducks/actionCard";

function ActionCards() {
  const actionCards = useSelector(getActionCardsForActiveShip, shallowEqual);

  if (!actionCards || actionCards.length === 0) {
    return <div />;
  }

  return (
    <section className="mb-0 py-4 mx-2">
      <h2 className="text-center text-uppercase">Action Cards</h2>
      <Container fluid>
        <Row className="text-center mx-4">
          {actionCards.map((actionCard) => {
            return (
              <ActionCard key={actionCard.id} actionCardId={actionCard.id} />
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default ActionCards;
