import { put, takeLatest, all, take } from "redux-saga/effects";
import {
  requestFetch as requestFetchStations,
  FETCH_SUCCEEDED as fetchStationsSucceeded,
} from "./station";
import {
  requestFetch as requestFetchCrew,
  FETCH_SUCCEEDED as fetchCrewSucceeded,
} from "./crew";
import {
  requestFetch as requestFetchLoadingDock,
  FETCH_SUCCEEDED as fetchLoadingDockSucceeded,
} from "./loadingDock";
import {
  requestFetch as requestFetchShips,
  FETCH_SUCCEEDED as fetchShipsSucceeded,
} from "./ship";
import {
  requestFetch as requestFetchCosts,
  FETCH_SUCCEEDED as fetchCostsSucceeded,
} from "./cost";
import {
  requestFetch as requestFetchTransactions,
  FETCH_SUCCEEDED as fetchTransactionsSucceeded,
} from "./transaction";
import {
  requestFetch as requestFetchTransactionRequirements,
  FETCH_SUCCEEDED as fetchTransactionRequirementsSucceeded,
} from "./transactionRequirement";
import {
  requestFetch as requestFetchTransactionEffets,
  FETCH_SUCCEEDED as fetchTransactionEffectsSucceeded,
} from "./transactionEffect";
import {
  requestFetch as requestFetchGameTurn,
  FETCH_SUCCEEDED as fetchGameTurnSucceeded,
} from "./gameTurn";
import {
  requestFetch as requestFetchResearchPoints,
  FETCH_SUCCEEDED as fetchResearchPointsSucceeded,
} from "./researchPoints";
import {
  requestFetch as requestFetchShuttles,
  FETCH_SUCCEEDED as fetchShuttlesSucceeded,
} from "./shuttle";
import {
  requestFetch as requestFetchActionCards,
  FETCH_SUCCEEDED as fetchActionsCardsSucceeded,
} from "./actionCard";
import {
  requestFetch as requestFetchShuttleCargo,
  FETCH_SUCCEEDED as fetchShuttleCargoSucceeded,
} from "./shuttleCargo";
import {
  requestFetch as requestFetchUsers,
  FETCH_SUCCEEDED as fetchUsersSucceeded,
} from "./user";
import { setLoadingData } from "./display";
import {
  requestFetch as requestFetchPlayers,
  FETCH_SUCCEEDED as fetchPlayersSucceeded,
} from "./player";
import {
  requestFetch as requestFetchFighters,
  FETCH_SUCCEEDED as fetchFightersSucceeded,
} from "./fighter";
import {
  requestFetch as requestFetchWolfUnits,
  FETCH_SUCCEEDED as fetchWolfUnitsSucceeded,
} from "./wolfUnit";
import {
  requestFetch as requestFetchCombatLogMessages,
  FETCH_SUCCEEDED as fetchCombatLogMessagesSucceeded,
} from "./combatLogMessage";

const ON_PAGE_LOAD = "maas-loader/pageLoad/ON_PAGE_LOAD";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function onPageLoad() {
  return { type: ON_PAGE_LOAD };
}

function* loadAllData() {
  yield put(setLoadingData(true));

  yield all([
    put(requestFetchUsers()),
    put(requestFetchPlayers()),
    put(requestFetchStations()),
    put(requestFetchCrew()),
    put(requestFetchLoadingDock()),
    put(requestFetchShips()),
    put(requestFetchCosts()),
    put(requestFetchTransactions()),
    put(requestFetchTransactionRequirements()),
    put(requestFetchTransactionEffets()),
    put(requestFetchGameTurn()),
    put(requestFetchResearchPoints()),
    put(requestFetchShuttles()),
    put(requestFetchActionCards()),
    put(requestFetchShuttleCargo()),
    put(requestFetchFighters()),
    put(requestFetchWolfUnits()),
    put(requestFetchCombatLogMessages()),
  ]);

  yield all([
    take(fetchUsersSucceeded),
    take(fetchPlayersSucceeded),
    take(fetchStationsSucceeded),
    take(fetchCrewSucceeded),
    take(fetchLoadingDockSucceeded),
    take(fetchShipsSucceeded),
    take(fetchCostsSucceeded),
    take(fetchTransactionsSucceeded),
    take(fetchTransactionRequirementsSucceeded),
    take(fetchTransactionEffectsSucceeded),
    take(fetchGameTurnSucceeded),
    take(fetchResearchPointsSucceeded),
    take(fetchShuttlesSucceeded),
    take(fetchActionsCardsSucceeded),
    take(fetchShuttleCargoSucceeded),
    take(fetchFightersSucceeded),
    take(fetchWolfUnitsSucceeded),
    take(fetchCombatLogMessagesSucceeded),
  ]);

  yield put(setLoadingData(false));
}

export function* watchOnPageLoad() {
  yield takeLatest(ON_PAGE_LOAD, loadAllData);
}
