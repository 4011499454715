import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getTransactionEffect } from "store/ducks/transactionEffect";

function MoraleBonus({ effectId }) {
  const effect = useSelector(
    (state) => getTransactionEffect(state, effectId),
    shallowEqual
  );

  return (
    <Row>
      <Col>
        <h4>Bonus to Morale Roll</h4>
        <p>Ship will gain a +{effect.bonus} to this turn&apos;s morale roll.</p>
      </Col>
    </Row>
  );
}

MoraleBonus.propTypes = {
  effectId: PropTypes.number.isRequired,
};

export default MoraleBonus;
