import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  getUpdateSucceeded,
  getErrorMessage,
  clearUpdateStatus,
} from "store/ducks/display";
import { getStation } from "store/ducks/station";
import { requestCommitTransaction } from "store/ducks/transaction";
import { getTransactionRequirementsForTransaction } from "store/ducks/transactionRequirement";
import { getTransactionEffectsForTransaction } from "store/ducks/transactionEffect";
import UseCrewAtStation from "./transaction/requirements/UseCrewAtStation";
import SpendResource from "./transaction/requirements/SpendResource";
import RefineOre from "./transaction/requirements/RefineOre";
import AddResearchPoints from "./transaction/effects/AddResearchPoints";
import AddResource from "./transaction/effects/AddResource";
import MoraleBonus from "./transaction/effects/MoraleBonus";
import AddOreOrMaterial from "./transaction/effects/AddOreOrMaterial";
import HealCrewAtStation from "./transaction/effects/HealCrewAtStation";
import RepairSynthsAtStation from "./transaction/effects/RepairSynthsAtStation";
import ChargeSynthsAtStation from "./transaction/effects/ChargeSynthsAtStation";
import TransportPrisoner from "./transaction/effects/TransportPrisoner";

function Transaction({ show, onHide, stationId }) {
  const dispatch = useDispatch();

  const station = useSelector(
    (state) => getStation(state, stationId),
    shallowEqual
  );
  const transactionRequirements = useSelector(
    (state) =>
      getTransactionRequirementsForTransaction(state, station.transaction_id),
    shallowEqual
  );
  const transactionEffects = useSelector(
    (state) =>
      getTransactionEffectsForTransaction(state, station.transaction_id),
    shallowEqual
  );
  const updateSucceeded = useSelector(getUpdateSucceeded, shallowEqual);
  const errorMessage = useSelector(getErrorMessage, shallowEqual);

  const [requirementsMet, setRequirementsMet] = useState({});
  const [requirementsData, setRequirementsData] = useState({});
  const [effectsData, setEffectsData] = useState({});
  const [effectsRequirementsMet, setEffectsRequirementsMet] = useState({});
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (updateSucceeded === undefined) {
      setShowSuccessAlert(false);
      setShowErrorAlert(false);
      setErrorAlertMessage("");
    }
    if (updateSucceeded !== undefined) {
      setLoading(false);
      if (updateSucceeded !== showSuccessAlert) {
        setShowSuccessAlert(updateSucceeded);
      }
      if (updateSucceeded === showErrorAlert) {
        setShowErrorAlert(!updateSucceeded);
      }
    }
    if (errorMessage !== undefined && errorMessage !== errorAlertMessage) {
      setErrorAlertMessage(errorMessage);
    }
  }, [
    updateSucceeded,
    showSuccessAlert,
    showErrorAlert,
    errorMessage,
    errorAlertMessage,
  ]);

  const clearAlert = () => {
    if (updateSucceeded !== undefined) {
      dispatch(clearUpdateStatus());
    }
  };

  const updateRequirementsMet = (requirementId, met) => {
    if (requirementsMet[requirementId] !== met) {
      setRequirementsMet({ ...requirementsMet, [requirementId]: met });
    }
  };

  const updateRequirementsData = (requirementId, data) => {
    if (requirementsData[requirementId] !== data) {
      setRequirementsData({ ...requirementsData, [requirementId]: data });
    }
  };

  const updateEffectsRequirementsMet = (effectId, met) => {
    if (effectsRequirementsMet[effectId] !== met) {
      setEffectsRequirementsMet({ ...effectsRequirementsMet, [effectId]: met });
    }
  };

  const updateEffectsData = (effectId, data) => {
    if (effectsData[effectId] !== data) {
      setEffectsData({ ...effectsData, [effectId]: data });
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={() => clearAlert()}
      size="lg"
      aria-labelledby="transactionModalTitle"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="transactionModalTitle">
          Use Station {station.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert
          show={showSuccessAlert}
          variant="success"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Station Used</Alert.Heading>
          Station effects have been applied.
        </Alert>
        <Alert
          show={showErrorAlert}
          variant="danger"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Error</Alert.Heading>
          {errorAlertMessage}
        </Alert>
        <Container>
          {transactionRequirements.map((requirement) => {
            switch (requirement.type) {
              case "useCrewAtStation": {
                return (
                  <UseCrewAtStation
                    key={requirement.id}
                    requirementId={requirement.id}
                    crewCount={1}
                    updateRequirementsMet={updateRequirementsMet}
                    updateRequirementsData={updateRequirementsData}
                  />
                );
              }
              case "useTwoCrewAtStation": {
                return (
                  <UseCrewAtStation
                    key={requirement.id}
                    requirementId={requirement.id}
                    crewCount={2}
                    updateRequirementsMet={updateRequirementsMet}
                    updateRequirementsData={updateRequirementsData}
                  />
                );
              }
              case "spendWater": {
                return (
                  <SpendResource
                    key={requirement.id}
                    requirementId={requirement.id}
                    resource="Water"
                    updateRequirementsMet={updateRequirementsMet}
                  />
                );
              }
              case "spendFood": {
                return (
                  <SpendResource
                    key={requirement.id}
                    requirementId={requirement.id}
                    resource="Food"
                    updateRequirementsMet={updateRequirementsMet}
                  />
                );
              }
              case "spendMaterial": {
                return (
                  <SpendResource
                    key={requirement.id}
                    requirementId={requirement.id}
                    resource="Material"
                    updateRequirementsMet={updateRequirementsMet}
                  />
                );
              }
              case "refineOreVariable": {
                return (
                  <RefineOre
                    key={requirement.id}
                    requirementId={requirement.id}
                    updateRequirementsMet={updateRequirementsMet}
                    updateRequirementsData={updateRequirementsData}
                  />
                );
              }
              default:
                return (
                  <Row key={requirement.id}>
                    <Col />
                  </Row>
                );
            }
          })}
          {transactionEffects.map((effect) => {
            switch (effect.type) {
              case "addResearchPoints":
                return (
                  <AddResearchPoints key={effect.id} effectId={effect.id} />
                );
              case "addFood":
                return (
                  <AddResource
                    key={effect.id}
                    effectId={effect.id}
                    resource="Food"
                  />
                );
              case "addWater":
                return (
                  <AddResource
                    key={effect.id}
                    effectId={effect.id}
                    resource="Water"
                  />
                );
              case "addMaterial":
                return (
                  <AddResource
                    key={effect.id}
                    effectId={effect.id}
                    resource="Material"
                  />
                );
              case "moraleBonus":
                return <MoraleBonus key={effect.id} effectId={effect.id} />;
              case "addOreOrMaterial":
                return (
                  <AddOreOrMaterial
                    key={effect.id}
                    effectId={effect.id}
                    updateEffectsData={updateEffectsData}
                  />
                );
              case "healCrewAtStation":
                return (
                  <HealCrewAtStation
                    key={effect.id}
                    effectId={effect.id}
                    updateEffectsRequirementsMet={updateEffectsRequirementsMet}
                    updateEffectsData={updateEffectsData}
                  />
                );
              case "repairSynthsAtStation":
                return (
                  <RepairSynthsAtStation
                    key={effect.id}
                    effectId={effect.id}
                    updateEffectsRequirementsMet={updateEffectsRequirementsMet}
                  />
                );
              case "chargeSynthsAtStation":
                return (
                  <ChargeSynthsAtStation
                    key={effect.id}
                    effectId={effect.id}
                    updateEffectsRequirementsMet={updateEffectsRequirementsMet}
                  />
                );
              case "transportPrisoner":
                return (
                  <TransportPrisoner
                    key={effect.id}
                    effectId={effect.id}
                    updateEffectsRequirementsMet={updateEffectsRequirementsMet}
                    updateEffectsData={updateEffectsData}
                  />
                );
              default:
                return (
                  <Row key={effect.id}>
                    <Col />
                  </Row>
                );
            }
          })}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={
            isLoading ||
            station.status !== "active" ||
            !Object.values(requirementsMet).reduce(
              (allRequirementsMet, requirementMet) =>
                allRequirementsMet && requirementMet,
              true
            ) ||
            !Object.values(effectsRequirementsMet).reduce(
              (allRequirementsMet, requirementMet) =>
                allRequirementsMet && requirementMet,
              true
            )
          }
          onClick={() => {
            clearAlert();
            dispatch(
              requestCommitTransaction(
                station.transaction_id,
                requirementsData,
                effectsData
              )
            );
            setLoading(true);
          }}
        >
          Commit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

Transaction.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  stationId: PropTypes.number.isRequired,
};

export default Transaction;
