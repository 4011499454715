import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  getTransactionEffect,
  requestUpdateTransactionEffectValues,
  requestDeleteTransactionEffect,
  requestCreateTransactionEffect,
} from "store/ducks/transactionEffect";

function TransactionEffectEditor({
  transactionEffectId,
  shipId,
  stationId,
  transactionId,
}) {
  const dispatch = useDispatch();

  const transactionEffect = useSelector(
    (state) => getTransactionEffect(state, transactionEffectId) || {},
    shallowEqual
  );

  const [showEditor, setShowEditor] = useState(false);

  const [type, setType] = useState(
    transactionEffect.type || "addResearchPoints"
  );

  useEffect(() => {
    if (transactionEffect.type) {
      setType(transactionEffect.type);
    }
  }, [transactionEffect.type]);

  const [quantity, setQuantity] = useState(transactionEffect.quantity || 0);

  useEffect(() => {
    if (transactionEffect.quantity) {
      setQuantity(transactionEffect.quantity);
    }
  }, [transactionEffect.quantity]);

  const [oreQuantity, setOreQuantity] = useState(
    transactionEffect.ore_quantity || 0
  );

  useEffect(() => {
    if (transactionEffect.ore_quantity) {
      setOreQuantity(transactionEffect.ore_quantity);
    }
  }, [transactionEffect.ore_quantity]);

  const [materialQuantity, setMaterialQuantity] = useState(
    transactionEffect.material_quantity || 0
  );

  useEffect(() => {
    if (transactionEffect.material_quantity) {
      setMaterialQuantity(transactionEffect.material_quantity);
    }
  }, [transactionEffect.material_quantity]);

  const [bonus, setBonus] = useState(transactionEffect.bonus || 0);

  useEffect(() => {
    if (transactionEffect.bonus) {
      setBonus(transactionEffect.bonus);
    }
  }, [transactionEffect.bonus]);

  return (
    <>
      <Row>
        {transactionEffectId && (
          <>
            <Col>{transactionEffect.type}</Col>
            <Col>
              <ButtonGroup>
                <Button onClick={() => setShowEditor(!showEditor)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button
                  onClick={() =>
                    dispatch(
                      requestDeleteTransactionEffect(transactionEffectId)
                    )
                  }
                  variant="danger"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </ButtonGroup>
            </Col>
          </>
        )}
        {!transactionEffectId && (
          <Col>
            <Button onClick={() => setShowEditor(!showEditor)}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
        )}
      </Row>
      <Row className={showEditor ? "" : "d-none"}>
        <Col>
          {!transactionEffectId && (
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="addResearchPoints">addResearchPoints</option>
                <option value="addFood">addFood</option>
                <option value="addWater">addWater</option>
                <option value="addMaterial">addMaterial</option>
                <option value="addOreOrMaterial">addOreOrMaterial</option>
                <option value="moraleBonus">moraleBonus</option>
                <option value="healCrewAtStation">healCrewAtStation</option>
                <option value="repairSynthAtStation">
                  repairSynthAtStation
                </option>
                <option value="chargeSynthsAtStation">
                  chargeSynthsAtStation
                </option>
                <option value="transportPrisoner">transportPrisoner</option>
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Ore Quantity</Form.Label>
            <Form.Control
              value={oreQuantity}
              onChange={(e) => setOreQuantity(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Material Quantity</Form.Label>
            <Form.Control
              value={materialQuantity}
              onChange={(e) => setMaterialQuantity(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Bonus</Form.Label>
            <Form.Control
              value={bonus}
              onChange={(e) => setBonus(e.target.value)}
            />
          </Form.Group>
          <Button
            onClick={() => {
              if (transactionEffectId) {
                dispatch(
                  requestUpdateTransactionEffectValues(transactionEffectId, {
                    quantity,
                    ore_quantity: oreQuantity,
                    material_quantity: materialQuantity,
                    bonus,
                  })
                );
              } else {
                dispatch(
                  requestCreateTransactionEffect({
                    transaction_id: transactionId,
                    ship_id: shipId,
                    station_id: stationId,
                    type,
                    quantity,
                    ore_quantity: oreQuantity,
                    material_quantity: materialQuantity,
                    bonus,
                  })
                );
              }
              setShowEditor(false);
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
}

TransactionEffectEditor.propTypes = {
  transactionEffectId: PropTypes.number,
  shipId: PropTypes.number.isRequired,
  stationId: PropTypes.number.isRequired,
  transactionId: PropTypes.number.isRequired,
};

TransactionEffectEditor.defaultProps = {
  transactionEffectId: null,
};

export default TransactionEffectEditor;
