import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {
  getWolfUnitById,
  requestUpdateWolfUnitCurrentHealth,
  requestDelete as requestDeleteWolfUnit,
  requestBoard,
  requestAttack,
} from "store/ducks/wolfUnit";
import { setSelectedItem } from "store/ducks/display";

function WolfUnitControl({ wolfUnitId }) {
  const dispatch = useDispatch();

  const wolfUnit = useSelector(
    (state) => getWolfUnitById(state, wolfUnitId),
    shallowEqual
  );

  // This can happen if the currently-selected unit is deleted
  if (!wolfUnit) {
    return <div />;
  }

  return (
    <Row className="text-light">
      <Col>
        <div className="d-flex flex-row align-items-center">
          <span className="mr-4">{wolfUnit.name} Actions:</span>
          <ButtonToolbar>
            <ButtonGroup className="mr-2">
              {wolfUnit.type !== "boarding" && (
                <Button onClick={() => dispatch(requestAttack(wolfUnitId))}>
                  Attack x{wolfUnit.number_of_attacks}
                </Button>
              )}
              {wolfUnit.type === "boarding" && (
                <Button
                  onClick={() => {
                    setSelectedItem(null, null);
                    dispatch(requestBoard(wolfUnitId));
                  }}
                >
                  Board
                </Button>
              )}
            </ButtonGroup>
            <ButtonGroup className="mr-2">
              <Button
                disabled={wolfUnit.current_health === 0}
                onClick={() =>
                  dispatch(
                    requestUpdateWolfUnitCurrentHealth(
                      wolfUnitId,
                      wolfUnit.current_health - 1
                    )
                  )
                }
              >
                Damage Unit
              </Button>
              <Button
                disabled={wolfUnit.current_health === wolfUnit.max_health}
                onClick={() =>
                  dispatch(
                    requestUpdateWolfUnitCurrentHealth(
                      wolfUnitId,
                      wolfUnit.current_health + 1
                    )
                  )
                }
              >
                Repair Unit
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button
                onClick={() => {
                  dispatch(setSelectedItem(null, null));
                  dispatch(requestDeleteWolfUnit(wolfUnitId));
                }}
              >
                Delete Unit
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      </Col>
    </Row>
  );
}

WolfUnitControl.propTypes = {
  wolfUnitId: PropTypes.number.isRequired,
};

export default WolfUnitControl;
