import { call, put, takeLatest, takeEvery, select } from "redux-saga/effects";
import axios from "axios";
import { createSelector } from "reselect";
import { getShipId, setUpdateSucceeded, setUpdateFailed } from "./display";
import { getActivePlayer } from "./player";

export const getShips = (state) => Object.values(state.ships);

export const getEnabledShips = createSelector([getShips], (ships) => {
  return ships.filter((ship) => !ship.disabled);
});

export const getShipById = (state, shipId) =>
  state.ships ? state.ships[shipId] : {};

export const getActiveShip = createSelector(
  [getShipId, getShips],
  (shipId, ships) => {
    return ships.find((ship) => ship.id === shipId);
  }
);

export const getActivePlayerShip = createSelector(
  [getActivePlayer, getShips],
  (player, ships) => {
    if (!player) return null;
    return ships.find((ship) => ship.id === player.ship_id);
  }
);

export const getShipJumpDriveEngaged = createSelector(
  [getActiveShip],
  (ship) => {
    return ship ? ship.jump_active : false;
  }
);

export const getBattlemapShips = createSelector([getShips], (ships) => {
  const neQuadrant = {};
  const nwQuadrant = {};
  const seQuadrant = {};
  const swQuadrant = {};

  ships.forEach((ship) => {
    const battleMapShip = {
      name: ship.name,
      id: ship.id,
      size: ship.battle_map_size,
      boardingUnits: ship.wolf_boarding_units,
    };

    switch (ship.battle_map_quadrant) {
      case "NE": {
        neQuadrant[ship.battle_map_number] = battleMapShip;
        break;
      }
      case "NW": {
        nwQuadrant[ship.battle_map_number] = battleMapShip;
        break;
      }
      case "SE": {
        seQuadrant[ship.battle_map_number] = battleMapShip;
        break;
      }
      case "SW": {
        swQuadrant[ship.battle_map_number] = battleMapShip;
        break;
      }
      default:
    }
  });

  return { neQuadrant, nwQuadrant, seQuadrant, swQuadrant };
});

export const getShipJumpCrewUsed = createSelector([getActiveShip], (ship) => {
  return ship ? ship.turn_jump_crew_used : false;
});

export const getShipCargoTotals = (state) => {
  const allShips = getShips(state);
  const cargo = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
  allShips.forEach((ship) => {
    cargo[1] += ship.food;
    cargo[2] += ship.water;
    cargo[3] += ship.ore;
    cargo[4] += ship.fuel;
    cargo[5] += ship.material;
  });
  return cargo;
};

const FETCH_REQUESTED = "maas-loader/ship/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/ship/FETCH_SUCCEEDED";
const UPDATE_RECEIVED = "maas-loader/ship/UPDATE_RECEIVED";
const UPDATE_JUMP_COORDINATES_REQUESTED =
  "maas-loader/ship/UPDATE_JUMP_COORDINATES_REQUESTED";
const ENGAGE_JUMP_DRIVE_REQUESTED =
  "maas-loader/ship/ENGAGE_JUMP_DRIVE_REQUESTED";
const MOVE_SHIP_REQUESTED = "maas-loader/ship/MOVE_SHIP_REQUESTED";
const MOVE_SHIP_TENTATIVE = "maas-loader/ship/MOVE_SHIP_TENTATIVE";
const MOVE_SHIP_FAILED = "maas-loader/ship/MOVE_SHIP_FAILED";
const UPDATE_LOADING_DOCK_ACCESS_REQUESTED =
  "maas-loader/ship/UPDATE_LOADING_DOCK_ACCESS_REQUESTED";
const UPDATE_RATION_SETTING_REQUESTED =
  "maas-loader/ship/UPDATE_RATION_SETTING_REQUESTED";
const DAMAGE_SHIP_REQUESTED = "maas-loader/ship/DAMAGE_SHIP_REQUESTED";
const ADD_BOARDER_REQUESTED = "maas-loader/ship/ADD_BOARDER_REQUESTED";
const REMOVE_BOARDER_REQUESTED = "maas-loader/ship/REMOVE_BOARDER_REQUESTED";
const REPEL_BOARDING_UNIT_REQUESTED =
  "maas-loader/ship/REPEL_BOARDING_UNIT_REQUESTED";
const UPDATE_NEWS_MORALE_MODIFIER_REQUESTED =
  "maas-loader/ship/UPDATE_NEWS_MORALE_MODIFIER_REQUESTED";
const ROLL_BOARDER_DAMAGE_REQUESTED =
  "maas-loader/ship/ROLL_BOARDER_DAMAGE_REQUESTED";
const UPDATE_RESOURCE_REQUESTED = "maas-loader/ship/UPDATE_RESOURCE_REQUESTED";
const UPDATE_MISC_MORALE_MODIFIER_REQUESTED =
  "maas-loader/ship/UPDATE_MISC_MORALE_MODIFIER_REQUESTED";
const JUMP_SHIPS_REQUESTED = "maas-loader/ship/JUMP_SHIPS_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { ships } = action;
      return ships;
    }
    case UPDATE_RECEIVED: {
      const { ship } = action;
      return { ...state, [ship.id]: ship };
    }
    case MOVE_SHIP_TENTATIVE:
    case MOVE_SHIP_FAILED: {
      const { shipId, quadrant, position } = action;
      return {
        ...state,
        [shipId]: {
          ...state[shipId],
          battle_map_quadrant: quadrant,
          battle_map_number: position,
        },
      };
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(ships) {
  return { type: FETCH_SUCCEEDED, ships };
}

export function updateReceived(ship) {
  return { type: UPDATE_RECEIVED, ship };
}

export function requestUpdateJumpCoordinates(shipId, jumpCoordinates) {
  return {
    type: UPDATE_JUMP_COORDINATES_REQUESTED,
    shipId,
    jumpCoordinates,
  };
}

export function requestEngageJumpDrive(shipId) {
  return {
    type: ENGAGE_JUMP_DRIVE_REQUESTED,
    shipId,
  };
}

export function requestMoveShip(shipId, quadrant, position) {
  return {
    type: MOVE_SHIP_REQUESTED,
    shipId,
    quadrant,
    position,
  };
}

export function moveShipTentative(shipId, quadrant, position) {
  return {
    type: MOVE_SHIP_TENTATIVE,
    shipId,
    quadrant,
    position,
  };
}

export function moveShipFailed(shipId, quadrant, position) {
  return {
    type: MOVE_SHIP_FAILED,
    shipId,
    quadrant,
    position,
  };
}

export function requestUpdateLoadingDockAccess(shipId, accessible) {
  return { type: UPDATE_LOADING_DOCK_ACCESS_REQUESTED, shipId, accessible };
}

export function requestUpdateRationSetting(shipId, ration, setting) {
  return { type: UPDATE_RATION_SETTING_REQUESTED, shipId, ration, setting };
}

export function requestDamageShip(shipId) {
  return { type: DAMAGE_SHIP_REQUESTED, shipId };
}

export function requestAddBoarder(shipId) {
  return { type: ADD_BOARDER_REQUESTED, shipId };
}

export function requestRemoveBoarder(shipId, reduceMoralePenalty) {
  return { type: REMOVE_BOARDER_REQUESTED, shipId, reduceMoralePenalty };
}

export function requestRepelBoardingUnit(shipId, crewId, modifier) {
  return { type: REPEL_BOARDING_UNIT_REQUESTED, shipId, crewId, modifier };
}

export function requestUpdateNewsMoraleModifier(shipId, modifier) {
  return { type: UPDATE_NEWS_MORALE_MODIFIER_REQUESTED, shipId, modifier };
}

export function requestUpdateMiscMoraleModifier(shipId, modifier) {
  return { type: UPDATE_MISC_MORALE_MODIFIER_REQUESTED, shipId, modifier };
}

export function requestRollBoarderDamage(shipId) {
  return { type: ROLL_BOARDER_DAMAGE_REQUESTED, shipId };
}

export function requestUpdateResource(shipId, resource, value) {
  return { type: UPDATE_RESOURCE_REQUESTED, shipId, resource, value };
}

export function requestJumpShips(ships) {
  return { type: JUMP_SHIPS_REQUESTED, ships };
}

function* fetchShip() {
  try {
    const shipResponse = yield call(() => axios.get("/api/v1/ships/"));
    const ships = {};
    Object.values(shipResponse.data).forEach((ship) => {
      ships[ship.id] = ship;
    });
    yield put(fetchSucceeded(ships));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchShip() {
  yield takeLatest(FETCH_REQUESTED, fetchShip);
}

function* updateJumpCoordinates(action) {
  const { shipId, jumpCoordinates } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/ships/${shipId}`, {
        jump_coordinates: jumpCoordinates,
      })
    );
    yield put(setUpdateSucceeded());
  } catch (e) {
    yield put(setUpdateFailed(e.message));
  }
}

export function* watchUpdateJumpCoordinates() {
  yield takeEvery(UPDATE_JUMP_COORDINATES_REQUESTED, updateJumpCoordinates);
}

function* engageJumpDrive(action) {
  const { shipId } = action;
  try {
    yield call(() => axios.put(`/api/v1/ships/${shipId}/jump`, {}));
    yield put(setUpdateSucceeded());
  } catch (e) {
    yield put(setUpdateFailed(e.message));
  }
}

export function* watchEngageJumpDrive() {
  yield takeEvery(ENGAGE_JUMP_DRIVE_REQUESTED, engageJumpDrive);
}

function* moveShip(action) {
  const { shipId, quadrant, position } = action;
  // Record the ship's original position in case the move fails
  const ship = yield select(getShipById, shipId);
  const originalQuadrant = ship.battle_map_quadrant;
  const originalPosition = ship.battle_map_number;
  // Move the ship for the sake of a smooth UI
  yield put(moveShipTentative(shipId, quadrant, position));
  try {
    yield call(() =>
      axios.put(`/api/v1/ships/${shipId}`, {
        battle_map_quadrant: quadrant,
        battle_map_number: position,
      })
    );
  } catch (e) {
    yield put(moveShipFailed(shipId, originalQuadrant, originalPosition));
  }
}

export function* watchMoveShip() {
  yield takeEvery(MOVE_SHIP_REQUESTED, moveShip);
}

function* updateLoadingDockAccess(action) {
  const { shipId, accessible } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/ships/${shipId}`, {
        loading_dock_access: accessible,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateLoadingDockAccess() {
  yield takeEvery(
    UPDATE_LOADING_DOCK_ACCESS_REQUESTED,
    updateLoadingDockAccess
  );
}

function* updateRationSetting(action) {
  const { shipId, ration, setting } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/ships/${shipId}`, {
        [`${ration}_setting`]: setting,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateRationSetting() {
  yield takeEvery(UPDATE_RATION_SETTING_REQUESTED, updateRationSetting);
}

function* damageShip(action) {
  const { shipId } = action;
  try {
    yield call(() => axios.put(`/api/v1/ships/${shipId}/damage_ship`, {}));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchDamageShip() {
  yield takeEvery(DAMAGE_SHIP_REQUESTED, damageShip);
}

function* addBoarder(action) {
  const { shipId } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/ships/${shipId}/add_boarding_unit`, {})
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchAddBoarder() {
  yield takeEvery(ADD_BOARDER_REQUESTED, addBoarder);
}

function* removeBoarder(action) {
  const { shipId, reduceMoralePenalty } = action;
  try {
    yield call(() =>
      axios.put(
        `/api/v1/ships/${shipId}/remove_boarding_unit?reduce_morale_penalty=${reduceMoralePenalty}`,
        {}
      )
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchRemoveBoarder() {
  yield takeEvery(REMOVE_BOARDER_REQUESTED, removeBoarder);
}

function* repelBoardingUnit(action) {
  const { shipId, crewId, modifier } = action;
  try {
    yield call(() =>
      axios.put(
        `/api/v1/ships/${shipId}/repel_boarding_unit/crew/${crewId}?modifier=${modifier}`,
        {}
      )
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchRepelBoardingUnit() {
  yield takeEvery(REPEL_BOARDING_UNIT_REQUESTED, repelBoardingUnit);
}

function* updateNewsMoraleModifier(action) {
  const { shipId, modifier } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/ships/${shipId}`, { turn_news_modifier: modifier })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateNewsMoraleModifier() {
  yield takeEvery(
    UPDATE_NEWS_MORALE_MODIFIER_REQUESTED,
    updateNewsMoraleModifier
  );
}

function* rollBoarderDamage(action) {
  const { shipId } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/ships/${shipId}/roll_boarder_damage`, {})
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchRollBoarderDamage() {
  yield takeEvery(ROLL_BOARDER_DAMAGE_REQUESTED, rollBoarderDamage);
}

function* updateResource(action) {
  const { shipId, resource, value } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/ships/${shipId}`, { [resource]: value })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateResource() {
  yield takeEvery(UPDATE_RESOURCE_REQUESTED, updateResource);
}

function* updateMiscMoraleModifier(action) {
  const { shipId, modifier } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/ships/${shipId}`, { turn_misc_modifier: modifier })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateMiscMoraleModifier() {
  yield takeEvery(
    UPDATE_MISC_MORALE_MODIFIER_REQUESTED,
    updateMiscMoraleModifier
  );
}

function* jumpShips(action) {
  const { ships } = action;
  try {
    yield call(() => axios.put(`/api/v1/ships/jump`, { ships }));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchJumpShips() {
  yield takeEvery(JUMP_SHIPS_REQUESTED, jumpShips);
}
