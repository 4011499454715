import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";

export const getResearchPoints = (state) => state.researchPoints;

const FETCH_REQUESTED = "maas-loader/researchPoints/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/researchPoints/FETCH_SUCCEEDED";
const UPDATE_RECEIVED = "maas-loader/researchPoints/UPDATE_RECEIVED";
const UPDATE_RESEARCH_POINTS_REQUESTED =
  "maas-loader/researchPoints/UPDATE_RESEARCH_POINTS_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { researchPoints } = action;
      return researchPoints;
    }
    case UPDATE_RECEIVED: {
      const { researchPoints } = action;
      return researchPoints;
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(researchPoints) {
  return { type: FETCH_SUCCEEDED, researchPoints };
}

export function updateReceived(researchPoints) {
  return { type: UPDATE_RECEIVED, researchPoints };
}

export function requestUpdateResearchPoints(researchPoints) {
  return { type: UPDATE_RESEARCH_POINTS_REQUESTED, researchPoints };
}

function* fetchResearchPoints() {
  try {
    const researchPointsResponse = yield call(() =>
      axios.get("/api/v1/research_points/1")
    );
    yield put(fetchSucceeded(researchPointsResponse.data.points));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchResearchPoints() {
  yield takeLatest(FETCH_REQUESTED, fetchResearchPoints);
}

function* updateResearchPoints(action) {
  const { researchPoints } = action;
  try {
    yield call(() =>
      axios.put("/api/v1/research_points/1", { points: researchPoints })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateResearchPoints() {
  yield takeEvery(UPDATE_RESEARCH_POINTS_REQUESTED, updateResearchPoints);
}
