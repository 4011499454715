import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { createSelector } from "reselect";
import { getShipId, setUpdateSucceeded, setUpdateFailed } from "./display";

const getCrew = (state) => Object.values(state.crew);

export const getCrewById = (state, crewId) =>
  state.crew ? state.crew[crewId] : {};

export const getCrewForShip = createSelector(
  [getShipId, getCrew],
  (shipId, crew) => {
    return crew.filter((crewMember) => {
      return crewMember.ship_id === shipId;
    });
  }
);

export const getCrewForShipId = (state, shipId) => {
  const crew = getCrew(state);
  return crew.filter((crewMember) => {
    return crewMember.ship_id === shipId;
  });
};

export const getSecurityCrewForShip = createSelector(
  [getShipId, getCrew],
  (shipId, crew) => {
    return crew.filter((crewMember) => {
      return crewMember.ship_id === shipId && crewMember.station_id === null;
    });
  }
);

export const getSecurityCrewForShipById = (state, shipId) => {
  const crew = getCrew(state);
  return crew.filter((crewMember) => {
    return crewMember.ship_id === shipId && crewMember.station_id === null;
  });
};

export const getShipSecurityForRiotSuppression = createSelector(
  [getSecurityCrewForShip],
  (crew) => {
    return crew.filter((crewMember) => {
      return ["Normal", "Wounded"].includes(crewMember.status);
    });
  }
);

export const getCrewForStation = (state, stationId) => {
  const crew = getCrew(state);
  return crew.filter((crewMember) => {
    return crewMember.station_id === parseInt(stationId, 10);
  });
};

export const getUsableCrewForStation = (state, stationId) => {
  const crew = getCrewForStation(state, stationId);
  return crew.filter((crewMember) => {
    return crewMember.status === "Normal";
  });
};

export const getUsableCrewForTransaction = (state, stationId, role, status) => {
  const crew = getCrewForStation(state, stationId);
  return crew.filter((crewMember) => {
    if (crewMember.status === status) {
      return role === "Untrained" || crewMember.role === role;
    }
    return false;
  });
};

export const getWoundedCrewAtStation = (state, stationId) => {
  const crew = getCrewForStation(state, stationId);
  return crew.filter((crewMember) => {
    return ["Wounded", "Wounded-Tired"].includes(crewMember.status);
  });
};

export const getDamagedSynthsAtStation = (state, stationId) => {
  const crew = getCrewForStation(state, stationId);
  return crew.filter((crewMember) => {
    return (
      ["Wounded", "Wounded-Tired"].includes(crewMember.status) &&
      crewMember.role === "Synth"
    );
  });
};

export const getTiredSynthsAtStation = (state, stationId) => {
  const crew = getCrewForStation(state, stationId);
  return crew.filter((crewMember) => {
    return crewMember.status === "Tired" && crewMember.role === "Synth";
  });
};

export const getCrewForShuttle = (state, shuttleId) => {
  const crew = getCrew(state);
  return crew.filter((crewMember) => {
    return crewMember.shuttle_id === shuttleId;
  });
};

export const getTransferableCrewForShip = (state, shipId) => {
  const crew = getCrewForShipId(state, shipId);
  return crew.filter(
    (crewMember) =>
      crewMember.may_offload &&
      (crewMember.role === "Synth" ||
        !["Tired", "Wounded-Tired"].includes(crewMember.status))
  );
};

const FETCH_REQUESTED = "maas-loader/crew/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/crew/FETCH_SUCCEEDED";
const TRANSFER_STATION_REQUESTED =
  "maas-loader/crew/TRANSFER_STATION_REQUESTED";
const UPDATE_RECEIVED = "maas-loader/crew/UPDATE_RECEIVED";
const COMMIT_TO_JUMP_DRIVE_REQUESTED =
  "maas-loader/crew/COMMIT_TO_JUMP_DRIVE_REQUESTED";
const UPDATE_TRANSFERABILITY_REQUESTED =
  "maas-loader/crew/UPDATE_TRANSFERABILITY_REQUESTED";
const TRANSFER_TO_SHUTTLE_REQUESTED =
  "maas-loader/crew/TRANSFER_TO_SHUTTLE_REQUESTED";
const TRANSFER_TO_SHIP_REQUESTED =
  "maas-loader/crew/TRANSFER_TO_SHIP_REQUESTED";
const COMMIT_TO_WEAPON_BATTERIES_REQUESTED =
  "maas-loader/crew/COMMIT_TO_WEAPON_BATTERIES_REQUESTED";
const COMMIT_TO_HANGAR_BAY_REQUESTED =
  "maas-loader/crew/COMMIT_TO_HANGAR_BAY_REQUESTED";
const UPDATE_CREW_VALUES_REQUESTED =
  "maas-loader/crew/UPDATE_CREW_VALUES_REQUESTED";
const DELETE_RECEIVED = "maas-loader/crew/DELETE_RECEIVED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { crew } = action;
      return crew;
    }
    // In real-time, move the crew member to the new station. Rely on saga to send the update to the server.
    case TRANSFER_STATION_REQUESTED: {
      const { crewId, destStationId } = action;
      return {
        ...state,
        [crewId]: { ...state[crewId], station_id: parseInt(destStationId, 10) },
      };
    }
    case UPDATE_RECEIVED: {
      const { crew } = action;
      return { ...state, [crew.id]: crew };
    }
    case TRANSFER_TO_SHUTTLE_REQUESTED: {
      const { crewId, shuttleId } = action;
      return {
        ...state,
        [crewId]: {
          ...state[crewId],
          station_id: null,
          ship_id: null,
          shuttle_id: shuttleId,
        },
      };
    }
    case TRANSFER_TO_SHIP_REQUESTED: {
      const { crewId, shipId, stationId } = action;
      return {
        ...state,
        [crewId]: {
          ...state[crewId],
          station_id: stationId,
          ship_id: shipId,
          shuttle_id: null,
        },
      };
    }
    case DELETE_RECEIVED: {
      const { crew } = action;
      const { [crew.id]: _, ...rest } = state;
      return rest;
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(crew) {
  return { type: FETCH_SUCCEEDED, crew };
}

export function requestTransferStation(crewId, destStationId) {
  return {
    type: TRANSFER_STATION_REQUESTED,
    crewId,
    destStationId,
  };
}

export function updateReceived(crew) {
  return { type: UPDATE_RECEIVED, crew };
}

export function requestCommitToJumpDrive(crewId) {
  return {
    type: COMMIT_TO_JUMP_DRIVE_REQUESTED,
    crewId,
  };
}

export function requestUpdateTransferability(crewId, allowed) {
  return { type: UPDATE_TRANSFERABILITY_REQUESTED, crewId, allowed };
}

export function requestTransferToShuttle(crewId, shuttleId) {
  return { type: TRANSFER_TO_SHUTTLE_REQUESTED, crewId, shuttleId };
}

export function requestTransferToShip(crewId, shipId, stationId) {
  return { type: TRANSFER_TO_SHIP_REQUESTED, crewId, shipId, stationId };
}

export function requestCommitToWeaponBatteries(crewId, stationId) {
  return { type: COMMIT_TO_WEAPON_BATTERIES_REQUESTED, crewId, stationId };
}

export function requestCommitToHangarBay(crewIds, stationId) {
  return { type: COMMIT_TO_HANGAR_BAY_REQUESTED, crewIds, stationId };
}

export function requestUpdateCrewValues(crewId, values) {
  return { type: UPDATE_CREW_VALUES_REQUESTED, crewId, values };
}

export function deleteReceived(crew) {
  return { type: DELETE_RECEIVED, crew };
}

function* fetchCrew() {
  try {
    const crewResponse = yield call(() =>
      axios.get("/api/v1/crew/?limit=1000")
    );
    const crew = {};
    Object.values(crewResponse.data).forEach((crewMember) => {
      crew[crewMember.id] = crewMember;
    });
    yield put(fetchSucceeded(crew));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchCrew() {
  yield takeLatest(FETCH_REQUESTED, fetchCrew);
}

function* transferStation(action) {
  const { crewId, destStationId } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/crew/${crewId}`, {
        station_id: destStationId,
      })
    );
  } catch (e) {
    // TODO: Move crew back to original location
  }
}

export function* watchTransferStation() {
  yield takeEvery(TRANSFER_STATION_REQUESTED, transferStation);
}

function* commitToJumpDrive(action) {
  const { crewId } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/crew/${crewId}/commit_to_jump_drive`, {})
    );
    yield put(setUpdateSucceeded());
  } catch (e) {
    yield put(setUpdateFailed(e.message));
  }
}

export function* watchCommitToJumpDrive() {
  yield takeEvery(COMMIT_TO_JUMP_DRIVE_REQUESTED, commitToJumpDrive);
}

function* updateTransferability(action) {
  const { crewId, allowed } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/crew/${crewId}`, {
        may_offload: allowed,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateTransferability() {
  yield takeEvery(UPDATE_TRANSFERABILITY_REQUESTED, updateTransferability);
}

function* transferToShuttle(action) {
  const { crewId, shuttleId } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/crew/${crewId}/transfer_to_shuttle/${shuttleId}`, {})
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchTransferToShuttle() {
  yield takeEvery(TRANSFER_TO_SHUTTLE_REQUESTED, transferToShuttle);
}

function* transferToShip(action) {
  const { crewId, shipId } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/crew/${crewId}/transfer_to_ship/${shipId}`, {})
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchTransferToShip() {
  yield takeEvery(TRANSFER_TO_SHIP_REQUESTED, transferToShip);
}

function* commitToWeaponBatteries(action) {
  const { crewId, stationId } = action;
  try {
    yield call(() =>
      axios.put(
        `/api/v1/crew/${crewId}/commit_to_weapon_batteries/station/${stationId}`,
        {}
      )
    );
    yield put(setUpdateSucceeded());
  } catch (e) {
    yield put(setUpdateFailed(e.message));
  }
}

export function* watchCommitToWeaponBatteries() {
  yield takeEvery(
    COMMIT_TO_WEAPON_BATTERIES_REQUESTED,
    commitToWeaponBatteries
  );
}

function* commitToHangarBay(action) {
  const { crewIds, stationId } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/crew/commit_to_hangar_bay/station/${stationId}`, {
        crew_ids: crewIds,
      })
    );
    yield put(setUpdateSucceeded());
  } catch (e) {
    yield put(setUpdateFailed(e.message));
  }
}

export function* watchCommitToHangarBay() {
  yield takeEvery(COMMIT_TO_HANGAR_BAY_REQUESTED, commitToHangarBay);
}

function* updateCrewValues(action) {
  const { crewId, values } = action;
  try {
    yield call(() => axios.put(`/api/v1/crew/${crewId}`, values));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateCrewValues() {
  yield takeEvery(UPDATE_CREW_VALUES_REQUESTED, updateCrewValues);
}
