import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { createSelector } from "reselect";
import { getShipId } from "./display";

const getActionCards = (state) => Object.values(state.actionCards);

export const getActionCardsForActiveShip = createSelector(
  [getShipId, getActionCards],
  (shipId, actionCards) => {
    return actionCards.filter((actionCard) => actionCard.ship_id === shipId);
  }
);

export const getActionCard = (state, actionCardId) =>
  state.actionCards ? state.actionCards[actionCardId] : null;

const FETCH_REQUESTED = "maas-loader/actionCard/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/actionCard/FETCH_SUCCEEDED";
const UPDATE_RECEIVED = "maas-loader/actionCard/UPDATE_RECEIVED";
const UPDATE_TEXT_REQUESTED = "maas-loader/actionCard/UPDATE_TEXT_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { actionCards } = action;
      return actionCards;
    }
    case UPDATE_RECEIVED: {
      const { actionCard } = action;
      return { ...state, [actionCard.id]: actionCard };
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(actionCards) {
  return { type: FETCH_SUCCEEDED, actionCards };
}

export function updateReceived(actionCard) {
  return { type: UPDATE_RECEIVED, actionCard };
}

export function requestTextUpdate(actionCardId, text1, text2) {
  return { type: UPDATE_TEXT_REQUESTED, actionCardId, text1, text2 };
}

function* fetchActionCards() {
  try {
    const actionCardsResponse = yield call(() =>
      axios.get("/api/v1/simple_action_card/")
    );
    const actionCards = {};
    Object.values(actionCardsResponse.data).forEach((actionCard) => {
      actionCards[actionCard.id] = actionCard;
    });
    yield put(fetchSucceeded(actionCards));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchActionCards() {
  yield takeLatest(FETCH_REQUESTED, fetchActionCards);
}

function* updateText(action) {
  const { actionCardId, text1, text2 } = action;
  try {
    yield call(() =>
      axios.put(`/api/v1/simple_action_card/${actionCardId}`, {
        text_1: text1,
        text_2: text2,
      })
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchUpdateText() {
  yield takeEvery(UPDATE_TEXT_REQUESTED, updateText);
}
