import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DragDropContext } from "react-beautiful-dnd";
import DiceRow from "views/components/ship/battlemap/DiceRow";
import ShipRow from "views/components/ship/battlemap/ShipRow";
import AegisToken from "views/components/ship/battlemap/AegisToken";
import SmallCraftPanel from "views/components/ship/battlemap/SmallCraftPanel";
import PursuitTrack from "views/components/ship/battlemap/PursuitTrack";
import QuadrantLabel from "views/components/ship/battlemap/QuadrantLabel";
import ShipControl from "views/components/ship/battlemap/controlPanels/ShipControl";
import FighterControl from "views/components/ship/battlemap/controlPanels/FighterControl";
import WolfUnitControl from "views/components/ship/battlemap/controlPanels/WolfUnitControl";
import CombatLog from "views/components/ship/battlemap/CombatLog";
import FacilitatorButtons from "views/components/ship/battlemap/FacilitatorButtons";
import { getBattlemapShips, getShips, requestMoveShip } from "store/ducks/ship";
import { activeUserIsFacilitator, activeUserIsNews } from "store/ducks/user";
import { getActivePlayer } from "store/ducks/player";
import { getSelectedItem } from "store/ducks/display";
import { requestMoveFighter } from "store/ducks/fighter";
import { requestMoveWolfUnit } from "store/ducks/wolfUnit";
import { getInWolfAttack, getWolfAttackPhase } from "store/ducks/gameTurn";

function Battlemap() {
  const dispatch = useDispatch();

  const { neQuadrant, nwQuadrant, seQuadrant, swQuadrant } = useSelector(
    getBattlemapShips,
    shallowEqual
  );
  const ships = useSelector(getShips, shallowEqual);
  const player = useSelector(getActivePlayer, shallowEqual);
  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);
  const isNews = useSelector(activeUserIsNews, shallowEqual);
  const selectedItem = useSelector(getSelectedItem, shallowEqual);
  const inWolfAttack = useSelector(getInWolfAttack, shallowEqual);
  const wolfAttackPhase = useSelector(getWolfAttackPhase, shallowEqual);

  const onDragEnd = async (result) => {
    if (
      result.destination &&
      result.reason === "DROP" &&
      result.destination.droppableId !== result.source.droppableId
    ) {
      if (result.type === "SHIP") {
        const slot = result.destination.droppableId;
        const shipId = parseInt(result.draggableId.split("-")[1], 10);
        const quadrant = slot.split("-")[0];
        const position = parseInt(slot.split("-")[1], 10);
        let quadrantShips = {};
        switch (quadrant) {
          case "NE":
            quadrantShips = neQuadrant;
            break;
          case "NW":
            quadrantShips = nwQuadrant;
            break;
          case "SE":
            quadrantShips = seQuadrant;
            break;
          case "SW":
            quadrantShips = swQuadrant;
            break;
          default:
        }
        const shipSize = ships.find(
          (ship) => ship.id === shipId
        ).battle_map_size;

        if (shipSize === 1) {
          // Size 1 ships can go anywhere - allow it
          dispatch(requestMoveShip(shipId, quadrant, position));
        } else {
          switch (position) {
            case 1:
            case 2:
              // Check that both slots are empty
              if (quadrantShips[1] || quadrantShips[2]) {
                return;
              }
              // Both slots empty - move to the first one
              dispatch(requestMoveShip(shipId, quadrant, 1));
              break;
            case 3:
            case 4:
              // Check that both slots are empty
              if (quadrantShips[3] || quadrantShips[4]) {
                return;
              }
              // Both slots empty - move to the first one
              dispatch(requestMoveShip(shipId, quadrant, 3));
              break;
            case 5:
              // Trying to fit a size-2 ship in a size-1 slot - fail
              break;
            default:
          }
        }
      } else if (result.type === "SMALLCRAFT") {
        const quadrant = result.destination.droppableId;
        if (result.draggableId.startsWith("fighter")) {
          const fighterId = parseInt(result.draggableId.split("-")[1], 10);
          dispatch(requestMoveFighter(fighterId, quadrant, inWolfAttack));
        } else if (result.draggableId.startsWith("wolfUnit")) {
          const wolfUnitId = parseInt(result.draggableId.split("-")[1], 10);
          dispatch(requestMoveWolfUnit(wolfUnitId, quadrant));
        }
      }
    }
  };

  return (
    <Container
      fluid
      className="h-100 w-100 d-flex align-items-center position-relative"
    >
      <div className="stars" />
      <div className="twinkling" />
      <Container
        fluid
        className="w-100 h-100 position-absolute d-flex align-items-start justify-content-center mb-2"
        style={{ top: 0, left: 0, maxHeight: "5%" }}
      >
        <PursuitTrack />
      </Container>
      {(isFacilitator || isNews || (player && player.has_battlemap_access)) && (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Container
              fluid
              className="h-75 w-75 d-flex justify-content-center align-items-center flex-column"
            >
              <Row className="h-50 w-100 border-bottom">
                <Col className="border-right d-flex flex-column position-relative">
                  <QuadrantLabel quadrant="NW" />
                  <ShipRow ships={nwQuadrant} quadrant="NW" />
                  <DiceRow />
                  <SmallCraftPanel quadrant="NW" fighterNum={1} />
                </Col>
                <Col className="d-flex flex-column position-relative">
                  <QuadrantLabel quadrant="NE" />
                  <ShipRow east ships={neQuadrant} quadrant="NE" />
                  <DiceRow east />
                  <SmallCraftPanel quadrant="NE" fighterNum={2} />
                </Col>
              </Row>
              <Row className="h-50 w-100">
                <Col className="border-right d-flex flex-column align-items-end position-relative">
                  <QuadrantLabel quadrant="SW" />
                  <SmallCraftPanel quadrant="SW" fighterNum={3} />
                  <DiceRow south />
                  <ShipRow ships={swQuadrant} quadrant="SW" />
                </Col>
                <Col className="d-flex flex-column align-items-end position-relative">
                  <QuadrantLabel quadrant="SE" />
                  <SmallCraftPanel quadrant="SE" fighterNum={4} />
                  <DiceRow south east />
                  <ShipRow east ships={seQuadrant} quadrant="SE" />
                </Col>
              </Row>
            </Container>
          </DragDropContext>
          <AegisToken />
          <Container
            fluid
            className="w-100 h-100 position-absolute d-flex align-items-end mb-2"
            style={{ bottom: 0, left: 0, maxHeight: "5%" }}
          >
            {selectedItem && selectedItem.itemType === "ship" && (
              <ShipControl shipId={selectedItem.itemId} />
            )}
            {selectedItem && selectedItem.itemType === "fighter" && (
              <FighterControl fighterId={selectedItem.itemId} />
            )}
            {selectedItem && selectedItem.itemType === "wolfunit" && (
              <WolfUnitControl wolfUnitId={selectedItem.itemId} />
            )}
          </Container>
          <Container
            fluid
            className="w-100 h-100 position-absolute d-flex flex-column"
            style={{ right: 0, top: 0, maxWidth: "12%" }}
          >
            {isFacilitator && <FacilitatorButtons />}
            {inWolfAttack && (
              <h4 className="text-light font-weight-bold text-center pt-2">
                Attack Phase: {wolfAttackPhase}
              </h4>
            )}
            <CombatLog />
          </Container>
        </>
      )}
    </Container>
  );
}

export default Battlemap;
