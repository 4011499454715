import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import crew, {
  watchFetchCrew,
  watchTransferStation,
  watchCommitToJumpDrive,
  watchUpdateTransferability,
  watchTransferToShip,
  watchTransferToShuttle,
  watchCommitToWeaponBatteries,
  watchCommitToHangarBay,
  watchUpdateCrewValues,
} from "./crew";
import station, {
  watchFetchStations,
  watchRepairStation,
  watchSuppressRioters,
  watchUpdateStationValues,
} from "./station";
import loadingDock, {
  watchFetchLoadingDock,
  watchTransferToLoadingDock,
  watchTransferFromLoadingDock,
} from "./loadingDock";
import ship, {
  watchFetchShip,
  watchUpdateJumpCoordinates,
  watchEngageJumpDrive,
  watchMoveShip,
  watchUpdateLoadingDockAccess,
  watchUpdateRationSetting,
  watchDamageShip,
  watchAddBoarder,
  watchRemoveBoarder,
  watchRepelBoardingUnit,
  watchUpdateNewsMoraleModifier,
  watchRollBoarderDamage,
  watchUpdateResource,
  watchUpdateMiscMoraleModifier,
  watchJumpShips,
} from "./ship";
import cost, { watchFetchCosts } from "./cost";
import display from "./display";
import transaction, {
  watchFetchTransactions,
  watchCommitTransaction,
  watchCreateTransaction,
} from "./transaction";
import transactionRequirement, {
  watchFetchTransactionRequirements,
  watchUpdateTransactionRequirementValues,
  watchDeleteTransactionRequirement,
  watchCreateTransactionRequirement,
} from "./transactionRequirement";
import transactionEffect, {
  watchFetchTransactionEffects,
  watchUpdateTransactionEffectValues,
  watchDeleteTransactionEffect,
  watchCreateTransactionEffect,
} from "./transactionEffect";
import gameTurn, {
  watchFetchGameTurn,
  watchPauseTimer,
  watchResumeTimer,
  watchRewindTimer,
  watchAdvancePhase,
  watchToggleInWolfAttack,
  watchUpdatePursuitTrackLevel,
  watchAdvanceWolfAttackPhase,
} from "./gameTurn";
import researchPoints, {
  watchFetchResearchPoints,
  watchUpdateResearchPoints,
} from "./researchPoints";
import shuttle, {
  watchFetchShuttles,
  watchMoveShuttle,
  watchTransferShuttle,
} from "./shuttle";
import actionCard, {
  watchFetchActionCards,
  watchUpdateText,
} from "./actionCard";
import shuttleCargo, {
  watchFetchShuttleCargo,
  watchTransferToShuttleCargo,
  watchTransferFromShuttleCargo,
} from "./shuttleCargo";
import user, {
  watchFetchUsers,
  watchCreateUser,
  watchUpdateUser,
} from "./user";
import player, {
  watchFetchPlayers,
  watchCreatePlayer,
  watchUpdatePlayer,
  watchDeletePlayer,
} from "./player";
import fighter, {
  watchFetchFighters,
  watchMoveFighter,
  watchUpdateFighterStatus,
  watchUpdateFighterUsed,
  watchAttack as watchFighterAttack,
} from "./fighter";
import wolfUnit, {
  watchFetchWolfUnits,
  watchSpawnWolfUnit,
  watchUpdateWolfUnitCurrentHealth,
  watchDeleteWolfUnit,
  watchMoveWolfUnit,
  watchBoard,
  watchAttack as watchWolfUnitAttack,
} from "./wolfUnit";
import combatLogMessage, {
  watchFetchCombatLogMessages,
  watchCreateMessage,
} from "./combatLogMessage";
import { watchOnPageLoad } from "./pageLoad";

export function* rootSaga() {
  yield all([
    watchFetchStations(),
    watchFetchCrew(),
    watchTransferStation(),
    watchFetchShip(),
    watchUpdateJumpCoordinates(),
    watchFetchCosts(),
    watchEngageJumpDrive(),
    watchMoveShip(),
    watchFetchTransactionRequirements(),
    watchFetchTransactionEffects(),
    watchFetchGameTurn(),
    watchCommitToJumpDrive(),
    watchFetchResearchPoints(),
    watchFetchTransactions(),
    watchCommitTransaction(),
    watchRepairStation(),
    watchSuppressRioters(),
    watchFetchLoadingDock(),
    watchFetchShuttles(),
    watchTransferToLoadingDock(),
    watchTransferFromLoadingDock(),
    watchUpdateLoadingDockAccess(),
    watchUpdateTransferability(),
    watchUpdateRationSetting(),
    watchFetchActionCards(),
    watchUpdateText(),
    watchFetchShuttleCargo(),
    watchTransferToShuttleCargo(),
    watchTransferFromShuttleCargo(),
    watchTransferToShip(),
    watchTransferToShuttle(),
    watchMoveShuttle(),
    watchFetchUsers(),
    watchFetchPlayers(),
    watchTransferShuttle(),
    watchPauseTimer(),
    watchResumeTimer(),
    watchRewindTimer(),
    watchAdvancePhase(),
    watchCommitToWeaponBatteries(),
    watchCommitToHangarBay(),
    watchFetchFighters(),
    watchMoveFighter(),
    watchUpdateFighterStatus(),
    watchUpdateFighterUsed(),
    watchToggleInWolfAttack(),
    watchFetchWolfUnits(),
    watchSpawnWolfUnit(),
    watchUpdateWolfUnitCurrentHealth(),
    watchDeleteWolfUnit(),
    watchMoveWolfUnit(),
    watchUpdatePursuitTrackLevel(),
    watchAdvanceWolfAttackPhase(),
    watchBoard(),
    watchWolfUnitAttack(),
    watchDamageShip(),
    watchAddBoarder(),
    watchRemoveBoarder(),
    watchFetchCombatLogMessages(),
    watchCreateMessage(),
    watchFighterAttack(),
    watchRepelBoardingUnit(),
    watchUpdateNewsMoraleModifier(),
    watchRollBoarderDamage(),
    watchUpdateResource(),
    watchUpdateResearchPoints(),
    watchUpdateStationValues(),
    watchUpdateCrewValues(),
    watchUpdateMiscMoraleModifier(),
    watchJumpShips(),
    watchCreateUser(),
    watchUpdateUser(),
    watchCreatePlayer(),
    watchUpdatePlayer(),
    watchDeletePlayer(),
    watchOnPageLoad(),
    watchUpdateTransactionRequirementValues(),
    watchDeleteTransactionRequirement(),
    watchCreateTransactionRequirement(),
    watchUpdateTransactionEffectValues(),
    watchDeleteTransactionEffect(),
    watchCreateTransactionEffect(),
    watchCreateTransaction(),
  ]);
}

export default combineReducers({
  crew,
  stations: station,
  ships: ship,
  display,
  costs: cost,
  transactionRequirements: transactionRequirement,
  transactionEffects: transactionEffect,
  gameTurn,
  researchPoints,
  transactions: transaction,
  loadingDock,
  shuttles: shuttle,
  actionCards: actionCard,
  shuttleCargo,
  users: user,
  players: player,
  fighters: fighter,
  wolfUnits: wolfUnit,
  combatLogMessages: combatLogMessage,
});
