import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { getTransactionEffect } from "store/ducks/transactionEffect";
import { getShipById } from "store/ducks/ship";

function AddOreOrMaterial({ effectId, updateEffectsData }) {
  const effect = useSelector(
    (state) => getTransactionEffect(state, effectId),
    shallowEqual
  );
  const ship = useSelector(
    (state) => getShipById(state, effect.ship_id),
    shallowEqual
  );

  const [selectedOutput, setSelectedOutput] = useState("material");

  useEffect(() => {
    updateEffectsData(effectId, selectedOutput);
  }, [updateEffectsData, effectId, selectedOutput]);

  const handleOutputSelectionUpdated = (event) => {
    if (event.target.checked) {
      setSelectedOutput(event.target.value);
    }
  };

  return (
    <Row>
      <Col>
        <h4>Add Ore or Material</h4>
        <Form>
          <Form.Group>
            <Form.Check
              type="radio"
              label={`Gain ${effect.material_quantity} material: ${
                ship.material
              } -> ${ship.material + effect.material_quantity}`}
              name="outputRadio"
              id="material"
              value="material"
              onChange={handleOutputSelectionUpdated}
              defaultChecked
            />
            <Form.Check
              type="radio"
              label={`Gain ${effect.ore_quantity} ore: ${ship.ore} -> ${
                ship.ore + effect.ore_quantity
              }`}
              name="outputRadio"
              id="ore"
              value="ore"
              onChange={handleOutputSelectionUpdated}
            />
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
}

AddOreOrMaterial.propTypes = {
  effectId: PropTypes.number.isRequired,
  updateEffectsData: PropTypes.func.isRequired,
};

export default AddOreOrMaterial;
