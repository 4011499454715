import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faCheck,
  faBan,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { getActiveShip, requestEngageJumpDrive } from "store/ducks/ship";
import {
  getUpdateSucceeded,
  getErrorMessage,
  clearUpdateStatus,
} from "store/ducks/display";
import { getAllFuelCostsForShip } from "store/ducks/cost";
import {
  getJumpStationForActiveShip,
  getFuelStationForActiveShip,
} from "store/ducks/station";
import fuel from "static/img/icons/fuel.svg";

function JumpControls({ show, onHide }) {
  const dispatch = useDispatch();

  const ship = useSelector(getActiveShip, shallowEqual);
  const fuelCosts = useSelector(getAllFuelCostsForShip, shallowEqual);
  const jumpStation = useSelector(getJumpStationForActiveShip, shallowEqual);
  const fuelStation = useSelector(getFuelStationForActiveShip, shallowEqual);
  const updateSucceeded = useSelector(getUpdateSucceeded, shallowEqual);
  const errorMessage = useSelector(getErrorMessage, shallowEqual);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [safetyOneEnabled, setSafetyOneEnabled] = useState(true);
  const [safetyTwoEnabled, setSafetyTwoEnabled] = useState(true);
  const [safetyThreeEnabled, setSafetyThreeEnabled] = useState(true);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (updateSucceeded === undefined) {
      setShowSuccessAlert(false);
      setShowErrorAlert(false);
      setErrorAlertMessage("");
    }
    if (updateSucceeded !== undefined) {
      setLoading(false);
      if (updateSucceeded !== showSuccessAlert) {
        setShowSuccessAlert(updateSucceeded);
      }
      if (updateSucceeded === showErrorAlert) {
        setShowErrorAlert(!updateSucceeded);
      }
    }
    if (errorMessage !== undefined && errorMessage !== errorAlertMessage) {
      setErrorAlertMessage(errorMessage);
    }
  }, [
    updateSucceeded,
    showSuccessAlert,
    showErrorAlert,
    errorMessage,
    errorAlertMessage,
  ]);

  // If/when this component loads before data is available
  if (!ship || !fuelCosts) {
    return <div />;
  }

  const clearAlert = () => {
    if (updateSucceeded !== undefined) {
      dispatch(clearUpdateStatus());
    }
  };

  const jumpFailureChanceAndReasons = (() => {
    if (
      jumpStation.status === "damaged" &&
      fuelStation &&
      fuelStation.status === "damaged"
    ) {
      return {
        failureChance: 75,
        reasons: [
          "Your jump station is damaged. This adds a 50% chance that your jump will fail.",
          "Your fuel storage is damaged. This adds a 25% chance that your jump will fail.",
        ],
      };
    }

    if (jumpStation.status === "damaged") {
      return {
        failureChance: 50,
        reasons: {
          1: "Your jump station is damaged. This adds a 50% chance that your jump will fail.",
        },
      };
    }

    if (fuelStation) {
      if (fuelStation.status === "damaged") {
        return {
          failureChance: 50,
          reasons: {
            1: "Your fuel storage is damaged. This adds a 50% chance that your jump will fail.",
          },
        };
      }
    }

    return { failureChance: 0, reasons: {} };
  })();

  const safetiesDisabled = () =>
    !safetyOneEnabled && !safetyTwoEnabled && !safetyThreeEnabled;

  const onCloseHandler = () => {
    clearAlert();
    setSafetyOneEnabled(true);
    setSafetyTwoEnabled(true);
    setSafetyThreeEnabled(true);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={() => onCloseHandler()}
      size="lg"
      aria-labelledby="jumpControlsModalTitle"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="jumpControlsModalTitle">Jump Controls</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert
          show={showSuccessAlert}
          variant="success"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Jump Drive Engaged</Alert.Heading>
          Please wait for a Facilitator member to contact you in Discord.
        </Alert>
        <Alert
          show={showErrorAlert}
          variant="danger"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Error</Alert.Heading>
          {errorAlertMessage}
        </Alert>

        <Container className="text-center">
          <Row>
            <Col>
              <h4>
                Jump Failure Chance:{" "}
                {jumpFailureChanceAndReasons.failureChance > 0 && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        <ul className="text-left mb-0 pl-4">
                          {Object.keys(jumpFailureChanceAndReasons.reasons).map(
                            (reasonId) => {
                              return (
                                <li key={reasonId}>
                                  {
                                    jumpFailureChanceAndReasons.reasons[
                                      reasonId
                                    ]
                                  }
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </Tooltip>
                    }
                  >
                    <span className="font-weight-bold text-danger">
                      {jumpFailureChanceAndReasons.failureChance}%
                      <sup>
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </sup>
                    </span>
                  </OverlayTrigger>
                )}
                {jumpFailureChanceAndReasons.failureChance === 0 && "0%"}
              </h4>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="d-flex justify-content-center">
              <Table bordered className="table-fit">
                <thead>
                  <tr>
                    <td colSpan="4">
                      Current Fuel: {ship.fuel}{" "}
                      <img src={fuel} title="Fuel" alt="Fuel" height="24" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Range</th>
                    <th scope="col">Short</th>
                    <th scope="col">Medium</th>
                    <th scope="col">Long</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Cost</th>
                    <td>
                      {fuelCosts.short}{" "}
                      <img src={fuel} title="Fuel" alt="Fuel" height="24" />
                    </td>
                    <td>
                      {fuelCosts.medium}{" "}
                      <img src={fuel} title="Fuel" alt="Fuel" height="24" />
                    </td>
                    <td>
                      {fuelCosts.long}{" "}
                      <img src={fuel} title="Fuel" alt="Fuel" height="24" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Available?</th>
                    <td>
                      {ship.fuel >= fuelCosts.short && (
                        <span className="text-success">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      )}
                      {ship.fuel < fuelCosts.short && (
                        <span className="text-danger">
                          <FontAwesomeIcon icon={faBan} />
                        </span>
                      )}
                    </td>
                    <td>
                      {ship.fuel >= fuelCosts.medium && (
                        <span className="text-success">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      )}
                      {ship.fuel < fuelCosts.medium && (
                        <span className="text-danger">
                          <FontAwesomeIcon icon={faBan} />
                        </span>
                      )}
                    </td>
                    <td>
                      {ship.fuel >= fuelCosts.long && (
                        <span className="text-success">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      )}
                      {ship.fuel < fuelCosts.long && (
                        <span className="text-danger">
                          <FontAwesomeIcon icon={faBan} />
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          {ship.fuel < fuelCosts.long && (
            <Row>
              <Col className="text-left">
                <span className="font-weight-bold">Emergency Jump:</span> You
                may perform an emergency jump to complete a jump without
                sufficient fuel. This will disable all stations for the
                following turn, and may have additional effects at
                Facilitator&apos;s discretion.
              </Col>
            </Row>
          )}
          <hr />
          <Row className="d-flex justify-content-center">
            <Col xs={2}>
              <Button
                variant={safetyOneEnabled ? "success" : "danger"}
                onClick={() => setSafetyOneEnabled(!safetyOneEnabled)}
              >
                Safety 1<br />
                {safetyOneEnabled ? "Enabled" : "Disabled"}
              </Button>
            </Col>
            <Col xs={2}>
              <Button
                variant={safetyTwoEnabled ? "success" : "danger"}
                onClick={() => setSafetyTwoEnabled(!safetyTwoEnabled)}
              >
                Safety 2<br />
                {safetyTwoEnabled ? "Enabled" : "Disabled"}
              </Button>
            </Col>
            <Col xs={2}>
              <Button
                variant={safetyThreeEnabled ? "success" : "danger"}
                onClick={() => setSafetyThreeEnabled(!safetyThreeEnabled)}
              >
                Safety 3<br />
                {safetyThreeEnabled ? "Enabled" : "Disabled"}
              </Button>
            </Col>
          </Row>
          <Row className="pt-2">
            <Alert variant="warning" className="d-flex flex-row text-left">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size="3x"
                className="mr-3 align-self-center"
              />
              <div>
                <p>
                  Activating the ship&apos;s jump drive will immediately attempt
                  to jump to the currently set coordinates. Only the Ship&apos;s
                  Captain may view or modify these coordinates.
                </p>
                <p>
                  You do not know the range of the jump without outside
                  information.
                  {ship.fuel < fuelCosts.long && (
                    <span>
                      {" "}
                      If the fuel cost for the range of the jump undertaken
                      exceeds your current fuel levels, it will be considered an
                      &quot;Emergency Jump&quot; (see above).
                    </span>
                  )}
                </p>
                <p>
                  Due to the complexity of this action, fewer checks are being
                  performed in the background. Please ensure you have the ship
                  in the proper configuration.
                </p>
                <p>
                  If you are jumping outside of a planned group jump, please
                  alert Facilitators. Although it may take some time to
                  completely adjudicate the action, you will be committed to the
                  jump AS SOON AS YOU PRESS THE BUTTON BELOW. For this reason,
                  you must disable the above safeties before jumping.
                </p>
                <p className="mb-0">Good luck.</p>
              </div>
            </Alert>
          </Row>
          <Row>
            <Col>
              <Button
                variant={!ship.jump_active ? "danger" : "success"}
                disabled={!safetiesDisabled() || ship.jump_active || isLoading}
                onClick={() => {
                  clearAlert();
                  dispatch(requestEngageJumpDrive(ship.id));
                  setLoading(true);
                }}
              >
                {!ship.jump_active ? "Engage Jump Drive" : "Jump Drive Engaged"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

JumpControls.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default JumpControls;
