import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  getTurnNumber,
  getTurnPhase,
  getTimerPauseTime,
  getTurnEndTime,
} from "store/ducks/gameTurn";
import { activeUserIsFacilitator } from "store/ducks/user";
import TimerControls from "./TimerControls";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function Timer() {
  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);
  const turnNumber = useSelector(getTurnNumber, shallowEqual);
  const turnPhase = useSelector(getTurnPhase, shallowEqual);
  const turnEndTime = useSelector(getTurnEndTime, shallowEqual);
  const timerPauseTime = useSelector(getTimerPauseTime, shallowEqual);

  const calculateTimeLeft = () => {
    const difference = !timerPauseTime
      ? +new Date(turnEndTime) - +new Date()
      : +new Date(turnEndTime) - +new Date(timerPauseTime);
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  });

  const timerValue = Object.keys(timeLeft)
    .map((interval) => timeLeft[interval].toString().padStart(2, "0"))
    .join(":");

  return (
    <>
      {isFacilitator && <TimerControls />}
      <span className="navbar-text text-light timer-text text-nowrap">
        <span className="mx-1 text-nowrap">Turn {turnNumber}</span>{" "}
        <span className="mx-1 text-nowrap">
          {toTitleCase(turnPhase.replace("_", " "))} Phase
        </span>{" "}
        {Object.keys(timeLeft).length > 0 && (
          <span className="mx-1 text-nowrap">{timerValue}</span>
        )}
        {Object.keys(timeLeft).length === 0 && (
          <span className="mx-1 text-nowrap blink">00:00</span>
        )}
      </span>
    </>
  );
}

export default Timer;
