import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Draggable } from "react-beautiful-dnd";
import aegisFighter from "static/img/battlemapTokens/aegisFighter.png";
import icebreakerFighter from "static/img/battlemapTokens/icebreakerFighter.png";
import quellonFighter from "static/img/battlemapTokens/quellonFighter.png";
import dioneFighter from "static/img/battlemapTokens/dioneFighter.png";
import { setSelectedItem, getSelectedItem } from "store/ducks/display";
import { activeUserIsFacilitator } from "store/ducks/user";
import { getActivePlayer } from "store/ducks/player";

function Fighter({ fighterId, name, shipId, status, index, used }) {
  const dispatch = useDispatch();

  const isUserFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);
  const player = useSelector(getActivePlayer, shallowEqual);
  const selectedItem = useSelector(getSelectedItem, shallowEqual);

  const fighterImageByShipId = {
    1: aegisFighter,
    2: icebreakerFighter,
    4: quellonFighter,
    7: dioneFighter,
  };

  const getHealthByStatus = () => {
    if (status === "normal") {
      return 100;
    }
    if (status === "damaged") {
      return 50;
    }
    return 0;
  };

  const isInteractive = () => {
    return isUserFacilitator || (player && player.ship_id === shipId);
  };

  return (
    <Draggable
      draggableId={`fighter-${fighterId}`}
      index={index}
      isDragDisabled={!isInteractive()}
    >
      {(provided) => (
        <Col
          xs="auto"
          ref={provided.innerRef}
          {...provided.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
          {...provided.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
          className="pt-4"
          onClick={
            isInteractive()
              ? () => dispatch(setSelectedItem("fighter", fighterId))
              : () => {}
          }
          onKeyUp={
            isInteractive()
              ? () => dispatch(setSelectedItem("fighter", fighterId))
              : () => {}
          }
          role={isInteractive() ? "button" : ""}
          tabIndex={isInteractive() ? 1 : null}
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip className="battlemap-tooltip">{name}</Tooltip>}
          >
            <div
              className={`border position-relative ${
                selectedItem &&
                selectedItem.itemType === "fighter" &&
                selectedItem.itemId === fighterId
                  ? "border-warning"
                  : "border-dark"
              }`}
            >
              <div className="fighter-token-number">
                {name.split(" ").pop()}
              </div>
              <ProgressBar
                now={getHealthByStatus()}
                variant="success"
                style={{ height: "4px" }}
              />
              <div className={used ? "used-token-overlay" : ""}>
                <div
                  className={
                    status === "destroyed" ? "destroyed-token-overlay" : ""
                  }
                >
                  <img
                    src={fighterImageByShipId[shipId]}
                    style={{ maxHeight: "4vh" }}
                    alt={name}
                  />
                </div>
              </div>
            </div>
          </OverlayTrigger>
        </Col>
      )}
    </Draggable>
  );
}

Fighter.propTypes = {
  fighterId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  shipId: PropTypes.oneOf([1, 2, 4, 7]).isRequired,
  status: PropTypes.oneOf(["normal", "damaged", "destroyed"]).isRequired,
  index: PropTypes.number.isRequired,
  used: PropTypes.bool.isRequired,
};

export default Fighter;
