import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "./App.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavBar from "./views/components/NavBar";
import PlayerShip from "./views/pages/PlayerShip";
import Login from "./views/pages/Login";
import Shuttle from "./views/pages/tabs/player/ship/Shuttle";
import Battlemap from "./views/pages/tabs/player/ship/Battlemap";
import JumpStatus from "./views/pages/facilitators/JumpStatus";
import Resources from "./views/pages/facilitators/Resources";
import Users from "./views/pages/facilitators/Users";
import { updateReceived as stationUpdateReceived } from "./store/ducks/station";
import {
  updateReceived as crewUpdateReceived,
  deleteReceived as crewDeleteReceived,
} from "./store/ducks/crew";
import { updateReceived as loadingDockUpdateReceived } from "./store/ducks/loadingDock";
import { updateReceived as shipUpdateReceived } from "./store/ducks/ship";
import { updateReceived as gameTurnUpdateReceived } from "./store/ducks/gameTurn";
import { updateReceived as researchPointsUpdateReceived } from "./store/ducks/researchPoints";
import { updateReceived as shuttleUpdateReceived } from "./store/ducks/shuttle";
import { updateReceived as actionCardUpdateReceived } from "./store/ducks/actionCard";
import { updateReceived as shuttleCargoUpdateReceived } from "./store/ducks/shuttleCargo";
import {
  updateReceived as transactionUpdateReceived,
  deleteReceived as transactionDeleteReceived,
} from "./store/ducks/transaction";
import {
  updateReceived as transactionRequirementUpdateReceived,
  deleteReceived as transactionRequirementDeleteReceived,
} from "./store/ducks/transactionRequirement";
import {
  updateReceived as transactionEffectUpdateReceived,
  deleteReceived as transactionEffectDeleteReceived,
} from "./store/ducks/transactionEffect";
import {
  updateReceived as userUpdateReceived,
  activeUserIsFacilitator,
  activeUserIsNews,
} from "./store/ducks/user";
import { getDiscordName, getLoadingData } from "./store/ducks/display";
import {
  updateReceived as playerUpdateReceived,
  deleteReceived as playerDeleteReceived,
  getActivePlayer,
} from "./store/ducks/player";
import { updateReceived as fighterUpdateReceived } from "./store/ducks/fighter";
import {
  updateReceived as wolfUnitUpdateReceived,
  deleteReceived as wolfUnitDeleteReceived,
} from "./store/ducks/wolfUnit";
import { updateReceived as combatLogMessageUpdateReceived } from "./store/ducks/combatLogMessage";
import { onPageLoad } from "./store/ducks/pageLoad";
import websockets from "./websockets";

function App() {
  const dispatch = useDispatch();

  const discordName = useSelector(getDiscordName, shallowEqual);
  const userIsFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);
  const userIsNews = useSelector(activeUserIsNews, shallowEqual);
  const activePlayer = useSelector(getActivePlayer, shallowEqual);
  const loading = useSelector(getLoadingData, shallowEqual);
  const [cookies, , removeCookie] = useCookies(["jwt", "session"]);

  useEffect(() => {
    if (cookies.jwt) {
      dispatch(onPageLoad());

      websockets.updateRecord((data) => {
        switch (data.type) {
          case "Crew": {
            dispatch(crewUpdateReceived(data.data));
            break;
          }
          case "Ship": {
            dispatch(shipUpdateReceived(data.data));
            break;
          }
          case "Station": {
            dispatch(stationUpdateReceived(data.data));
            break;
          }
          case "GameTurn": {
            dispatch(gameTurnUpdateReceived(data.data));
            break;
          }
          case "ResearchPoints": {
            dispatch(researchPointsUpdateReceived(data.data.points));
            break;
          }
          case "LoadingDock": {
            dispatch(loadingDockUpdateReceived(data.data));
            break;
          }
          case "SimpleActionCard": {
            dispatch(actionCardUpdateReceived(data.data));
            break;
          }
          case "ShuttleCargo": {
            dispatch(shuttleCargoUpdateReceived(data.data));
            break;
          }
          case "Shuttle": {
            dispatch(shuttleUpdateReceived(data.data));
            break;
          }
          case "User": {
            dispatch(userUpdateReceived(data.data));
            break;
          }
          case "Player": {
            dispatch(playerUpdateReceived(data.data));
            break;
          }
          case "Fighter": {
            dispatch(fighterUpdateReceived(data.data));
            break;
          }
          case "WolfUnit": {
            dispatch(wolfUnitUpdateReceived(data.data));
            break;
          }
          case "Transaction": {
            dispatch(transactionUpdateReceived(data.data));
            break;
          }
          case "TransactionRequirement": {
            dispatch(transactionRequirementUpdateReceived(data.data));
            break;
          }
          case "TransactionEffect": {
            dispatch(transactionEffectUpdateReceived(data.data));
            break;
          }
          default:
            break;
        }
      });

      websockets.createRecord((data) => {
        switch (data.type) {
          case "Crew": {
            dispatch(crewUpdateReceived(data.data));
            break;
          }
          case "Ship": {
            dispatch(shipUpdateReceived(data.data));
            break;
          }
          case "Station": {
            dispatch(stationUpdateReceived(data.data));
            break;
          }
          case "GameTurn": {
            dispatch(gameTurnUpdateReceived(data.data));
            break;
          }
          case "LoadingDock": {
            dispatch(loadingDockUpdateReceived(data.data));
            break;
          }
          case "SimpleActionCard": {
            dispatch(actionCardUpdateReceived(data.data));
            break;
          }
          case "ShuttleCargo": {
            dispatch(shuttleCargoUpdateReceived(data.data));
            break;
          }
          case "Shuttle": {
            dispatch(shuttleUpdateReceived(data.data));
            break;
          }
          case "User": {
            dispatch(userUpdateReceived(data.data));
            break;
          }
          case "Player": {
            dispatch(playerUpdateReceived(data.data));
            break;
          }
          case "Fighter": {
            dispatch(fighterUpdateReceived(data.data));
            break;
          }
          case "WolfUnit": {
            dispatch(wolfUnitUpdateReceived(data.data));
            break;
          }
          case "CombatLogMessage": {
            dispatch(combatLogMessageUpdateReceived(data.data));
            break;
          }
          case "Transaction": {
            dispatch(transactionUpdateReceived(data.data));
            break;
          }
          case "TransactionRequirement": {
            dispatch(transactionRequirementUpdateReceived(data.data));
            break;
          }
          case "TransactionEffect": {
            dispatch(transactionEffectUpdateReceived(data.data));
            break;
          }
          default:
            break;
        }
      });

      websockets.deleteRecord((data) => {
        switch (data.type) {
          case "WolfUnit": {
            dispatch(wolfUnitDeleteReceived(data.data));
            break;
          }
          case "Player": {
            dispatch(playerDeleteReceived(data.data));
            break;
          }
          case "Transaction": {
            dispatch(transactionDeleteReceived(data.data));
            break;
          }
          case "TransactionRequirement": {
            dispatch(transactionRequirementDeleteReceived(data.data));
            break;
          }
          case "TransactionEffect": {
            dispatch(transactionEffectDeleteReceived(data.data));
            break;
          }
          case "Crew": {
            dispatch(crewDeleteReceived(data.data));
            break;
          }
          default:
            break;
        }
      });

      websockets.resetStore(() => {
        dispatch(onPageLoad());
      });

      websockets.deauth(() => {
        removeCookie("jwt", { path: "/" });
        window.location.replace("/api/v1/discord/login");
      });
    }
  }, [dispatch, cookies.jwt, removeCookie]);

  if (loading) {
    return (
      <Container fluid className="h-100 w-100">
        <Row className="h-100 w-100 align-items-center">
          <Col className="justify-content-center">
            <h1 className="display-1 text-center">Loading...</h1>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!discordName) {
    return (
      <Route>
        <Login />
      </Route>
    );
  }

  return (
    <>
      <NavBar />
      <Switch>
        <Route exact path="/">
          <Redirect to="/ship" />
        </Route>
        <Route path="/ship/:shipId">
          <PlayerShip />
        </Route>
        <Route exact path="/ship">
          {userIsFacilitator && <Redirect to="/ship/1" />}
          {!userIsFacilitator && activePlayer && (
            <Redirect to={`/ship/${activePlayer.ship_id}`} />
          )}
          {userIsNews && <Redirect to="/battlemap" />}
        </Route>
        <Route path="/shuttle/:shuttleId">
          <Shuttle />
        </Route>
        <Route path="/battlemap">
          <Battlemap />
        </Route>
        <Route path="/jump">
          <JumpStatus />
        </Route>
        <Route path="/resources">
          <Resources />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
      </Switch>
    </>
  );
}

export default App;
