import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { getActionCard, requestTextUpdate } from "store/ducks/actionCard";
import { getActiveShip } from "store/ducks/ship";

function ActionCard({ actionCardId }) {
  const dispatch = useDispatch();

  const actionCard = useSelector(
    (state) => getActionCard(state, actionCardId),
    shallowEqual
  );
  const ship = useSelector(getActiveShip, shallowEqual);

  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");

  useEffect(() => {
    if (actionCard) {
      setText1(actionCard.text_1);
      setText2(actionCard.text_2);
    }
  }, [actionCard]);

  if (!actionCard || !ship) {
    return <div />;
  }

  return (
    <Col xs={12} sm={6} md={4} xl={3}>
      <div className="bg-dark m-4 p-4 rounded">
        <img
          src={require(`static/img/actionCards/${ship.name}/${actionCard.image}.PNG`)} // eslint-disable-line global-require, import/no-dynamic-require
          alt="Action Card"
          className="mb-4"
          style={{ maxWidth: "10vw" }}
        />
        <FormControl
          as="textarea"
          rows={3}
          onChange={(e) => setText1(e.target.value)}
          value={text1}
        />
        <FormControl
          as="textarea"
          rows={3}
          onChange={(e) => setText2(e.target.value)}
          value={text2}
        />
        <Button
          variant="secondary"
          onClick={() =>
            dispatch(requestTextUpdate(actionCardId, text1, text2))
          }
        >
          Save
        </Button>
      </div>
    </Col>
  );
}

ActionCard.propTypes = {
  actionCardId: PropTypes.number.isRequired,
};

export default ActionCard;
