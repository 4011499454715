import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faArrowsAltH,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams, useHistory } from "react-router-dom";
import NumberSelector from "views/components/NumberSelector";
import CrewDisplay from "views/components/CrewDisplay";
import CrewListEntry from "views/components/ship/stations/CrewListEntry";
import food from "static/img/icons/food.svg";
import water from "static/img/icons/water.svg";
import ore from "static/img/icons/ore.svg";
import fuel from "static/img/icons/fuel.svg";
import material from "static/img/icons/material.svg";
import {
  requestMoveShuttle,
  getActivePlayerShuttle,
  getPlayersWithoutShuttles,
  getShuttle,
  requestTransferShuttleOwnership,
} from "store/ducks/shuttle";
import { getShipById, getEnabledShips, getShips } from "store/ducks/ship";
import {
  getShuttleCargoForShuttle,
  requestTransferToShuttleCargo,
  requestTransferFromShuttleCargo,
} from "store/ducks/shuttleCargo";
import { getLoadingDockForShip } from "store/ducks/loadingDock";
import {
  getTransferableCrewForShip,
  getCrewForShuttle,
  requestTransferToShip,
  requestTransferToShuttle,
} from "store/ducks/crew";
import { getInitialStationForShip } from "store/ducks/station";
import { activeUserIsFacilitator } from "store/ducks/user";
import { getTurnPhase, getInWolfAttack } from "store/ducks/gameTurn";
import { setShuttleId } from "store/ducks/display";
import { getPlayerById } from "store/ducks/player";

// TODO: Support for custom cargo

function Shuttle() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { shuttleId } = useParams();

  const isActiveUserFacilitator = useSelector(
    activeUserIsFacilitator,
    shallowEqual
  );
  const shuttle = useSelector((state) => {
    if (isActiveUserFacilitator) {
      return getShuttle(state, shuttleId);
    }
    return getActivePlayerShuttle(state);
  }, shallowEqual);
  const shuttleCargo = useSelector(
    (state) => (shuttle ? getShuttleCargoForShuttle(state, shuttle.id) : {}),
    shallowEqual
  );
  const loadingDock = useSelector(
    (state) => (shuttle ? getLoadingDockForShip(state, shuttle.ship_id) : {}),
    shallowEqual
  );
  const currentShip = useSelector(
    (state) => (shuttle ? getShipById(state, shuttle.ship_id) : {}),
    shallowEqual
  );
  const enabledShips = useSelector(getEnabledShips, shallowEqual);
  const allShips = useSelector(getShips, shallowEqual);
  const passengers = useSelector(
    (state) => (shuttle ? getCrewForShuttle(state, shuttle.id) : []),
    shallowEqual
  );
  const shipTransferableCrew = useSelector(
    (state) =>
      shuttle ? getTransferableCrewForShip(state, shuttle.ship_id) : [],
    shallowEqual
  );
  const shipInitialStation = useSelector(
    (state) =>
      shuttle ? getInitialStationForShip(state, shuttle.ship_id) : {},
    shallowEqual
  );
  const playersWithoutShuttles = useSelector(
    getPlayersWithoutShuttles,
    shallowEqual
  );
  const turnPhase = useSelector(getTurnPhase, shallowEqual);
  const inWolfAttack = useSelector(getInWolfAttack, shallowEqual);
  const shuttlePilot = useSelector(
    (state) =>
      shuttle && shuttle.pilot_id
        ? getPlayerById(state, shuttle.pilot_id)
        : null,
    shallowEqual
  );

  const [shuttleMoveShipId, setShuttleMoveShipId] = useState(1);
  const [transferOwnershipAllowed, setTransferOwnershipAllowed] =
    useState(false);
  const [shuttleOwnerId, setShuttleOwnerId] = useState(
    playersWithoutShuttles[0].id
  );
  const [foodTransferQuantity, setFoodTransferQuantity] = useState(1);
  const [waterTransferQuantity, setWaterTransferQuantity] = useState(1);
  const [oreTransferQuantity, setOreTransferQuantity] = useState(1);
  const [fuelTransferQuantity, setFuelTransferQuantity] = useState(1);
  const [materialTransferQuantity, setMaterialTransferQuantity] = useState(1);
  const [lockControls, setLockControls] = useState(false);

  useEffect(() => {
    if (shuttle) {
      dispatch(setShuttleId(shuttle.id));
    }

    return () => {
      dispatch(setShuttleId(null));
    };
  }, [dispatch, shuttle]);

  useEffect(() => {
    if (currentShip) {
      setShuttleMoveShipId(currentShip.id);
    } else {
      setShuttleMoveShipId("null");
    }
  }, [currentShip]);

  useEffect(() => {
    if (turnPhase !== "action") {
      setLockControls(true);
    } else if (inWolfAttack) {
      setLockControls(true);
    } else {
      setLockControls(false);
    }
  }, [turnPhase, inWolfAttack]);

  if (!shuttle) {
    return <div />;
  }

  const onDragEnd = async (result) => {
    if (
      result.destination &&
      result.reason === "DROP" &&
      result.destination.droppableId !== result.source.droppableId
    ) {
      switch (result.destination.droppableId) {
        case "SHUTTLE": {
          dispatch(requestTransferToShuttle(result.draggableId, shuttle.id));
          break;
        }
        case "SHIP": {
          const stationId = ["Security", "Marine"].includes(
            passengers.find((crew) => crew.id.toString() === result.draggableId)
              .role
          )
            ? null
            : shipInitialStation.id;
          dispatch(
            requestTransferToShip(
              result.draggableId,
              shuttle.ship_id,
              stationId
            )
          );
          break;
        }
        default:
      }
    }
  };

  return (
    <section className="mb-0 py-4 mx-2">
      <Container fluid>
        <Row>
          <Col>
            <h2 className="text-center text-uppercase">
              Location: {currentShip ? currentShip.name : "Space"}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center text-center">
            <Form>
              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Change shuttle location:
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    as="select"
                    size="lg"
                    value={shuttleMoveShipId}
                    onChange={(e) => setShuttleMoveShipId(e.target.value)}
                  >
                    {enabledShips.map((moveShip) => (
                      <option key={moveShip.id} value={moveShip.id}>
                        {moveShip.name}
                      </option>
                    ))}
                    <option key="null" value="null">
                      Space
                    </option>
                  </Form.Control>
                  <InputGroup.Append>
                    <Button
                      size="lg"
                      disabled={lockControls}
                      onClick={() =>
                        dispatch(
                          requestMoveShuttle(shuttle.id, shuttleMoveShipId)
                        )
                      }
                    >
                      Move
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        {currentShip && !currentShip.loading_dock_access && (
          <div className="d-flex align-items-center justify-content-center">
            <Alert
              variant="danger"
              className="d-flex flex-row text-left align-items-center justify-content-center"
            >
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size="3x"
                className="mr-3 align-self-center"
              />
              <span>Unable to dock with {currentShip.name}</span>
            </Alert>
          </div>
        )}
        <hr />
        <div className="border bg-light rounded">
          <Row>
            <Col>
              <h2 className="text-center text-uppercase">Shuttle Cargo</h2>
            </Col>
          </Row>
          <Row className="text-center">
            <Col className="font-weight-bold d-flex flex-column">
              <div>
                <img src={food} alt="Food" title="Food" height="48" />
              </div>
              <div>Food</div>
              <div>{shuttleCargo[1] || 0}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column">
              <div>
                <img src={water} alt="Water" title="Water" height="48" />
              </div>
              <div>Water</div>
              <div>{shuttleCargo[2] || 0}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column">
              <div>
                <img src={ore} alt="Ore" title="Ore" height="48" />
              </div>
              <div>Ore</div>
              <div>{shuttleCargo[3] || 0}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column">
              <div>
                <img src={fuel} alt="Fuel" title="Fuel" height="48" />
              </div>
              <div>Fuel</div>
              <div>{shuttleCargo[4] || 0}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column">
              <div>
                <img
                  src={material}
                  alt="Material"
                  title="Material"
                  height="48"
                />
              </div>
              <div>Material</div>
              <div>{shuttleCargo[5] || 0}</div>
            </Col>
          </Row>
        </div>
        {currentShip && currentShip.loading_dock_access && (
          <>
            <Row className="text-center my-2">
              <Col>
                <Form inline className="justify-content-center">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <Button
                        size="sm"
                        disabled={
                          foodTransferQuantity > (shuttleCargo[1] || 0) ||
                          lockControls
                        }
                        onClick={() =>
                          dispatch(
                            requestTransferFromShuttleCargo(
                              shuttle.id,
                              shuttle.ship_id,
                              1,
                              foodTransferQuantity
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </Button>
                    </InputGroup.Prepend>
                    <NumberSelector
                      defaultValue={1}
                      index={1}
                      size="sm"
                      start={1}
                      onChange={setFoodTransferQuantity}
                    />
                    <InputGroup.Append>
                      <Button
                        size="sm"
                        disabled={
                          foodTransferQuantity > (loadingDock[1] || 0) ||
                          lockControls
                        }
                        onClick={() =>
                          dispatch(
                            requestTransferToShuttleCargo(
                              shuttle.id,
                              shuttle.ship_id,
                              1,
                              foodTransferQuantity
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </Col>
              <Col>
                <Form inline className="justify-content-center">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <Button
                        size="sm"
                        disabled={
                          waterTransferQuantity > (shuttleCargo[2] || 0) ||
                          lockControls
                        }
                        onClick={() =>
                          dispatch(
                            requestTransferFromShuttleCargo(
                              shuttle.id,
                              shuttle.ship_id,
                              2,
                              waterTransferQuantity
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </Button>
                    </InputGroup.Prepend>
                    <NumberSelector
                      defaultValue={1}
                      index={1}
                      size="sm"
                      start={1}
                      onChange={setWaterTransferQuantity}
                    />
                    <InputGroup.Append>
                      <Button
                        size="sm"
                        disabled={
                          waterTransferQuantity > (loadingDock[2] || 0) ||
                          lockControls
                        }
                        onClick={() =>
                          dispatch(
                            requestTransferToShuttleCargo(
                              shuttle.id,
                              shuttle.ship_id,
                              2,
                              waterTransferQuantity
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </Col>
              <Col>
                <Form inline className="justify-content-center">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <Button
                        size="sm"
                        disabled={
                          oreTransferQuantity > (shuttleCargo[3] || 0) ||
                          lockControls
                        }
                        onClick={() =>
                          dispatch(
                            requestTransferFromShuttleCargo(
                              shuttle.id,
                              shuttle.ship_id,
                              3,
                              oreTransferQuantity
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </Button>
                    </InputGroup.Prepend>
                    <NumberSelector
                      defaultValue={1}
                      index={1}
                      size="sm"
                      start={1}
                      onChange={setOreTransferQuantity}
                    />
                    <InputGroup.Append>
                      <Button
                        size="sm"
                        disabled={
                          oreTransferQuantity > (loadingDock[3] || 0) ||
                          lockControls
                        }
                        onClick={() =>
                          dispatch(
                            requestTransferToShuttleCargo(
                              shuttle.id,
                              shuttle.ship_id,
                              3,
                              oreTransferQuantity
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </Col>
              <Col>
                <Form inline className="justify-content-center">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <Button
                        size="sm"
                        disabled={
                          fuelTransferQuantity > (shuttleCargo[4] || 0) ||
                          lockControls
                        }
                        onClick={() =>
                          dispatch(
                            requestTransferFromShuttleCargo(
                              shuttle.id,
                              shuttle.ship_id,
                              4,
                              fuelTransferQuantity
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </Button>
                    </InputGroup.Prepend>
                    <NumberSelector
                      defaultValue={1}
                      index={1}
                      size="sm"
                      start={1}
                      onChange={setFuelTransferQuantity}
                    />
                    <InputGroup.Append>
                      <Button
                        size="sm"
                        disabled={
                          fuelTransferQuantity > (loadingDock[4] || 0) ||
                          lockControls
                        }
                        onClick={() =>
                          dispatch(
                            requestTransferToShuttleCargo(
                              shuttle.id,
                              shuttle.ship_id,
                              4,
                              fuelTransferQuantity
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </Col>
              <Col>
                <Form inline className="justify-content-center">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <Button
                        size="sm"
                        disabled={
                          materialTransferQuantity > (shuttleCargo[5] || 0) ||
                          lockControls
                        }
                        onClick={() =>
                          dispatch(
                            requestTransferFromShuttleCargo(
                              shuttle.id,
                              shuttle.ship_id,
                              5,
                              materialTransferQuantity
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </Button>
                    </InputGroup.Prepend>
                    <NumberSelector
                      defaultValue={1}
                      index={1}
                      size="sm"
                      start={1}
                      onChange={setMaterialTransferQuantity}
                    />
                    <InputGroup.Append>
                      <Button
                        size="sm"
                        disabled={
                          materialTransferQuantity > (loadingDock[5] || 0) ||
                          lockControls
                        }
                        onClick={() =>
                          dispatch(
                            requestTransferToShuttleCargo(
                              shuttle.id,
                              shuttle.ship_id,
                              5,
                              materialTransferQuantity
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </Col>
            </Row>
            <div className="border bg-light rounded">
              <Row className="text-center">
                <Col className="font-weight-bold d-flex flex-column-reverse">
                  <div>
                    <img src={food} alt="Food" title="Food" height="48" />
                  </div>
                  <div>Food</div>
                  <div>{loadingDock[1] || "0"}</div>
                </Col>
                <Col className="font-weight-bold d-flex flex-column-reverse">
                  <div>
                    <img src={water} alt="Water" title="Water" height="48" />
                  </div>
                  <div>Water</div>
                  <div>{loadingDock[2] || "0"}</div>
                </Col>
                <Col className="font-weight-bold d-flex flex-column-reverse">
                  <div>
                    <img src={ore} alt="Ore" title="Ore" height="48" />
                  </div>
                  <div>Ore</div>
                  <div>{loadingDock[3] || "0"}</div>
                </Col>
                <Col className="font-weight-bold d-flex flex-column-reverse">
                  <div>
                    <img src={fuel} alt="Fuel" title="Fuel" height="48" />
                  </div>
                  <div>Fuel</div>
                  <div>{loadingDock[4] || "0"}</div>
                </Col>
                <Col className="font-weight-bold d-flex flex-column-reverse">
                  <div>
                    <img
                      src={material}
                      alt="Material"
                      title="Material"
                      height="48"
                    />
                  </div>
                  <div>Material</div>
                  <div>{loadingDock[5] || "0"}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="text-center text-uppercase mb-0 mt-2">
                    {currentShip.name} Loading Dock
                  </h2>
                </Col>
              </Row>
            </div>
          </>
        )}
        <hr />
        <Row>
          <Col>
            <h2 className="text-center text-uppercase">Passengers</h2>
          </Col>
        </Row>
        <DragDropContext onDragEnd={onDragEnd}>
          <Row className="d-flex justify-content-center text-center">
            <Col xs={3}>
              <h4>Shuttle Passengers</h4>
              <Droppable droppableId="SHUTTLE">
                {(droppableProvided) => (
                  <ListGroup
                    className="border rounded"
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
                  >
                    {passengers.length > 0 &&
                      passengers.map((crew, index) => {
                        return (
                          <Draggable
                            draggableId={crew.id.toString()}
                            index={index}
                            key={crew.id.toString()}
                            isDragDisabled={
                              (currentShip &&
                                !currentShip.loading_dock_access) ||
                              !currentShip ||
                              lockControls
                            }
                          >
                            {(draggableProvided) => (
                              <ListGroup.Item
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
                                {...draggableProvided.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
                              >
                                <CrewListEntry crew={crew} />
                              </ListGroup.Item>
                            )}
                          </Draggable>
                        );
                      })}
                    {passengers.length === 0 && (
                      <Draggable
                        draggableId="none"
                        index={1}
                        key="none"
                        isDragDisabled
                      >
                        {(draggableProvided) => (
                          <ListGroup.Item
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
                            {...draggableProvided.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
                          >
                            None
                          </ListGroup.Item>
                        )}
                      </Draggable>
                    )}
                    {droppableProvided.placeholder}
                  </ListGroup>
                )}
              </Droppable>
            </Col>
            {currentShip && currentShip.loading_dock_access && (
              <>
                <Col
                  xs={3}
                  className="d-flex flex-column align-items-center justify-content-center"
                >
                  <h2 className="d-flex">
                    <FontAwesomeIcon icon={faArrowsAltH} />
                  </h2>
                </Col>
                <Col xs={3}>
                  <h4>{currentShip.name} Transferable Crew</h4>
                  <Droppable droppableId="SHIP">
                    {(droppableProvided) => (
                      <ListGroup
                        className="border rounded"
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
                      >
                        {shipTransferableCrew.length > 0 &&
                          shipTransferableCrew.map((crew, index) => {
                            return (
                              <Draggable
                                draggableId={crew.id.toString()}
                                index={index}
                                key={crew.id.toString()}
                                isDragDisabled={lockControls}
                              >
                                {(draggableProvided) => (
                                  <ListGroup.Item
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
                                    {...draggableProvided.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
                                  >
                                    <CrewDisplay
                                      role={crew.role}
                                      name={crew.name}
                                      status={crew.status}
                                    />
                                  </ListGroup.Item>
                                )}
                              </Draggable>
                            );
                          })}
                        {shipTransferableCrew.length === 0 && (
                          <Draggable
                            draggableId="none"
                            index={1}
                            key="none"
                            isDragDisabled
                          >
                            {(draggableProvided) => (
                              <ListGroup.Item
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
                                {...draggableProvided.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
                              >
                                None
                              </ListGroup.Item>
                            )}
                          </Draggable>
                        )}
                        {droppableProvided.placeholder}
                      </ListGroup>
                    )}
                  </Droppable>
                </Col>
              </>
            )}
          </Row>
        </DragDropContext>
        <hr />
        <Row>
          <Col className="text-center">
            <h2 className="text-uppercase">Transfer Shuttle Ownership</h2>
            <div className="d-flex align-items-center justify-content-center">
              <Alert
                variant="warning"
                className="d-flex flex-row text-left align-items-center justify-content-center"
              >
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  size="3x"
                  className="mr-3 align-self-center"
                />
                <span>
                  You may transfer the ownership of this shuttle to any other
                  player. Be aware, this is a PERMANENT decision! That player
                  will have full control of the shuttle, may keep it for any
                  length of time, and may transfer it to any other player.
                </span>
              </Alert>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center text-center">
          <p>
            <span className="font-weight-bold">Current shuttle owner:</span>{" "}
            {shuttlePilot
              ? `${
                  allShips.find((ship) => ship.id === shuttlePilot.ship_id).name
                } - ${shuttlePilot.role} - ${shuttlePilot.name}`
              : "Nobody"}
          </p>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center text-center">
            <Form>
              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Change shuttle owner:
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    as="select"
                    onChange={(e) =>
                      setShuttleOwnerId(
                        e.target.value !== "null"
                          ? parseInt(e.target.value, 10)
                          : "null"
                      )
                    }
                  >
                    {playersWithoutShuttles.map((player) => {
                      return (
                        <option key={player.id} value={player.id}>{`${
                          allShips.find((ship) => ship.id === player.ship_id)
                            .name
                        } - ${player.role} - ${player.name}`}</option>
                      );
                    })}
                    {isActiveUserFacilitator && (
                      <option key="null" value="null">
                        Nobody
                      </option>
                    )}
                  </Form.Control>
                  <InputGroup.Append>
                    <Button
                      variant={transferOwnershipAllowed ? "success" : "danger"}
                      onClick={() =>
                        setTransferOwnershipAllowed(!transferOwnershipAllowed)
                      }
                    >
                      Confirm?
                    </Button>
                  </InputGroup.Append>
                  <InputGroup.Append>
                    <Button
                      disabled={!transferOwnershipAllowed || lockControls}
                      onClick={() => {
                        dispatch(
                          requestTransferShuttleOwnership(
                            shuttle.id,
                            shuttleOwnerId !== "null" ? shuttleOwnerId : null
                          )
                        );
                        if (!isActiveUserFacilitator) {
                          history.push("/");
                        }
                      }}
                    >
                      Transfer
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Shuttle;
