import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { getTransactionRequirement } from "store/ducks/transactionRequirement";
import { getShipById } from "store/ducks/ship";

function RefineOre({
  requirementId,
  updateRequirementsMet,
  updateRequirementsData,
}) {
  const requirement = useSelector(
    (state) => getTransactionRequirement(state, requirementId),
    shallowEqual
  );
  const ship = useSelector(
    (state) => getShipById(state, requirement.ship_id),
    shallowEqual
  );

  const [selectedOreAmount, setSelectedOreAmount] = useState("low");

  useEffect(() => {
    if (ship.ore < requirement.low_quantity) {
      updateRequirementsMet(requirementId, false);
    } else {
      updateRequirementsMet(requirementId, true);
      updateRequirementsData(requirementId, selectedOreAmount);
    }
  }, [
    ship,
    requirement,
    requirementId,
    updateRequirementsMet,
    updateRequirementsData,
    selectedOreAmount,
  ]);

  const handleOreSelectionUpdated = (event) => {
    if (event.target.checked) {
      setSelectedOreAmount(event.target.value);
    }
  };

  if (ship.ore < requirement.low_quantity) {
    return (
      <Row>
        <Col>
          <h4>Refine Ore</h4>
          <p>
            Insufficient ore for refinement. Need at least{" "}
            {requirement.low_quantity}, have {ship.ore}.
          </p>
        </Col>
      </Row>
    );
  }
  return (
    <Row>
      <Col>
        <h4>Refine Ore</h4>
        <Form>
          <Form.Group>
            <Form.Check
              type="radio"
              label={`Turn ${requirement.low_quantity} ore into ${requirement.low_result} fuel`}
              name="oreRadio"
              id="low"
              value="low"
              onChange={handleOreSelectionUpdated}
              defaultChecked
            />
            <Form.Check
              type="radio"
              label={`Turn ${requirement.med_quantity} ore into ${requirement.med_result} fuel and suffer a ${requirement.med_penalty} penalty to your morale roll this turn`}
              name="oreRadio"
              id="med"
              value="med"
              onChange={handleOreSelectionUpdated}
              disabled={requirement.med_quantity > ship.ore}
            />
            <Form.Check
              type="radio"
              label={`Turn ${requirement.high_quantity} ore into ${requirement.high_result} fuel and suffer a ${requirement.high_penalty} penalty to your morale roll this turn`}
              name="oreRadio"
              id="high"
              value="high"
              onChange={handleOreSelectionUpdated}
              disabled={requirement.high_quantity > ship.ore}
            />
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
}

RefineOre.propTypes = {
  requirementId: PropTypes.number.isRequired,
  updateRequirementsMet: PropTypes.func.isRequired,
  updateRequirementsData: PropTypes.func.isRequired,
};

export default RefineOre;
