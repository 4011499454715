import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import NumberSelector from "views/components/NumberSelector";
import CrewDisplay from "views/components/CrewDisplay";
import food from "static/img/icons/food.svg";
import water from "static/img/icons/water.svg";
import ore from "static/img/icons/ore.svg";
import fuel from "static/img/icons/fuel.svg";
import material from "static/img/icons/material.svg";
import {
  getActiveShip,
  requestUpdateLoadingDockAccess,
  getShips,
} from "store/ducks/ship";
import {
  getLoadingDockForActiveShip,
  requestTransferToLoadingDock,
  requestTransferFromLoadingDock,
} from "store/ducks/loadingDock";
import { getShuttlesDockedToActiveShip } from "store/ducks/shuttle";
import { getCrewForShip, requestUpdateTransferability } from "store/ducks/crew";
import { getStationsForActiveShip } from "store/ducks/station";
import { getPlayers } from "store/ducks/player";

// TODO: Support for custom cargo

function Cargo() {
  const dispatch = useDispatch();

  const ship = useSelector(getActiveShip, shallowEqual);
  const loadingDock = useSelector(getLoadingDockForActiveShip, shallowEqual);
  const dockedShuttles = useSelector(
    getShuttlesDockedToActiveShip,
    shallowEqual
  );
  const crew = useSelector(getCrewForShip, shallowEqual);
  const stations = useSelector(getStationsForActiveShip, shallowEqual);
  const players = useSelector(getPlayers, shallowEqual);
  const ships = useSelector(getShips, shallowEqual);

  const [foodTransferQuantity, setFoodTransferQuantity] = useState(1);
  const [waterTransferQuantity, setWaterTransferQuantity] = useState(1);
  const [oreTransferQuantity, setOreTransferQuantity] = useState(1);
  const [fuelTransferQuantity, setFuelTransferQuantity] = useState(1);
  const [materialTransferQuantity, setMaterialTransferQuantity] = useState(1);

  if (
    !ship ||
    !crew ||
    crew.length === 0 ||
    !stations ||
    stations.length === 0
  ) {
    return <div />;
  }

  return (
    <section className="mb-0 py-4 mx-2">
      <Container fluid>
        <div className="border bg-light rounded">
          <Row>
            <Col>
              <h2 className="text-center text-uppercase">Inner Cargo</h2>
              <p className="text-center">
                Stored safely within the ship. Can be used by ship stations and
                actions.
              </p>
            </Col>
          </Row>
          <Row className="text-center">
            <Col className="font-weight-bold d-flex flex-column">
              <div>
                <img src={food} alt="Food" title="Food" height="48" />
              </div>
              <div>Food</div>
              <div>{ship.food}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column">
              <div>
                <img src={water} alt="Water" title="Water" height="48" />
              </div>
              <div>Water</div>
              <div>{ship.water}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column">
              <div>
                <img src={ore} alt="Ore" title="Ore" height="48" />
              </div>
              <div>Ore</div>
              <div>{ship.ore}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column">
              <div>
                <img src={fuel} alt="Fuel" title="Fuel" height="48" />
              </div>
              <div>Fuel</div>
              <div>{ship.fuel}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column">
              <div>
                <img
                  src={material}
                  alt="Material"
                  title="Material"
                  height="48"
                />
              </div>
              <div>Material</div>
              <div>{ship.material}</div>
            </Col>
          </Row>
        </div>
        <Row className="text-center my-2">
          <Col>
            <Form inline className="justify-content-center">
              <InputGroup>
                <InputGroup.Prepend>
                  <Button
                    size="sm"
                    disabled={foodTransferQuantity > ship.food}
                    onClick={() =>
                      dispatch(
                        requestTransferToLoadingDock(
                          ship.id,
                          1,
                          foodTransferQuantity
                        )
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faArrowDown} />
                  </Button>
                </InputGroup.Prepend>
                <NumberSelector
                  defaultValue={1}
                  size="sm"
                  start={1}
                  onChange={setFoodTransferQuantity}
                />
                <InputGroup.Append>
                  <Button
                    size="sm"
                    disabled={foodTransferQuantity > (loadingDock[1] || 0)}
                    onClick={() =>
                      dispatch(
                        requestTransferFromLoadingDock(
                          ship.id,
                          1,
                          foodTransferQuantity
                        )
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </Col>
          <Col>
            <Form inline className="justify-content-center">
              <InputGroup>
                <InputGroup.Prepend>
                  <Button
                    size="sm"
                    disabled={waterTransferQuantity > ship.water}
                    onClick={() =>
                      dispatch(
                        requestTransferToLoadingDock(
                          ship.id,
                          2,
                          waterTransferQuantity
                        )
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faArrowDown} />
                  </Button>
                </InputGroup.Prepend>
                <NumberSelector
                  defaultValue={1}
                  size="sm"
                  start={1}
                  onChange={setWaterTransferQuantity}
                />
                <InputGroup.Append>
                  <Button
                    size="sm"
                    disabled={waterTransferQuantity > (loadingDock[2] || 0)}
                    onClick={() =>
                      dispatch(
                        requestTransferFromLoadingDock(
                          ship.id,
                          2,
                          waterTransferQuantity
                        )
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </Col>
          <Col>
            <Form inline className="justify-content-center">
              <InputGroup>
                <InputGroup.Prepend>
                  <Button
                    size="sm"
                    disabled={oreTransferQuantity > ship.ore}
                    onClick={() =>
                      dispatch(
                        requestTransferToLoadingDock(
                          ship.id,
                          3,
                          oreTransferQuantity
                        )
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faArrowDown} />
                  </Button>
                </InputGroup.Prepend>
                <NumberSelector
                  defaultValue={1}
                  size="sm"
                  start={1}
                  onChange={setOreTransferQuantity}
                />
                <InputGroup.Append>
                  <Button
                    size="sm"
                    disabled={oreTransferQuantity > (loadingDock[3] || 0)}
                    onClick={() =>
                      dispatch(
                        requestTransferFromLoadingDock(
                          ship.id,
                          3,
                          oreTransferQuantity
                        )
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </Col>
          <Col>
            <Form inline className="justify-content-center">
              <InputGroup>
                <InputGroup.Prepend>
                  <Button
                    size="sm"
                    disabled={fuelTransferQuantity > ship.fuel}
                    onClick={() =>
                      dispatch(
                        requestTransferToLoadingDock(
                          ship.id,
                          4,
                          fuelTransferQuantity
                        )
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faArrowDown} />
                  </Button>
                </InputGroup.Prepend>
                <NumberSelector
                  defaultValue={1}
                  size="sm"
                  start={1}
                  onChange={setFuelTransferQuantity}
                />
                <InputGroup.Append>
                  <Button
                    size="sm"
                    disabled={fuelTransferQuantity > (loadingDock[4] || 0)}
                    onClick={() =>
                      dispatch(
                        requestTransferFromLoadingDock(
                          ship.id,
                          4,
                          fuelTransferQuantity
                        )
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </Col>
          <Col>
            <Form inline className="justify-content-center">
              <InputGroup>
                <InputGroup.Prepend>
                  <Button
                    size="sm"
                    disabled={materialTransferQuantity > ship.material}
                    onClick={() =>
                      dispatch(
                        requestTransferToLoadingDock(
                          ship.id,
                          5,
                          materialTransferQuantity
                        )
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faArrowDown} />
                  </Button>
                </InputGroup.Prepend>
                <NumberSelector
                  defaultValue={1}
                  size="sm"
                  start={1}
                  onChange={setMaterialTransferQuantity}
                />
                <InputGroup.Append>
                  <Button
                    size="sm"
                    disabled={materialTransferQuantity > (loadingDock[5] || 0)}
                    onClick={() =>
                      dispatch(
                        requestTransferFromLoadingDock(
                          ship.id,
                          5,
                          materialTransferQuantity
                        )
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </Col>
        </Row>
        <div className="border bg-light rounded">
          <Row className="text-center">
            <Col className="font-weight-bold d-flex flex-column-reverse">
              <div>
                <img src={food} alt="Food" title="Food" height="48" />
              </div>
              <div>Food</div>
              <div>{loadingDock[1] || "0"}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column-reverse">
              <div>
                <img src={water} alt="Water" title="Water" height="48" />
              </div>
              <div>Water</div>
              <div>{loadingDock[2] || "0"}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column-reverse">
              <div>
                <img src={ore} alt="Ore" title="Ore" height="48" />
              </div>
              <div>Ore</div>
              <div>{loadingDock[3] || "0"}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column-reverse">
              <div>
                <img src={fuel} alt="Fuel" title="Fuel" height="48" />
              </div>
              <div>Fuel</div>
              <div>{loadingDock[4] || "0"}</div>
            </Col>
            <Col className="font-weight-bold d-flex flex-column-reverse">
              <div>
                <img
                  src={material}
                  alt="Material"
                  title="Material"
                  height="48"
                />
              </div>
              <div>Material</div>
              <div>{loadingDock[5] || "0"}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-center mb-0 mt-3">
                Prepped and ready for transport, accessible by any docked
                shuttles. Not available for use until stored.
              </p>
              <h2 className="text-center text-uppercase mb-0 mt-2">
                Loading Dock
              </h2>
            </Col>
          </Row>
        </div>
        <hr />
        <Row>
          <Col>
            <h2 className="text-center text-uppercase">Docked Shuttles</h2>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          {dockedShuttles.map((shuttle) => {
            return (
              <Col xs={6} sm={3} md={2} key={shuttle.id}>
                <Card>
                  <Card.Header className="text-center h4 font-weight-normal">
                    {shuttle.name}
                  </Card.Header>
                  <Card.Body>
                    Pilot:{" "}
                    {players
                      .filter((player) => player.id === shuttle.pilot_id)
                      .map((player) => {
                        return (
                          <span key={player.id}>
                            {player.name}, {player.role},{" "}
                            {
                              ships.find(
                                (playerShip) => playerShip.id === player.ship_id
                              ).name
                            }
                          </span>
                        );
                      })}
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
        <Row className="pt-2">
          <Col>
            <h4 className="text-center text-uppercase d-flex align-items-center justify-content-center">
              <span className="pr-3">Shuttle Access to Loading Dock:</span>
              <BootstrapSwitchButton
                onlabel="Enabled"
                offlabel="Disabled"
                onstyle="success"
                offstyle="danger"
                width={120}
                checked={ship.loading_dock_access}
                onChange={(checked) =>
                  dispatch(requestUpdateLoadingDockAccess(ship.id, checked))
                }
              />
            </h4>
            <p className="text-center">
              When shuttle access is enabled, shuttles at this ship are able to
              freely load and unload freight and passengers from this
              ship&apos;s loading dock. You can close and open this bay at any
              time.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h2 className="text-center text-uppercase">
              Crew Transfer Management
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center text-center">
            <Table striped bordered hover className="table-fit">
              <thead>
                <tr>
                  <th>Crew</th>
                  <th>Station</th>
                  <th>Transport</th>
                </tr>
              </thead>
              <tbody>
                {crew.map((crewMember) => {
                  return (
                    <tr key={crewMember.id}>
                      <td>
                        <CrewDisplay
                          name={crewMember.name}
                          role={crewMember.role}
                          status={crewMember.status}
                        />
                      </td>
                      {crewMember.station_id !== null && (
                        <td>
                          {
                            stations.find(
                              (station) => station.id === crewMember.station_id
                            ).name
                          }
                        </td>
                      )}
                      {crewMember.station_id === null && (
                        <td>Security Station</td>
                      )}
                      <td>
                        <BootstrapSwitchButton
                          size="sm"
                          onlabel="Allowed"
                          offlabel="Blocked"
                          onstyle="success"
                          offstyle="danger"
                          width={90}
                          checked={crewMember.may_offload}
                          onChange={(checked) =>
                            dispatch(
                              requestUpdateTransferability(
                                crewMember.id,
                                checked
                              )
                            )
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Cargo;
