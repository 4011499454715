import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import NumberSelector from "views/components/NumberSelector";
import moraleIcon from "static/img/icons/morale.svg";
import { activeUserIsFacilitator } from "store/ducks/user";
import { requestUpdateResource } from "store/ducks/ship";

function Morale({ shipId, morale }) {
  const dispatch = useDispatch();

  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);

  const [showMoraleSelector, setShowMoraleSelector] = useState(false);
  const [newMorale, setNewMorale] = useState(morale);

  useEffect(() => {
    setNewMorale(morale);
  }, [setNewMorale, morale]);

  if (showMoraleSelector) {
    return (
      <Form inline>
        <Form.Label>
          <img src={moraleIcon} alt="Morale" title="Morale" height="24" />
        </Form.Label>
        <InputGroup className="pl-1">
          <NumberSelector
            value={newMorale}
            onChange={setNewMorale}
            start={1}
            end={10}
            size="sm"
          />
          <InputGroup.Append>
            <Button
              size="sm"
              variant="success"
              onClick={() => {
                dispatch(requestUpdateResource(shipId, "morale", newMorale));
                setShowMoraleSelector(false);
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                setShowMoraleSelector(false);
                setNewMorale(morale);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    );
  }
  return (
    <span
      className="mx-1 text-nowrap"
      onClick={isFacilitator ? () => setShowMoraleSelector(true) : () => {}}
      onKeyUp={isFacilitator ? () => setShowMoraleSelector(true) : () => {}}
      role={isFacilitator ? "button" : ""}
      tabIndex={isFacilitator ? 0 : null}
    >
      <img src={moraleIcon} alt="Morale" title="Morale" height="24" /> {morale}
    </span>
  );
}

Morale.propTypes = {
  shipId: PropTypes.number.isRequired,
  morale: PropTypes.number.isRequired,
};

export default Morale;
