import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { getStation, requestUpdateStationValues } from "store/ducks/station";
import TransactionEditor from "./transaction/editor/TransactionEditor";

function FacilitatorEditStation({ show, onHide, stationId }) {
  const dispatch = useDispatch();

  const station = useSelector(
    (state) => getStation(state, stationId),
    shallowEqual
  );

  const [name, setName] = useState(station.name);

  useEffect(() => {
    setName(station.name);
  }, [station.name]);

  const [text, setText] = useState(station.text);

  useEffect(() => {
    setText(station.text);
  }, [station.text]);

  const [damageText, setDamageText] = useState(station.damage_text);

  useEffect(() => {
    setDamageText(station.damage_text);
  }, [station.damage_text]);

  const [status, setStatus] = useState(station.status);

  useEffect(() => {
    setStatus(station.status);
  }, [station.status]);

  const [rioterStatus, setRioterStatus] = useState(station.rioter_status);

  useEffect(() => {
    setRioterStatus(station.rioter_status);
  }, [station.rioter_status]);

  const [repairEngineerCost, setRepairEngineerCost] = useState(
    station.repair_engineer_cost
  );

  useEffect(() => {
    setRepairEngineerCost(station.repair_engineer_cost);
  }, [station.repair_engineer_cost]);

  const [repairMaterialCost, setRepairMaterialCost] = useState(
    station.repair_material_cost
  );

  useEffect(() => {
    setRepairMaterialCost(station.repair_material_cost);
  }, [station.repair_material_cost]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="editStationModalTitle"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="editStationModalTitle">
          Editing {station.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Button
                onClick={() =>
                  dispatch(requestUpdateStationValues(stationId, { name }))
                }
              >
                Save
              </Button>
            </Form.Group>
            <Form.Group>
              <Form.Label>Text</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <Button
                onClick={() =>
                  dispatch(requestUpdateStationValues(stationId, { text }))
                }
              >
                Save
              </Button>
            </Form.Group>
            <Form.Group>
              <Form.Label>Damage Text</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={damageText}
                onChange={(e) => setDamageText(e.target.value)}
              />
              <Button
                onClick={() =>
                  dispatch(
                    requestUpdateStationValues(stationId, {
                      damage_text: damageText,
                    })
                  )
                }
              >
                Save
              </Button>
            </Form.Group>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="damaged">Damaged</option>
                <option value="rioting">Rioting</option>
                <option value="used">Used</option>
                <option value="disabled">Disabled</option>
              </Form.Control>
              <Button
                onClick={() => {
                  if (station.status === "rioting" && status !== "rioting") {
                    dispatch(
                      requestUpdateStationValues(stationId, {
                        status,
                        rioter_status: "none",
                      })
                    );
                  } else if (
                    station.status !== "rioting" &&
                    status === "rioting"
                  ) {
                    dispatch(
                      requestUpdateStationValues(stationId, {
                        status,
                        rioter_status: "active",
                      })
                    );
                  } else {
                    dispatch(requestUpdateStationValues(stationId, { status }));
                  }
                }}
              >
                Save
              </Button>
            </Form.Group>
            <Form.Group>
              <Form.Label>Rioter Status</Form.Label>
              <Form.Control
                as="select"
                value={rioterStatus}
                onChange={(e) => setRioterStatus(e.target.value)}
              >
                <option value="none">None</option>
                <option value="active">Active</option>
                <option value="suppressed">Suppressed</option>
              </Form.Control>
              <Button
                disabled={station.status !== "rioting"}
                onClick={() => {
                  dispatch(
                    requestUpdateStationValues(stationId, {
                      rioter_status: rioterStatus,
                    })
                  );
                }}
              >
                Save
              </Button>
            </Form.Group>
            <Form.Group>
              <Form.Label>Repair Engineer Cost</Form.Label>
              <Form.Control
                value={repairEngineerCost}
                onChange={(e) => setRepairEngineerCost(e.target.value)}
              />
              <Button
                onClick={() =>
                  dispatch(
                    requestUpdateStationValues(stationId, {
                      repair_engineer_cost: repairEngineerCost,
                    })
                  )
                }
              >
                Save
              </Button>
            </Form.Group>
            <Form.Group>
              <Form.Label>Repair Material Cost</Form.Label>
              <Form.Control
                value={repairMaterialCost}
                onChange={(e) => setRepairMaterialCost(e.target.value)}
              />
              <Button
                onClick={() =>
                  dispatch(
                    requestUpdateStationValues(stationId, {
                      repair_material_cost: repairMaterialCost,
                    })
                  )
                }
              >
                Save
              </Button>
            </Form.Group>
            {!station.special_transaction_name && (
              <TransactionEditor
                transactionId={station.transaction_id}
                shipId={station.ship_id}
                stationId={stationId}
              />
            )}
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

FacilitatorEditStation.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  stationId: PropTypes.number.isRequired,
};

export default FacilitatorEditStation;
