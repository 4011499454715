import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faPlay,
  faBackward,
  faFastForward,
} from "@fortawesome/free-solid-svg-icons";
import {
  getTimerPauseTime,
  requestTimerPause,
  requestTimerResume,
  requestTimerRewind,
  requestPhaseAdvance,
} from "store/ducks/gameTurn";

function TimerControls() {
  const dispatch = useDispatch();

  const timerPauseTime = useSelector(getTimerPauseTime, shallowEqual);

  const [timerControlsLocked, setTimerControlsLocked] = useState(true);

  return (
    <ButtonGroup className="mr-4">
      <Button
        variant={timerControlsLocked ? "danger" : "success"}
        title="Unlock timer controls"
        onClick={() => setTimerControlsLocked(!timerControlsLocked)}
      >
        {timerControlsLocked ? "Locked" : "Unlocked"}
      </Button>
      <Button
        variant="secondary"
        title="Rewind timer to start of phase"
        onClick={() => dispatch(requestTimerRewind())}
        disabled={timerControlsLocked}
      >
        <FontAwesomeIcon icon={faBackward} />
      </Button>
      {timerPauseTime && (
        <Button
          variant="secondary"
          title="Resume timer countdown"
          onClick={() => dispatch(requestTimerResume())}
          disabled={timerControlsLocked}
        >
          <FontAwesomeIcon icon={faPlay} />
        </Button>
      )}
      {!timerPauseTime && (
        <Button
          variant="secondary"
          title="Pause timer countdown"
          onClick={() => dispatch(requestTimerPause())}
          disabled={timerControlsLocked}
        >
          <FontAwesomeIcon icon={faPause} />
        </Button>
      )}
      <Button
        variant="secondary"
        title="Advance to next phase"
        onClick={() => dispatch(requestPhaseAdvance())}
        disabled={timerControlsLocked}
      >
        <FontAwesomeIcon icon={faFastForward} />
      </Button>
    </ButtonGroup>
  );
}

export default TimerControls;
