import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CrewDisplay from "views/components/CrewDisplay";
import { getTransactionEffect } from "store/ducks/transactionEffect";
import { getDamagedSynthsAtStation } from "store/ducks/crew";

function RepairSynthsAtStation({ effectId, updateEffectsRequirementsMet }) {
  const effect = useSelector(
    (state) => getTransactionEffect(state, effectId),
    shallowEqual
  );
  const damagedSynths = useSelector(
    (state) => getDamagedSynthsAtStation(state, effect.station_id),
    shallowEqual
  );

  useEffect(() => {
    if (damagedSynths.length === 0) {
      updateEffectsRequirementsMet(effectId, false);
    }
    if (damagedSynths.length > 0) {
      updateEffectsRequirementsMet(effectId, true);
    }
  }, [damagedSynths, updateEffectsRequirementsMet, effectId]);

  if (damagedSynths.length === 0) {
    return (
      <Row>
        <Col>
          <h4>Repair Synths at Station</h4>
          <p>No damaged synths found at station.</p>
        </Col>
      </Row>
    );
  }
  return (
    <Row>
      <Col>
        <h4>Repair Synths at Station</h4>
        <p>Damaged synths at this station will be repaird and charged.</p>
        <ul>
          {damagedSynths.map((crew) => {
            return (
              <li key={crew.id}>
                <CrewDisplay
                  role={crew.role}
                  name={crew.name}
                  status={crew.status}
                />
              </li>
            );
          })}
        </ul>
      </Col>
    </Row>
  );
}

RepairSynthsAtStation.propTypes = {
  effectId: PropTypes.number.isRequired,
  updateEffectsRequirementsMet: PropTypes.func.isRequired,
};

export default RepairSynthsAtStation;
