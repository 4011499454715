import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import food from "static/img/icons/food.svg";
import water from "static/img/icons/water.svg";
import ore from "static/img/icons/ore.svg";
import fuel from "static/img/icons/fuel.svg";
import material from "static/img/icons/material.svg";
import { getShipById } from "store/ducks/ship";
import { getResearchPoints } from "store/ducks/researchPoints";
import ResearchPoints from "./resources/ResearchPoints";
import Resource from "./resources/Resource";
import Morale from "./resources/Morale";

function ShipResources({ shipId }) {
  const ship = useSelector(
    (state) => getShipById(state, shipId) || {},
    shallowEqual
  );
  const researchPoints = useSelector(
    (state) => getResearchPoints(state) || 0,
    shallowEqual
  );

  return (
    <span className="navbar-text text-light resource-text text-nowrap d-flex flex-row justify-content-center">
      <Morale shipId={ship.id} morale={ship.morale} />
      <Resource shipId={ship.id} resource="food" value={ship.food} img={food} />
      <Resource
        shipId={ship.id}
        resource="water"
        value={ship.water}
        img={water}
      />
      <Resource shipId={ship.id} resource="ore" value={ship.ore} img={ore} />
      <Resource shipId={ship.id} resource="fuel" value={ship.fuel} img={fuel} />
      <Resource
        shipId={ship.id}
        resource="material"
        value={ship.material}
        img={material}
      />
      {ship.id === 9 && <ResearchPoints points={researchPoints} />}
    </span>
  );
}

ShipResources.propTypes = {
  shipId: PropTypes.number.isRequired,
};

export default ShipResources;
