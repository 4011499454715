import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import CrewDisplay from "views/components/CrewDisplay";
import {
  getUsableCrewForStation,
  requestCommitToWeaponBatteries,
} from "store/ducks/crew";
import {
  getUpdateSucceeded,
  getErrorMessage,
  clearUpdateStatus,
} from "store/ducks/display";
import { getStation } from "store/ducks/station";

function CommitCrewToWeaponBatteries({ show, onHide, stationId }) {
  const dispatch = useDispatch();

  const usableCrew = useSelector(
    (state) => getUsableCrewForStation(state, stationId),
    shallowEqual
  );
  const station = useSelector(
    (state) => getStation(state, stationId),
    shallowEqual
  );
  const updateSucceeded = useSelector(getUpdateSucceeded, shallowEqual);
  const errorMessage = useSelector(getErrorMessage, shallowEqual);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedCrew, setSelectedCrew] = useState(
    usableCrew.length > 0 ? usableCrew[0].id : 0
  );

  useEffect(() => {
    if (updateSucceeded === undefined) {
      setShowSuccessAlert(false);
      setShowErrorAlert(false);
      setErrorAlertMessage("");
    }
    if (updateSucceeded !== undefined) {
      setLoading(false);
      if (updateSucceeded !== showSuccessAlert) {
        setShowSuccessAlert(updateSucceeded);
      }
      if (updateSucceeded === showErrorAlert) {
        setShowErrorAlert(!updateSucceeded);
      }
    }
    if (errorMessage !== undefined && errorMessage !== errorAlertMessage) {
      setErrorAlertMessage(errorMessage);
    }
  }, [
    updateSucceeded,
    showSuccessAlert,
    showErrorAlert,
    errorMessage,
    errorAlertMessage,
  ]);

  useEffect(() => {
    if (
      !usableCrew.map((crew) => crew.id).includes(parseInt(selectedCrew, 10))
    ) {
      setSelectedCrew(usableCrew.length > 0 ? usableCrew[0].id : 0);
    }
  }, [usableCrew, selectedCrew]);

  const clearAlert = () => {
    if (updateSucceeded !== undefined) {
      dispatch(clearUpdateStatus());
    }
  };

  const getCrewDisplayForCrewMember = (crewMember) => {
    return (
      <CrewDisplay
        role={crewMember.role}
        name={crewMember.name}
        status={crewMember.status}
      />
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={() => clearAlert()}
      size="lg"
      aria-labelledby="commitCrewModalTitle"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="commitCrewModalTitle">
          Commit Crew to Weapon Batteries
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert
          show={showSuccessAlert}
          variant="success"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Crew Committed</Alert.Heading>
          Crew has been committed to the Weapon Batteries for this turn.
        </Alert>
        <Alert
          show={showErrorAlert}
          variant="danger"
          dismissible
          onClose={() => clearAlert()}
        >
          <Alert.Heading>Error</Alert.Heading>
          {errorAlertMessage}
        </Alert>
        {station.status === "used" && (
          <span>
            Crew has already been committed to the Weapon Batteries this turn.
          </span>
        )}
        {station.status !== "used" && usableCrew.length === 0 && (
          <span>
            At least one uninjured, unused crew member must be posted at this
            station in order to commit them to the Weapon Batteries.
          </span>
        )}
        {station.status !== "used" && usableCrew.length === 1 && (
          <span>
            Are you sure you want to commit crew member{" "}
            {getCrewDisplayForCrewMember(usableCrew[0])} to the Weapon Batteries
            for this turn?
          </span>
        )}
        {station.status !== "used" && usableCrew.length > 1 && (
          <Form>
            <Form.Group controlId="formGroupCrewSelect">
              <Form.Label>
                Please select the crew member to commit to the Weapon Batteries
                for this turn.
              </Form.Label>
              <Form.Control
                as="select"
                custom
                onChange={(e) => setSelectedCrew(e.target.value)}
                defaultValue={selectedCrew}
              >
                {usableCrew.map((crew) => {
                  return (
                    <option value={crew.id} key={crew.id}>
                      {crew.role} - {crew.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={
            isLoading || usableCrew.length === 0 || station.status === "used"
          }
          onClick={() => {
            clearAlert();
            dispatch(requestCommitToWeaponBatteries(selectedCrew, stationId));
            setLoading(true);
          }}
        >
          Commit Crew
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CommitCrewToWeaponBatteries.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  stationId: PropTypes.number.isRequired,
};

export default CommitCrewToWeaponBatteries;
