import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { getShips, getShipById, requestJumpShips } from "store/ducks/ship";
import { getAllFuelCostsForShipById } from "store/ducks/cost";
import {
  getJumpStationForShipById,
  getFuelStationForShipById,
} from "store/ducks/station";

function FuelStationStatus({ shipId }) {
  const fuelStation = useSelector(
    (state) => getFuelStationForShipById(state, shipId),
    shallowEqual
  );

  if (fuelStation && fuelStation.status === "damaged") {
    return <td className="bg-danger text-white">Fuel Store Damaged</td>;
  }
  return <td className="bg-success text-white">Fuel Store OK</td>;
}

FuelStationStatus.propTypes = {
  shipId: PropTypes.number.isRequired,
};

function JumpStationStatus({ shipId }) {
  const jumpStation = useSelector(
    (state) => getJumpStationForShipById(state, shipId),
    shallowEqual
  );

  if (jumpStation.status === "damaged") {
    return <td className="bg-danger text-white">Drive Damaged</td>;
  }
  return <td className="bg-success text-white">Drive OK</td>;
}

JumpStationStatus.propTypes = {
  shipId: PropTypes.number.isRequired,
};

const ShipRow = forwardRef(function ShipRow({ shipId }, ref) {
  const ship = useSelector((state) => getShipById(state, shipId), shallowEqual);
  const fuelCosts = useSelector(
    (state) => getAllFuelCostsForShipById(state, shipId),
    shallowEqual
  );

  const [jumpDistance, setJumpDistance] = useState("na");

  useImperativeHandle(ref, () => ({
    setJumpDistance: (distance) => {
      setJumpDistance(distance);
    },
    getJumpDistance: () => {
      return jumpDistance;
    },
  }));

  return (
    <tr>
      <td>
        <Link to={`/ship/${ship.id}`}>{ship.name}</Link>
      </td>
      {ship.jump_active && <td className="bg-success text-white">Jumping!</td>}
      {!ship.jump_active && (
        <td className={jumpDistance !== "na" ? "bg-danger text-white" : ""}>
          Not Jumping
        </td>
      )}
      <td>{ship.jump_coordinates.padStart(5, "0")}</td>
      <td>
        {fuelCosts.short}-{fuelCosts.medium}-{fuelCosts.long}
      </td>
      <td
        className={
          jumpDistance !== "na" && fuelCosts[jumpDistance] > ship.fuel
            ? "bg-danger text-white"
            : ""
        }
      >
        {ship.fuel}
      </td>
      <JumpStationStatus shipId={ship.id} />
      <FuelStationStatus shipId={ship.id} />
      <td>
        <ButtonGroup>
          <Button
            variant={jumpDistance === "na" ? "success" : "secondary"}
            onClick={() => setJumpDistance("na")}
          >
            N/A
          </Button>
          <Button
            variant={jumpDistance === "short" ? "success" : "secondary"}
            onClick={() => setJumpDistance("short")}
          >
            Short
          </Button>
          <Button
            variant={jumpDistance === "medium" ? "success" : "secondary"}
            onClick={() => setJumpDistance("medium")}
          >
            Medium
          </Button>
          <Button
            variant={jumpDistance === "long" ? "success" : "secondary"}
            onClick={() => setJumpDistance("long")}
          >
            Long
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
});

ShipRow.propTypes = {
  shipId: PropTypes.number.isRequired,
};

function JumpStatus() {
  const dispatch = useDispatch();
  const ships = useSelector(getShips, shallowEqual);

  const shipRowsRef = useRef([]);

  useEffect(() => {
    shipRowsRef.current = shipRowsRef.current.slice(0, ships.length);
  }, [ships.length]);

  return (
    <Container
      fluid
      className="h-100 w-100 d-flex flex-column position-relative p-0"
    >
      <section className="mb-0 py-4 mx-2">
        <h2 className="text-center text-uppercase">Jump Status</h2>
        <Container fluid>
          <Table responsive>
            <thead>
              <tr>
                <th>Ship Name</th>
                <th>Jump Status</th>
                <th>Input Coordinates</th>
                <th>Jump Costs</th>
                <th>Current Fuel</th>
                <th>Drive Status</th>
                <th>Fuel Status</th>
                <th>Jump Distance</th>
              </tr>
            </thead>
            <tbody>
              {ships.map((ship, i) => {
                return (
                  <ShipRow
                    key={ship.id}
                    shipId={ship.id}
                    ref={(el) => {
                      shipRowsRef.current[i] = el;
                    }}
                  />
                );
              })}
            </tbody>
          </Table>
          <Row className="justify-content-center">
            <Col xs="auto" className="d-flex flex-row">
              <DropdownButton title="Set Jump Distance">
                <Dropdown.Item
                  onClick={() => {
                    shipRowsRef.current.forEach((ref) =>
                      ref.setJumpDistance("na")
                    );
                  }}
                >
                  N/A
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    shipRowsRef.current.forEach((ref) =>
                      ref.setJumpDistance("short")
                    );
                  }}
                >
                  Short
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    shipRowsRef.current.forEach((ref) =>
                      ref.setJumpDistance("medium")
                    );
                  }}
                >
                  Medium
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    shipRowsRef.current.forEach((ref) =>
                      ref.setJumpDistance("long")
                    );
                  }}
                >
                  Long
                </Dropdown.Item>
              </DropdownButton>
              <Button
                className="ml-4"
                onClick={() => {
                  const shipsToJump = {};
                  shipRowsRef.current.forEach((ref, rowNum) => {
                    if (ref.getJumpDistance() !== "na") {
                      shipsToJump[rowNum + 1] = ref.getJumpDistance();
                    }
                  });
                  dispatch(requestJumpShips(shipsToJump));
                  shipRowsRef.current.forEach((ref) =>
                    ref.setJumpDistance("na")
                  );
                }}
              >
                Jump Selected Ships
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </Container>
  );
}

export default JumpStatus;
