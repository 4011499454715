import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import CrewDisplay from "views/components/CrewDisplay";
import { getTransactionEffect } from "store/ducks/transactionEffect";
import { getWoundedCrewAtStation } from "store/ducks/crew";

function HealCrewAtStation({
  effectId,
  updateEffectsRequirementsMet,
  updateEffectsData,
}) {
  const effect = useSelector(
    (state) => getTransactionEffect(state, effectId),
    shallowEqual
  );
  const woundedCrew = useSelector(
    (state) => getWoundedCrewAtStation(state, effect.station_id),
    shallowEqual
  );

  const [selectedCrew, setSelectedCrew] = useState(
    woundedCrew.length > 0 ? woundedCrew[0].id : 0
  );

  useEffect(() => {
    if (woundedCrew.length === 0) {
      updateEffectsRequirementsMet(effectId, false);
    }
    if (woundedCrew.length > 0) {
      updateEffectsData(effectId, selectedCrew);
      updateEffectsRequirementsMet(effectId, true);
    }
  }, [
    woundedCrew,
    updateEffectsRequirementsMet,
    updateEffectsData,
    effectId,
    selectedCrew,
  ]);

  if (woundedCrew.length === 0) {
    return (
      <Row>
        <Col>
          <h4>Heal Crew at Station</h4>
          <p>No wounded crew found at station.</p>
        </Col>
      </Row>
    );
  }
  if (woundedCrew.length === 1) {
    const crewMember = woundedCrew[0];
    return (
      <Row>
        <Col>
          <h4>Heal Crew at Station</h4>
          <p>
            Crew member{" "}
            <CrewDisplay
              role={crewMember.role}
              name={crewMember.name}
              status={crewMember.status}
            />{" "}
            will be healed.
          </p>
        </Col>
      </Row>
    );
  }
  return (
    <Form>
      <Form.Group controlId="formHealCrewAtStationCrewSelect">
        <Form.Label>
          Please select the crew member to heal at this station.
        </Form.Label>
        <Form.Control
          as="select"
          custom
          onChange={(e) => setSelectedCrew(parseInt(e.target.value, 10))}
          defaultValue={selectedCrew}
        >
          {woundedCrew.map((crew) => {
            return (
              <option value={crew.id} key={crew.id}>
                {crew.role} - {crew.name}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    </Form>
  );
}

HealCrewAtStation.propTypes = {
  effectId: PropTypes.number.isRequired,
  updateEffectsRequirementsMet: PropTypes.func.isRequired,
  updateEffectsData: PropTypes.func.isRequired,
};

export default HealCrewAtStation;
