import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import NumberSelector from "views/components/NumberSelector";
import {
  getFighterById,
  requestMoveFighter,
  requestUpdateFighterStatus,
  requestUpdateFighterUsed,
  requestAttack,
} from "store/ducks/fighter";
import { setSelectedItem } from "store/ducks/display";
import { activeUserIsFacilitator } from "store/ducks/user";

function FighterControl({ fighterId }) {
  const dispatch = useDispatch();

  const fighter = useSelector(
    (state) => getFighterById(state, fighterId),
    shallowEqual
  );
  const isFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);

  const [fireWeaponsRollModifier, setFireWeaponsRollModifier] = useState(0);

  const damageFighter = () => {
    if (fighter.status === "normal") {
      dispatch(requestUpdateFighterStatus(fighterId, "damaged"));
    } else if (fighter.status === "damaged") {
      dispatch(requestUpdateFighterStatus(fighterId, "destroyed"));
    }
  };

  const repairFighter = () => {
    if (fighter.status === "destroyed") {
      dispatch(requestUpdateFighterStatus(fighterId, "damaged"));
    } else if (fighter.status === "damaged") {
      dispatch(requestUpdateFighterStatus(fighterId, "normal"));
    }
  };

  return (
    <Row className="text-light">
      <Col>
        <div className="d-flex flex-row align-items-center">
          <span className="mr-4">{fighter.name} Actions:</span>
          <ButtonToolbar>
            <InputGroup className="mr-2">
              <InputGroup.Prepend>
                <Button
                  onClick={() =>
                    dispatch(requestAttack(fighterId, fireWeaponsRollModifier))
                  }
                >
                  Fire Weapons
                </Button>
              </InputGroup.Prepend>
              <NumberSelector
                defaultValue={0}
                onChange={setFireWeaponsRollModifier}
                start={-3}
                end={3}
                showPlus
              />
            </InputGroup>
            <Button
              className="mr-2"
              onClick={() => {
                dispatch(requestMoveFighter(fighterId, null));
                dispatch(setSelectedItem(null, null));
              }}
            >
              Return to Hangar Bay
            </Button>
            {isFacilitator && (
              <>
                <ButtonGroup className="mr-2">
                  <Button
                    disabled={fighter.status === "destroyed"}
                    onClick={() => damageFighter()}
                  >
                    Damage Fighter
                  </Button>
                  <Button
                    disabled={fighter.status === "normal"}
                    onClick={() => repairFighter()}
                  >
                    Repair Fighter
                  </Button>
                </ButtonGroup>
                <ButtonGroup>
                  <Button
                    onClick={() =>
                      dispatch(
                        requestUpdateFighterUsed(fighterId, !fighter.used)
                      )
                    }
                  >
                    Toggle Used
                  </Button>
                </ButtonGroup>
              </>
            )}
          </ButtonToolbar>
        </div>
      </Col>
    </Row>
  );
}

FighterControl.propTypes = {
  fighterId: PropTypes.number.isRequired,
};

export default FighterControl;
