import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { createSelector } from "reselect";
import { getShipId } from "./display";

const getCosts = (state) => Object.values(state.costs);

const getCostsForShip = createSelector(
  [getShipId, getCosts],
  (shipId, costs) => {
    return costs.filter((cost) => {
      return cost.ship_id === shipId;
    });
  }
);

const getCostsForShipById = (state, shipId) => {
  const costs = getCosts(state);
  return costs.filter((cost) => {
    return cost.ship_id === shipId;
  });
};

const getCostsForShipByType = (shipCosts, type) => {
  return shipCosts.filter((cost) => {
    return cost.type === type;
  });
};

const getResourceCostsForShipInOrder = (shipCosts) => {
  const costs = [];
  [
    "none",
    "critical",
    "minimum",
    "low",
    "normal",
    "increased",
    "luxurious",
  ].forEach((setting) => {
    const cost = shipCosts.find((shipCost) => shipCost.setting === setting);
    if (cost) {
      costs.push(cost);
    }
  });
  return costs;
};

const getFoodCostsForShip = createSelector([getCostsForShip], (shipCosts) => {
  return getCostsForShipByType(shipCosts, "food");
});

export const getFoodCostsForShipInOrder = createSelector(
  [getFoodCostsForShip],
  (foodCosts) => {
    return getResourceCostsForShipInOrder(foodCosts);
  }
);

const getWaterCostsForShip = createSelector([getCostsForShip], (shipCosts) => {
  return getCostsForShipByType(shipCosts, "water");
});

export const getWaterCostsForShipInOrder = createSelector(
  [getWaterCostsForShip],
  (waterCosts) => {
    return getResourceCostsForShipInOrder(waterCosts);
  }
);

const getFuelCostsForShip = createSelector([getCostsForShip], (shipCosts) => {
  return getCostsForShipByType(shipCosts, "fuel");
});

const getFuelCostsForShipById = (state, shipId) => {
  const shipCosts = getCostsForShipById(state, shipId);
  return getCostsForShipByType(shipCosts, "fuel");
};

const getShortFuelCostForShip = createSelector(
  [getFuelCostsForShip],
  (fuelCosts) => {
    return fuelCosts.find((cost) => {
      return cost.setting === "short";
    });
  }
);

const getShortFuelCostForShipById = (state, shipId) => {
  const fuelCosts = getFuelCostsForShipById(state, shipId);
  return fuelCosts.find((cost) => {
    return cost.setting === "short";
  });
};

const getMediumFuelCostForShip = createSelector(
  [getFuelCostsForShip],
  (fuelCosts) => {
    return fuelCosts.find((cost) => {
      return cost.setting === "medium";
    });
  }
);

const getMediumFuelCostForShipById = (state, shipId) => {
  const fuelCosts = getFuelCostsForShipById(state, shipId);
  return fuelCosts.find((cost) => {
    return cost.setting === "medium";
  });
};

const getLongFuelCostForShip = createSelector(
  [getFuelCostsForShip],
  (fuelCosts) => {
    return fuelCosts.find((cost) => {
      return cost.setting === "long";
    });
  }
);

const getLongFuelCostForShipById = (state, shipId) => {
  const fuelCosts = getFuelCostsForShipById(state, shipId);
  return fuelCosts.find((cost) => {
    return cost.setting === "long";
  });
};

export const getAllFuelCostsForShip = createSelector(
  [getShortFuelCostForShip, getMediumFuelCostForShip, getLongFuelCostForShip],
  (short, medium, long) => {
    return {
      short: short !== undefined ? short.cost : null,
      medium: medium !== undefined ? medium.cost : null,
      long: long !== undefined ? long.cost : null,
    };
  }
);

export const getAllFuelCostsForShipById = (state, shipId) => {
  const short = getShortFuelCostForShipById(state, shipId);
  const medium = getMediumFuelCostForShipById(state, shipId);
  const long = getLongFuelCostForShipById(state, shipId);
  return {
    short: short !== undefined ? short.cost : null,
    medium: medium !== undefined ? medium.cost : null,
    long: long !== undefined ? long.cost : null,
  };
};

const FETCH_REQUESTED = "maas-loader/cost/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/cost/FETCH_SUCCEEDED";
const FETCH_FAILED = "maas-loader/cost/FETCH_FAILED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { costs } = action;
      return costs;
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(costs) {
  return { type: FETCH_SUCCEEDED, costs };
}

export function fetchFailed(message) {
  return { type: FETCH_FAILED, message };
}

function* fetchCosts() {
  try {
    const costsResponse = yield call(() =>
      axios.get("/api/v1/costs/?limit=1000")
    );
    const costs = {};
    Object.values(costsResponse.data).forEach((cost) => {
      costs[cost.id] = cost;
    });
    yield put(fetchSucceeded(costs));
  } catch (e) {
    yield put(fetchFailed(e.message));
  }
}

export function* watchFetchCosts() {
  yield takeLatest(FETCH_REQUESTED, fetchCosts);
}
