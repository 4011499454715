import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";

const getShuttleCargo = (state) => Object.values(state.shuttleCargo);

export const getShuttleCargoForShuttle = (state, shuttleId) => {
  const allShuttleCargo = getShuttleCargo(state);
  const cargo = {};
  allShuttleCargo
    .filter((entry) => entry.shuttle_id === shuttleId)
    .forEach((entry) => {
      cargo[entry.cargo_id] = entry.qty;
    });
  return cargo;
};

export const getShuttleCargoTotals = (state) => {
  const allShuttleCargo = getShuttleCargo(state);
  const cargo = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
  allShuttleCargo.forEach((entry) => {
    cargo[entry.cargo_id] += entry.qty;
  });
  return cargo;
};

const FETCH_REQUESTED = "maas-loader/shuttleCargo/FETCH_REQUESTED";
export const FETCH_SUCCEEDED = "maas-loader/shuttleCargo/FETCH_SUCCEEDED";
const UPDATE_RECEIVED = "maas-loader/shuttleCargo/UPDATE_RECEIVED";
const TRANSFER_TO_SHUTTLE_CARGO_REQUESTED =
  "maas-loader/shuttleCargo/TRANSFER_TO_SHUTTLE_CARGO_REQUESTED";
const TRANSFER_FROM_SHUTTLE_CARGO_REQUESTED =
  "maas-loader/shuttleCargo/TRANSFER_FROM_SHUTTLE_CARGO_REQUESTED";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_SUCCEEDED: {
      const { shuttleCargos } = action;
      return shuttleCargos;
    }
    case UPDATE_RECEIVED: {
      const { shuttleCargo } = action;
      return { ...state, [shuttleCargo.id]: shuttleCargo };
    }
    default:
      return state;
  }
}

export function requestFetch() {
  return { type: FETCH_REQUESTED };
}

export function fetchSucceeded(shuttleCargos) {
  return { type: FETCH_SUCCEEDED, shuttleCargos };
}

export function updateReceived(shuttleCargo) {
  return { type: UPDATE_RECEIVED, shuttleCargo };
}

export function requestTransferToShuttleCargo(shuttleId, shipId, cargoId, qty) {
  return {
    type: TRANSFER_TO_SHUTTLE_CARGO_REQUESTED,
    shuttleId,
    shipId,
    cargoId,
    qty,
  };
}

export function requestTransferFromShuttleCargo(
  shuttleId,
  shipId,
  cargoId,
  qty
) {
  return {
    type: TRANSFER_FROM_SHUTTLE_CARGO_REQUESTED,
    shuttleId,
    shipId,
    cargoId,
    qty,
  };
}

function* fetchShuttleCargo() {
  try {
    const shuttleCargoResponse = yield call(() =>
      axios.get("/api/v1/shuttle_cargo/")
    );
    const shuttleCargos = {};
    Object.values(shuttleCargoResponse.data).forEach((shuttleCargo) => {
      shuttleCargos[shuttleCargo.id] = shuttleCargo;
    });
    yield put(fetchSucceeded(shuttleCargos));
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchFetchShuttleCargo() {
  yield takeLatest(FETCH_REQUESTED, fetchShuttleCargo);
}

function* transferToShuttleCargo(action) {
  const { shuttleId, shipId, cargoId, qty } = action;
  try {
    yield call(
      () =>
        axios.put(
          `/api/v1/shuttle_cargo/transfer_to_shuttle_cargo/shuttle/${shuttleId}/ship/${shipId}/cargo/${cargoId}/qty/${qty}`
        ),
      {}
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchTransferToShuttleCargo() {
  yield takeEvery(TRANSFER_TO_SHUTTLE_CARGO_REQUESTED, transferToShuttleCargo);
}

function* transferFromShuttleCargo(action) {
  const { shuttleId, shipId, cargoId, qty } = action;
  try {
    yield call(
      () =>
        axios.put(
          `/api/v1/shuttle_cargo/transfer_from_shuttle_cargo/shuttle/${shuttleId}/ship/${shipId}/cargo/${cargoId}/qty/${qty}`
        ),
      {}
    );
  } catch (e) {
    // TODO: Error handling
  }
}

export function* watchTransferFromShuttleCargo() {
  yield takeEvery(
    TRANSFER_FROM_SHUTTLE_CARGO_REQUESTED,
    transferFromShuttleCargo
  );
}
