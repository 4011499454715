import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Draggable } from "react-beautiful-dnd";
import wolfFighter from "static/img/battlemapTokens/wolfFighter.png";
import wolfBoarding from "static/img/battlemapTokens/wolfBoarding.png";
import wolfDestroyer from "static/img/battlemapTokens/wolfDestroyer.png";
import wolfCruiser from "static/img/battlemapTokens/wolfCruiser.png";
import wolfBattleship from "static/img/battlemapTokens/wolfBattleship.png";
import { setSelectedItem, getSelectedItem } from "store/ducks/display";
import { activeUserIsFacilitator } from "store/ducks/user";

function WolfUnit({
  wolfUnitId,
  name,
  type,
  index,
  currentHealth,
  maxHealth,
  used,
  attacks,
}) {
  const dispatch = useDispatch();

  const isUserFacilitator = useSelector(activeUserIsFacilitator, shallowEqual);
  const selectedItem = useSelector(getSelectedItem, shallowEqual);

  const imageByType = {
    fighter: wolfFighter,
    boarding: wolfBoarding,
    destroyer: wolfDestroyer,
    cruiser: wolfCruiser,
    battleship: wolfBattleship,
  };

  const heightByType = {
    fighter: "4vh",
    boarding: "4vh",
    destroyer: "8vh",
    cruiser: "8vh",
    battleship: "8vh",
  };

  return (
    <Draggable
      draggableId={`wolfUnit-${wolfUnitId}`}
      index={index}
      isDragDisabled={!isUserFacilitator}
    >
      {(provided) => (
        <Col
          xs="auto"
          ref={provided.innerRef}
          {...provided.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
          {...provided.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
          className="pt-4"
          onClick={
            isUserFacilitator
              ? () => dispatch(setSelectedItem("wolfunit", wolfUnitId))
              : () => {}
          }
          onKeyUp={
            isUserFacilitator
              ? () => dispatch(setSelectedItem("wolfunit", wolfUnitId))
              : () => {}
          }
          role={isUserFacilitator ? "button" : ""}
          tabIndex={isUserFacilitator ? 1 : null}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="battlemap-tooltip">
                <ul className="list-unstyled m-0">
                  <li>{name}</li>
                  <li>Health: {`${currentHealth}/${maxHealth}`}</li>
                  <li>Attacks: {attacks}</li>
                </ul>
              </Tooltip>
            }
          >
            <div
              className={`border ${
                selectedItem &&
                selectedItem.itemType === "wolfunit" &&
                selectedItem.itemId === wolfUnitId
                  ? "border-warning"
                  : "border-dark"
              }`}
            >
              <ProgressBar
                now={(currentHealth / maxHealth) * 100}
                variant="success"
                style={{ height: "4px" }}
              />
              <div
                className={`
                ${used ? "used-token-overlay" : ""} ${
                  currentHealth === 0 ? "destroyed-token-overlay" : ""
                }
              `}
              >
                <img
                  src={imageByType[type]}
                  style={{ maxHeight: heightByType[type], maxWidth: "25vw" }}
                  alt={name}
                />
              </div>
            </div>
          </OverlayTrigger>
        </Col>
      )}
    </Draggable>
  );
}

WolfUnit.propTypes = {
  wolfUnitId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "fighter",
    "boarding",
    "destroyer",
    "cruiser",
    "battleship",
  ]).isRequired,
  index: PropTypes.number.isRequired,
  currentHealth: PropTypes.number.isRequired,
  maxHealth: PropTypes.number.isRequired,
  used: PropTypes.bool.isRequired,
  attacks: PropTypes.number.isRequired,
};

export default WolfUnit;
