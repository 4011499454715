import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getTransactionEffect } from "store/ducks/transactionEffect";
import { getShipById } from "store/ducks/ship";

function AddResource({ effectId, resource }) {
  const effect = useSelector(
    (state) => getTransactionEffect(state, effectId),
    shallowEqual
  );
  const ship = useSelector(
    (state) => getShipById(state, effect.ship_id),
    shallowEqual
  );

  const getResourceAmount = () => {
    switch (resource) {
      case "Food":
        return ship.food;
      case "Water":
        return ship.water;
      case "Material":
        return ship.material;
      default:
        return undefined;
    }
  };

  return (
    <Row>
      <Col>
        <h4>Add {resource}</h4>
        <p>
          Ship&apos;s {resource.toLowerCase()} will go from{" "}
          {getResourceAmount()} to {getResourceAmount() + effect.quantity}.
        </p>
      </Col>
    </Row>
  );
}

AddResource.propTypes = {
  effectId: PropTypes.number.isRequired,
  resource: PropTypes.oneOf(["Food", "Water", "Material"]).isRequired,
};

export default AddResource;
